import axios from '../../middleware/axios'
import idbs  from '../../api/offline/indexedDbService'

export const state = () => ({
  isLoading: true, // was false
  deletingImageIsLoading: false,
  actionsList: [],
  userSpecificActionsList: 0
})

export const getters = {
  getIsLoading: (state) => {
    return state.isLoading
  },
  getDeletingImageIsLoading: (state) => {
    return state.deletingImageIsLoading
  },
  getActionsList: (state) => {
    return state.actionsList
  },
  getUserSpecificActionsList: (state) => {
    return state.userSpecificActionsList
  }
}

export const mutations = {
  setIsLoading: (state, loading) => {
    state.isLoading = loading === true
  },
  setDeletingImageIsLoading: (state, loading) => {
    state.deletingImageIsLoading = loading === true
  },
  setActionsList: (state, data) => {
    state.actionsList = data
  },
  deleteImageFromCurrentAction: (state, dataObj) => {
    // eslint-disable-next-line
    let assetListLet = state.actionsList[dataObj.index].answer.data.assets.data
    // eslint-disable-next-line
    let assetCountLet = state.actionsList[dataObj.index].answer.data.total_assets

    // reduce count of image on this card
    assetCountLet = assetCountLet - 1

    // find the index of the image to delete
    const ASSET_INDEX = assetListLet.findIndex((i) => i.id === dataObj.id)

    // remove the image
    assetListLet.splice(ASSET_INDEX, 1)
  },
  setUserSpecificActionsList: (state, data) => {
    state.userSpecificActionsList = data
  }
}

export const actions = {
  async getActionsList({ commit, rootState }) {
    // empty out action list
    commit('setActionsList', [])

    // set loading state
    commit('setIsLoading', true)

    const evaluationUuid = rootState.newInspection.evaluation.currentEvaluation

    // try to retrieve from offline
    const offlineActions = idbs.getAllFromOffline('actions','evaluation_uuid', evaluationUuid )

    commit('setActionsList', offlineActions )
    commit('setIsLoading', false)

    await axios
      .get(process.env.apiUrl + '/actions', {
        params: {
          include: ['category', 'answer', 'contractor', 'answer.assets', 'roles'], // eslint-disable-line
          evaluation: evaluationUuid
        },
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setActionsList', response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // unset loading
        commit('setIsLoading', false)
      })
  },
  async getUserSpecificActionsList({ commit, rootState }) {
    // empty out action list
    commit('setActionsList', [])

    // set loading state
    commit('setIsLoading', true)

    await axios
      .get(process.env.apiUrl + '/actions', {
        params: {
          include: ['category', 'answer', 'contractor', 'answer.assets', 'roles'], // eslint-disable-line
          user: rootState.auth.userData.uuid
        },
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setActionsList', response.data.data)
        commit('setUserSpecificActionsList', response.data.data.length)
        commit('navigation/setActionsCount', response.data.data.length, {
          root: true
        })

        idbs.saveToOfflineStorage('actions', response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // unset loading
        commit('setIsLoading', false)
      })
  },
  async getSiteSpecificActionsList({ commit, rootState }, data) {
    // empty out action list
    commit('setActionsList', [])

    // set loading state
    commit('setIsLoading', true)

    await axios
      .get(process.env.apiUrl + '/actions', {
        params: {
          include: ['category', 'answer', 'contractor', 'answer.assets', 'roles', 'closer', 'closeAssets', 'site'], // eslint-disable-line
          site: data,
          pageSize: 500
        },
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setActionsList', response.data.data)

        idbs.saveToOfflineStorage('actions', response.data.data)

      })
      .catch((e) => {
        console.log(e)

        // try to fetch from offline store
        const offlineActions = idbs.getAllFromOffline('actions',
            'site_uuid',
            data )

        offlineActions.then(function( offlineActions )
        {
          commit('setActionsList', offlineActions )
        }, function( e )
        {
          console.log( 'offline data error' )
        })

      })
      .finally(() => {
        // unset loading
        commit('setIsLoading', false)
      })
  },
  async setActionedState({ commit, dispatch, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    let axiosParams

    if (data.boolean === true) {
      axiosParams = {
        close: 1
      }
    } else {
      axiosParams = {
        reopen: 1
      }
    }

    const offlineAction = idbs.getFromOfflineByPrimaryKey('actions', data.uuid)

    offlineAction.then(function( offlineAction )
    {
      idbs.updateOfflineStorage('actions', offlineAction, axiosParams )

      dispatch('getActionsList')

    }, function( e )
    {
      console.log( 'offline data get error' )
    })

    await axios
      .patch(process.env.apiUrl + '/actions/' + data.uuid, axiosParams, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          'Cache-Control': 'no-cache'
        }
      })
      .then((response) => {

        idbs.saveToOfflineStorage('actions', [response.data.data] )
        dispatch('getActionsList')
      })
      .catch((e) => {
        console.log(e)

        const offlineAction = idbs.getFromOfflineByPrimaryKey('actions', data.uuid)
        offlineAction.then(function( offlineAction )
        {
          idbs.updateOfflineStorage('actions', offlineAction, axiosParams )

          dispatch('getActionsList')

        }, function( e )
        {
          console.log( 'offline data get error' )
        })

      })
    // don't unset loading state as it interrfers with the dispatch
    // finally(() => {
    // set loading state
    // commit('setIsLoading', true)
    // })
  },
  async prefetchAnswersToOffline({ commit, state, rootState, dispatch }, { evaluationUuid })
  {
     console.log("prefetch answers to offline was called")

    await axios
        .get(process.env.apiUrl + '/answers', {
          params: {
            evaluation: evaluationUuid,
            include: ["actions","contractor"]
          },
          headers: {
            Authorization: 'Bearer ' + rootState.auth.authToken
          }
        })
        .then((response) => {
          // commmit full category list from api
          idbs.saveToOfflineStorage('answers',response.data.data )
        })
        .catch((e) => {
          console.log(e)
        })
  },

  async deleteImageFromAction({ commit, state, rootState }, data) {
    // set loading state
    commit('setDeletingImageIsLoading', true)

    await axios
      .delete(process.env.apiUrl + '/answers/assets/' + data.id, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .then(() => {
        const INDEX_CONST = state.actionsList.findIndex((i) => i.uuid === data.active)

        if (INDEX_CONST > -1) {
          commit('deleteImageFromCurrentAction', {
            index: INDEX_CONST,
            id: data.id
          })
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        commit('setDeletingImageIsLoading', false)
      })
  },
  clearStoreData({ commit }) {
    commit('setIsLoading', true)
    commit('setDeletingImageIsLoading', false)
    commit('setActionsList', [])
  },
  async saveCloseoffForm({ commit, dispatch, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    const DISPATCH_DATA = {}

    if (data.closeout === true) {
      DISPATCH_DATA.close = 1
    } else {
      DISPATCH_DATA.reopen = 1
    }

    if (data.evidence.length > 0) {
      DISPATCH_DATA.close_evidence = data.evidence
    }

    if (data.imageData.length > 0) {
      DISPATCH_DATA.close_asset = data.imageData[0].base_64
      DISPATCH_DATA.close_asset_filename = data.imageData[0].filename
    }

    await axios
      .patch(process.env.apiUrl + '/actions/' + data.actionUuid, DISPATCH_DATA, {
          headers: {
              Authorization: 'Bearer ' + rootState.auth.authToken,
              'Cache-Control': 'no-cache'
          }
      })
      .then(( response ) => {
        idbs.saveToOfflineStorage('actions', [response.data.data ] )
        dispatch('getSiteSpecificActionsList', data.siteUUid)
      })
      .catch((e) => {
        console.log(e)

        const offlineAction = idbs.getFromOfflineByPrimaryKey('actions', data.uuid)
        offlineAction.then(function( offlineAction )
        {
          idbs.updateOfflineStorage('actions', offlineAction, DISPATCH_DATA )

          dispatch('getSiteSpecificActionsList', data.siteUUid)

        }, function( e )
        {
          console.log( 'offline data get error' )
        })

      })
    // .finally(() => {
    // set loading state
    //   commit('setIsLoading', false)
    // })
  }
}
