import Vue from 'vue'
import * as moment from "moment";
import axios from '../middleware/axios'
import idbs from "../api/offline/indexedDbService"

export const state = () => ({
    loading: false,
    busy: false,
    collection: null,
    instance: null,
    collectionMeta: null
})

export const getters = {
    getIsLoading: (state) => {
        return state.isLoading
    },
    getCollection: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return []
        }

        return [...state.collection].sort(function( a, b )
        {
            const order = 1
            return a.running_order < b.running_order ? -order : a.running_order > b.running_order ? order : 0;
        })
    },
    getPaginationOptions: (state) => {

        if ( state.collectionMeta === null || state.collectionMeta.length === 0 ){
            return null
        }
        return ( "pagination" in state.collectionMeta ) ? state.collectionMeta.pagination : null
    },
}

export const mutations = {

    setLoading( state, isLoading ){
        state.loading = isLoading === true
    },
    setBusy( state, isBusy )
    {
      state.busy = isBusy === true
    },
    setCollection: ( state, data ) => {
        state.collection = data
    },

    setCollectionMeta: ( state, data ) => {
        state.collectionMeta = data
    },

    addToCollection: ( state, model ) => {
        if ( state.collection === null )
        {
            state.collection = []
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)
        if ( index === -1){
            state.collection.push(model)
        }else{
            Vue.set(state.collection, index, model )
        }
    },

    setInstance: ( state, model ) => {
        return ( state.instance = model )
    },

    updateInCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }

        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            return
        }

        Vue.set(state.collection, index, model )

    },

    removeFromCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)
        if ( index === -1 ){
            return
        }
        state.collection.splice(index,1)
    }
}

export const actions = {

    clearStore( {commit} ){
        commit("setInstance", null)
        commit("setCollection", null)
        commit("setCollectionMeta", null)
    },

    async collection( { commit, rootState }, filters={}) {

        commit('setLoading', true)
        commit('setCollectionMeta', null)

        let offlineCollection = null

        try {

            await idbs.getAllFromOffline('evaluation-question-answers','evaluation_uuid', filters.evaluation ).then( (response) => {

                offlineCollection = response

                commit('setCollection', response )
                commit('setLoading', false)
            })

        }catch( error )
        {

        }

        try {

            await axios
                .get( process.env.apiUrl + '/evaluation-question-answers',{
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then( function( response ) {
                    const updatedOfflineCollection = []

                    if ('meta' in response.data ){
                        commit('setCollectionMeta', response.data.meta )
                    }
                    for ( const model of response.data.data ){

                        if ( offlineCollection !== null )
                        {
                            const index = offlineCollection.findIndex((entry) => entry.uuid === model.uuid)

                            if ( index === -1 )
                            {
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }else if ( moment(offlineCollection[index].updated_at ).isBefore( model.updated_at ) )
                            {
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }else{
                                commit('addToCollection',offlineCollection[index] )
                            }

                        }else{
                            commit('addToCollection', model )
                            updatedOfflineCollection.push( model )
                        }
                    }
                    if ( updatedOfflineCollection.length > 0 ){
                        idbs.saveToOfflineStorage('evaluation-question-answers', updatedOfflineCollection )
                    }

                    return response.data
                })
                .catch(function( error ){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    async instance( { commit, rootState }, { id, filters }) {

        commit('setLoading', true)

        try {

            await axios
                .get( process.env.apiUrl +`/evaluation-question-answers/${id}`, {
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                }).then(function( response ){

                    commit('setInstance', response.data.data )

                    return response.data.data

                }).catch( function( error ){
                    return error
                })

        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    store( { commit, rootState }, data ){

        return new Promise( ( resolve, reject ) => {

            commit('setBusy',true)

            try {

                axios
                    .post( process.env.apiUrl +`/evaluation-question-answers`,data,{
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function( response ){
                        commit('addToCollection', response.data.data)

                        resolve( response )
                    })
                    .catch(function(error){
                        reject( error )
                    })

            }catch ( error ){
                reject( error )
            }finally {
                commit('setBusy', false)
            }
        })

    },
    async update( { commit, dispatch, rootState }, data ){

        commit('setBusy', true)

        data.offline_only = true
        data.updated_at = moment().format("YYYY-MM-DD HH:mm:ss")

        const mergedData = await dispatch('mergeFreshDataWithState', data )

        try {
            await idbs.saveToOfflineStorage('evaluation-question-answers', [mergedData]).then((response) => {

                if ( response !== undefined ){

                    commit('updateInCollection', mergedData )
                    commit('setBusy', false)
                }

            })

        }catch( error ){

        }
        if ( data.local_only !== true ) {
            try {

                await axios
                    .patch(process.env.apiUrl + `/evaluation-question-answers/${data.uuid}`, data, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function (response) {
                        commit('updateInCollection', response.data.data)
                        commit('setInstance', response.data.data)

                        return response.data.data
                    })
                    .catch(function (error) {
                        return error
                    })
            } catch (e) {
                throw e
            } finally {
                commit('setBusy', false)
            }
        }else{
            return data
        }
    },
    async destroy( { commit, rootState }, data ){

        commit('setBusy', true)

        try {
            commit('setBusy', true)
            await axios
                .delete( process.env.apiUrl +`/evaluation-question-answers/${data.uuid}`,{
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then(function( response ){
                    commit('removeFromCollection', response.data.data)
                    return response.data.data
                })
                .catch(function(error){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setBusy', false)
        }
    },
    updateCollectionFromOffline( { commit, rootState }, data ) {

        commit('setBusy', true)

        return new Promise( ( resolve, reject ) => {

            try {

                idbs.getAllFromOffline('evaluation-question-answers','evaluation_uuid', data.evaluation ).then( (response) => {

                    const offlineOnlyCollection = [...response.filter(model => (model.offline_only === true))]

                    if ( offlineOnlyCollection.length > 0 ){

                        axios.put( process.env.apiUrl + `/evaluation-question-answers/batch-upsert`,{ 'evaluation': data.evaluation, 'evaluation_question_answers': offlineOnlyCollection },{
                            headers: {
                                Authorization: 'Bearer ' + rootState.auth.authToken,
                                Accept: 'application/json'
                            }
                        })
                            .then(function( response ){

                                resolve( response.data )
                            })
                            .catch(function(error){
                                reject( error )
                            })
                    }else{
                        resolve({ message: "No offline only models to sync"})
                    }


                }).finally(()=> {
                    commit('setBusy', false)
                })

            }catch( error ){
                commit('setBusy', false)
                reject( error )
            }
        })

    },
    // utility
    mergeFreshDataWithState( {state}, data ){
        if (state.collection === null || state.collection.length === 0) {
            return data
        }
        const index = state.collection.findIndex((entry) => entry.uuid === data.uuid)
        if ( index === -1 ){
            return data
        }

        // const instance = state.collection[index]
        console.log("data should be merged with that in the state")
        // console.log( instance )

        return  {...state.collection[index], ...data}
    }
}
