import * as moment from 'moment'
import axios from '../middleware/axios'

function dateFormatter(dateVal) {
  return moment(dateVal).format('YYYY-MM-DD')
}

export const state = () => ({
  isLoading: false,
  list: [],
  listIsLoading: false,
  listPagination: {
    total: 0,
    count: 0,
    per_page: 8,
    current_page: 0,
    total_pages: 0,
    links: {}
  },
  listPaginationIsLoading: false,
  current: {},
  actionList: [],
  actionListIsLoading: false,
  actionListSortMeta: [],
  actionListPagination: {
    total: 0,
    count: 0,
    per_page: 8,
    current_page: 0,
    total_pages: 0,
    links: {}
  },
  actionListPaginationIsLoading: false,
  inspectionList: [],
  inspectionListIsLoading: false,
  inspectionListPagination: {
    total: 0,
    count: 0,
    per_page: 8,
    current_page: 0,
    total_pages: 0,
    links: {}
  },
  inspectionListPaginationIsLoading: false,
  inspectionListStatusOptions: []
})

export const getters = {
  getIsLoading: (state) => {
    return state.isLoading
  },
  getList: (state) => {
    return state.list
  },
  getListIsLoading: (state) => {
    return state.listIsLoading
  },
  getListPagination: (state) => {
    return state.listPagination
  },
  getListPaginationIsLoading: (state) => {
    return state.listPaginationIsLoading
  },
  getCurrent: (state) => {
    return state.current
  },
  getActionList: (state) => {
    return state.actionList
  },
  getActionListIsLoading: (state) => {
    return state.actionListIsLoading
  },
  getActionListSortMeta: (state) => {
    return state.actionListSortMeta
  },
  getActionListPagination: (state) => {
    return state.actionListPagination
  },
  getActionListPaginationIsLoading: (state) => {
    return state.actionListPaginationIsLoading
  },
  getInspectionList: (state) => {
    return state.inspectionList
  },
  getInspectionListIsLoading: (state) => {
    return state.inspectionListIsLoading
  },
  getInspectionListPagination: (state) => {
    return state.inspectionListPagination
  },
  getInspectionListPaginationIsLoading: (state) => {
    return state.inspectionListPaginationIsLoading
  },
  getInspectionListStatusOptions: (state) => {
    return state.inspectionListStatusOptions
  }
}

export const mutations = {
  setIsLoading: (state, loading) => {
    state.isLoading = loading === true
  },
  setList: (state, data) => {
    state.list.push(data)
  },
  clearList: (state) => {
    state.list = []
  },
  setListIsLoading: (state, loading) => {
    state.listIsLoading = loading === true
  },
  setListPagination: (state, data) => {
    state.listPagination = data
  },
  clearListPagination: (state) => {
    state.listPagination = {
      total: 0,
      count: 0,
      per_page: 8,
      current_page: 0,
      total_pages: 0,
      links: {}
    }
  },
  setListPaginationIsLoading: (state, loading) => {
    state.listPaginationIsLoading = loading === true
  },
  setCurrent: (state, data) => {
    state.current = data
  },
  setActionList: (state, data) => {
    state.actionList.push(data)
  },
  clearActionList: (state) => {
    state.actionList = []
  },
  setActionListIsLoading: (state, loading) => {
    state.actionListIsLoading = loading === true
  },
  setActionListSortMeta: (state, data) => {
    state.actionListSortMeta = data
  },
  clearActionListSortMeta: (state) => {
    state.actionListSortMeta = []
  },
  setActionListPagination: (state, data) => {
    state.actionListPagination = data
  },
  clearActionListPagination: (state) => {
    state.actionListPagination = {
      total: 0,
      count: 0,
      per_page: 8,
      current_page: 0,
      total_pages: 0,
      links: {}
    }
  },
  setActionListPaginationIsLoading: (state, loading) => {
    state.actionListPaginationIsLoading = loading === true
  },
  setInspectionList: (state, data) => {
    state.inspectionList.push(data)
  },
  clearInspectionList: (state) => {
    state.inspectionList = []
  },
  setInspectionListIsLoading: (state, loading) => {
    state.inspectionListIsLoading = loading === true
  },
  setInspectionListPagination: (state, data) => {
    state.inspectionListPagination = data
  },
  clearInspectionListPagination: (state) => {
    state.inspectionListPagination = {
      total: 0,
      count: 0,
      per_page: 8,
      current_page: 0,
      total_pages: 0,
      links: {}
    }
  },
  setInspectionListPaginationIsLoading: (state, loading) => {
    state.inspectionListPaginationIsLoading = loading === true
  },
  setInspectionListStatusOptions: (state, data) => {
    state.inspectionListStatusOptions = data
  },
  clearInspectionListStatusOptions: (state, data) => {
    state.inspectionListStatusOptions = []
  }
}

export const actions = {
  async getList({ commit, state, rootState }, data) {
    if (data.type === 'firstLoad') {
      commit('clearList')
      commit('clearListPagination')
    }

    if (data.type === 'paginate' && state.listPagination.current_page === state.listPagination.total_pages) {
      return false
    }

    // set loading state
    if (data.type === 'firstLoad') {
      commit('setListIsLoading', true)
    } else {
      commit('setListPaginationIsLoading', true)
    }

    let apiLinkValue
    const PARAMS_CONST = {}

    if (Object.keys(state.listPagination.links).length === 0) {
      apiLinkValue = process.env.apiUrl + '/sites'

      // PARAMS_CONST.pageSize = 8
      PARAMS_CONST.pageSize = 1000

      PARAMS_CONST.include = ['team']

      if (data.hasOwnProperty('isEnabled') && data.isEnabled !== null) {
        PARAMS_CONST.is_enabled = data.isEnabled
      }

      if (data.hasOwnProperty('team')) {
        PARAMS_CONST.team = data.team
      }

      if (data.hasOwnProperty('searchTerm') && data.searchTerm.length > 0) {
        PARAMS_CONST.query = data.searchTerm
      }

      if (data.hasOwnProperty('limitByUser')) {
        PARAMS_CONST.user = rootState.auth.userData.uuid
      }
    } else {
      apiLinkValue = state.listPagination.links.next
    }

    await axios
      .get(apiLinkValue, {
        params: PARAMS_CONST,
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        if (response.data.data.length) {
          response.data.data.forEach((item) => {
            commit('setList', item)
          })
        }
        commit('setListPagination', response.data.meta.pagination)
      })
      .catch((e) => {
        console.log(e)

        if (e.response.status === 404) {
          this.$router.push({
            path: '/clients'
          })
        }
      })
      .finally(() => {
        // unset loading state
        if (data.type === 'firstLoad') {
          commit('setListIsLoading', false)
        } else {
          commit('setListPaginationIsLoading', false)
        }
      })
  },
  async getCurrent({ commit, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    await axios
      .get(process.env.apiUrl + '/sites/' + data.siteId, {
        params: {
          include: ['contractors']
        },
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setCurrent', response.data.data)
      })
      .catch((e) => {
        console.log(e)

        if (e.response.status === 404) {
          this.$router.push({
            path: '/clients/' + data.id + '/sites'
          })
        }
      })
      .finally(() => {
        // set loading state
        commit('setIsLoading', false)
      })
  },
  async createSite({ commit, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    // put all form data into a FormData object
    const DISPTACH_DATA = new FormData()

    if (data.clientUuid.length > 0) {
      DISPTACH_DATA.append('team', data.clientUuid)
    } else {
      DISPTACH_DATA.append('team', '')
    }

    if (data.siteName.length > 0) {
      DISPTACH_DATA.append('title', data.siteName)
    } else {
      DISPTACH_DATA.append('title', '')
    }

    if (data.siteAddressLineOne.length > 0) {
      DISPTACH_DATA.append('street_address', data.siteAddressLineOne)
    }

    if (data.siteAddressLineTwo.length > 0) {
      DISPTACH_DATA.append('town', data.siteAddressLineTwo)
    }

    if (data.sitePostcode.length > 0) {
      DISPTACH_DATA.append('postal_code', data.sitePostcode)
    }

    const START_DATE_CONST = dateFormatter(data.siteStartDate)
    if (START_DATE_CONST.length > 0) {
      DISPTACH_DATA.append('starts_at', START_DATE_CONST)
    }

    const END_DATE_CONST = dateFormatter(data.siteCompletionDate)
    if (END_DATE_CONST.length > 0) {
      DISPTACH_DATA.append('ends_at', END_DATE_CONST)
    }

    if (data.siteActive === true) {
      DISPTACH_DATA.append('is_enabled', 1)
    } else {
      DISPTACH_DATA.append('is_enabled', 0)
    }

    if (data.siteContact.length > 0) {
      DISPTACH_DATA.append('primary_contact_name', data.siteContact)
    }

    if (data.siteContactRole.length > 0) {
      DISPTACH_DATA.append('primary_contact_job_title', data.siteContactRole)
    }

    if (data.siteContactEmail.length > 0) {
      DISPTACH_DATA.append('primary_contact_email', data.siteContactEmail)
    }

    if (data.siteContactPhone.length > 0) {
      DISPTACH_DATA.append('primary_contact_phone', data.siteContactPhone)
    }

    if (data.siteContactMobile.length > 0) {
      DISPTACH_DATA.append('primary_contact_mobile', data.siteContactMobile)
    }

    await axios
      .post(process.env.apiUrl + '/sites', DISPTACH_DATA, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .then(() => {
        this.$router.push({ path: '/clients/' + data.clientUuid + '/sites' })
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // unset loading state
        commit('setIsLoading', false)
      })
  },
  async editSite({ commit, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    const DISPATCH_DATA = {}

    if (data.siteName.length > 0) {
      DISPATCH_DATA.title = data.siteName
    } else {
      DISPATCH_DATA.title = ''
    }

    if (data.siteAddressLineOne.length > 0) {
      DISPATCH_DATA.street_address = data.siteAddressLineOne
    }

    if (data.siteAddressLineTwo.length > 0) {
      DISPATCH_DATA.town = data.siteAddressLineTwo
    }

    if (data.sitePostcode.length > 0) {
      DISPATCH_DATA.postal_code = data.sitePostcode
    }

    const START_DATE_CONST = dateFormatter(data.siteStartDate)
    if (START_DATE_CONST.length > 0) {
      DISPATCH_DATA.starts_at = START_DATE_CONST
    }

    const END_DATE_CONST = dateFormatter(data.siteCompletionDate)
    if (END_DATE_CONST.length > 0) {
      DISPATCH_DATA.ends_at = END_DATE_CONST
    }

    if (data.siteActive === true) {
      DISPATCH_DATA.is_enabled = 1
    } else {
      DISPATCH_DATA.is_enabled = 0
    }

    if (data.siteContact.length > 0) {
      DISPATCH_DATA.primary_contact_name = data.siteContact
    }

    if (data.siteContactRole.length > 0) {
      DISPATCH_DATA.primary_contact_job_title = data.siteContactRole
    }

    if (data.siteContactEmail.length > 0) {
      DISPATCH_DATA.primary_contact_email = data.siteContactEmail
    }

    if (data.siteContactPhone.length > 0) {
      DISPATCH_DATA.primary_contact_phone = data.siteContactPhone
    }

    if (data.siteContactMobile.length > 0) {
      DISPATCH_DATA.primary_contact_mobile = data.siteContactMobile
    }

    await axios
      .patch(process.env.apiUrl + '/sites/' + data.siteUuid, DISPATCH_DATA, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          'Cache-Control': 'no-cache'
        }
      })
      .then(() => {
        this.$router.push({ path: '/clients/' + data.clientUuid + '/sites/' + data.siteUuid })
      })
      .catch((e) => {
        console.log(e)
      })
  },
  async getActionList({ commit, state, rootState }, data) {
    if (data.type === 'firstLoad') {
      commit('clearActionList')
      commit('clearActionListSortMeta')
      commit('clearActionListPagination')
    }

    if (
      data.type === 'paginate' &&
      state.actionListPagination.current_page === state.actionListPagination.total_pages
    ) {
      return false
    }

    // set loading state
    if (data.type === 'firstLoad') {
      commit('setActionListIsLoading', true)
    } else {
      commit('setActionListPaginationIsLoading', true)
    }

    let apiLinkValue
    const PARAMS_CONST = {}

    if (Object.keys(state.actionListPagination.links).length === 0) {
      apiLinkValue = process.env.apiUrl + '/actions' + data.filter

      PARAMS_CONST.pageSize = 8

      PARAMS_CONST.meta = 'sort_fields'

      PARAMS_CONST.include = ['category', 'answer', 'contractor', 'answer.assets']

      if (data.hasOwnProperty('siteUuid')) {
        PARAMS_CONST.site = data.siteUuid
      }
    } else {
      apiLinkValue = state.actionListPagination.links.next
    }

    await axios
      .get(apiLinkValue, {
        params: PARAMS_CONST,
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        if (response.data.data.length) {
          response.data.data.forEach((item) => {
            commit('setActionList', item)
          })
        }
        commit('setActionListSortMeta', response.data.meta.sortFields)
        commit('setActionListPagination', response.data.meta.pagination)
      })
      .catch((e) => {
        console.log(e)

        // if (e.response.status === 404) {
        //   this.$router.push({
        //     path: '/clients'
        //   })
        // }
      })
      .finally(() => {
        // unset loading state
        if (data.type === 'firstLoad') {
          commit('setActionListIsLoading', false)
        } else {
          commit('setActionListPaginationIsLoading', false)
        }
      })
  },
  async getInspectionList({ commit, state, rootState }, data) {
    if (data.type === 'firstLoad') {
      commit('clearInspectionList')
      commit('clearInspectionListStatusOptions')
      commit('clearInspectionListPagination')
    }

    if (
      data.type === 'paginate' &&
      state.inspectionListPagination.current_page === state.inspectionListPagination.total_pages
    ) {
      return false
    }

    // set loading state
    if (data.type === 'firstLoad') {
      commit('setInspectionListIsLoading', true)
    } else {
      commit('setInspectionListPaginationIsLoading', true)
    }

    let apiLinkValue
    const PARAMS_CONST = {}

    if (Object.keys(state.inspectionListPagination.links).length === 0) {
      apiLinkValue = process.env.apiUrl + '/evaluations'

      PARAMS_CONST.pageSize = 8

      PARAMS_CONST.meta = ['status-options']

      if (data.status.length > 0) {
        PARAMS_CONST.status = data.status
      }

      if (data.hasOwnProperty('siteUuid')) {
        PARAMS_CONST.site = data.siteUuid
      }

      if (data.hasOwnProperty('inspector')) {
        PARAMS_CONST.owner = data.inspector
      }
    } else {
      apiLinkValue = state.inspectionListPagination.links.next
    }

    await axios
      .get(apiLinkValue, {
        params: PARAMS_CONST,
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        if (response.data.data.length) {
          response.data.data.forEach((item) => {
            commit('setInspectionList', item)
          })
        }
        commit('setInspectionListStatusOptions', response.data.meta['status-options'])
        commit('setInspectionListPagination', response.data.meta.pagination)
      })
      .catch((e) => {
        console.log(e)

        // if (e.response.status === 404) {
        //   this.$router.push({
        //     path: '/clients'
        //   })
        // }
      })
      .finally(() => {
        // unset loading state
        if (data.type === 'firstLoad') {
          commit('setInspectionListIsLoading', false)
        } else {
          commit('setInspectionListPaginationIsLoading', false)
        }
      })
  },
  async clearSiteListAction({ commit }) {
    await commit('clearList')
  }
}
