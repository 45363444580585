export default function({ store, route, redirect, params }) {
  let mainNavId

  if (route.name !== null) {
    const ROUTE_NAME_ARRAY = route.name.split('-')

    if (ROUTE_NAME_ARRAY[0].includes('clients')) {
      mainNavId = 0

      // if we are in the clients area and dynamic routing
      // elements are are are not present
      if (
        (route.name === 'clients-id' ||
          route.name === 'clients-id-edit' ||
          route.name === 'clients-id-sites' ||
          route.name === 'clients-id-settings') &&
        (params.id === undefined || params.id === 'redirect')
      ) {
        redirect('/clients')
      } else if (
        (route.name === 'clients-id-sites-siteId-edit' || route.name === 'clients-id-sites-siteId-edit') &&
        (params.id === undefined || params.siteId === undefined)
      ) {
        redirect('/clients/' + params.id + '/sites')
      }
    } else if (ROUTE_NAME_ARRAY[0].includes('inspections') || ROUTE_NAME_ARRAY[0].includes('evaluations')) {

      if(store.state.auth.rolePermissions !== null && store.state.auth.rolePermissions.some(item => item.title === 'evaluation.read.all'))
      {
        mainNavId = 1
      }
      else if(store.state.auth.userData.after_login_redirect !== '/inspections')
      {
        redirect('/')
      }
      else
      {
        //  redirect('/')
      }
      
    } else if (ROUTE_NAME_ARRAY[0].includes('actions')) {
      mainNavId = 2
    } else if (ROUTE_NAME_ARRAY[0].includes('assessments')) {

      if(store.state.auth.rolePermissions !== null && store.state.auth.rolePermissions.some(item => item.title === 'evaluation.read.all'))
      {
        mainNavId = 9
      }
      else if(store.state.auth.userData.after_login_redirect !== '/assessments')
      {
        redirect('/')
      }
      else
      {
        //  redirect('/')
      }

    } else if (ROUTE_NAME_ARRAY[0].includes('sites')) {
      mainNavId = 3
    } else if (ROUTE_NAME_ARRAY[0].includes('dashboard')) {
      mainNavId = 4
    } else if (ROUTE_NAME_ARRAY[0].includes('admin')) {
      mainNavId = 5

      if (route.name === 'admin-user') {
        redirect('/admin')
      } else if (route.name === 'admin-inspection-id' && (params.id === undefined || params.id === 'redirect')) {
        redirect('/admin/inspection')
      }
    } else {
      mainNavId = 0
    }

    store.dispatch('navigation/changeMainNavActiveId', mainNavId)
  } else {
    return redirect('/inspections')
  }
}
