import Vue from 'vue'
import * as moment from 'moment'
import axios from '../middleware/axios'
import idbs from "../api/offline/indexedDbService";

import uuidService from "../api/offline/uuidService";

export const state = () => ({
    loading: false,
    busy: false,
    collection: null,
    childrenCollection: null,
    instance: null,
    activeInstance: null,
    activeParentInstance: null,
    collectionMeta: null,
    trashedCollectionCount: 0
})

export const getters = {

    getCollection: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }
        return state.collection
    },
    pluckCollection: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }

        return [...state.collection].reduce(function(collection,model){
            collection[model.uuid] = model.title
           return collection
        },{})
    },
    getOrderedCollection: ( state ) => {
        if ( state.collection === undefined || state.collection === null || state.collection.length === 0) {
            return null
        }
        return [...state.collection].sort(function( a, b )
        {
            const order = 1
            return a.running_order < b.running_order ? -order : a.running_order > b.running_order ? order : 0;
        })
    },
    getOrderedChildrenCollection: ( state ) => {
        if ( state.childrenCollection === undefined || state.childrenCollection === null || state.childrenCollection.length === 0) {
            return null
        }
        return [...state.childrenCollection].sort(function( a, b )
        {
            const order = 1
            return a.running_order < b.running_order ? -order : a.running_order > b.running_order ? order : 0;
        })
    },
    getActiveParentInstance: ( state ) => {
      return state.activeParentInstance
    },
    getActiveCategoryInstance: ( state ) => {
      return state.activeInstance;
    },
    getTopLevelNonTrashedCollection: (state) => {

        if ( state.collection === undefined || state.collection === null || state.collection.length === 0) {
            return null
        }
        return [...state.collection].filter( evaluationCategory => ( evaluationCategory.parent_uuid === null ) ).sort(function( a, b )
        {
            const order = 1
            return a.running_order < b.running_order ? -order : a.running_order > b.running_order ? order : 0;
        })
    },
}

export const mutations = {

    setLoading( state, isLoading ){
        state.loading = isLoading === true
    },
    setBusy( state, isBusy ){
        state.busy = isBusy === true
    },
    setCollection: ( state, data ) => {
        state.collection = data
    },

    setCollectionMeta: ( state, data ) => {
        state.collectionMeta = data
    },

    addToCollection: ( state, model ) => {
        if ( state.collection === null )
        {
            state.collection = []
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            state.collection.push(model)
        }else if ( moment(state.collection[index].updated_at ).isBefore( model.updated_at ) ){

                Vue.set( state.collection, index, model )
            }

    },
    setChildrenCollection: ( state, parentUuid ) => {

        state.childrenCollection = []

        if (state.collection === null || state.collection.length === 0) {
            return
        }

        for ( let i=0; i < state.collection.length; i++ )
        {
            if ( state.collection[i].parent_uuid === parentUuid )
            {
                state.childrenCollection.push( state.collection[i] )
            }
        }
    },
    setActiveInstanceByUuid: ( state, uuid ) => {

        if ( ! state.collection || state.collection.length < 1 )
        {
            state.activeInstance = null
            return
        }

        if ( state.collection !== null ){
            const index = state.collection.findIndex((entry) => entry.uuid === uuid)
            if ( index === -1 ){
                state.activeInstance = null
            }else{
                state.activeInstance = state.collection[index]
            }
        }


    },
    setActiveParentInstanceByUuid: ( state, uuid ) => {

        if ( ! state.collection || state.collection.length < 1 )
        {
            state.activeParentInstance = null
            return
        }

        if ( state.collection !== null ){
            const index = state.collection.findIndex((entry) => entry.uuid === uuid)
            if ( index === -1 ){
                state.activeParentInstance = null
            }else{
                state.activeParentInstance = state.collection[index]
            }
        }


    },
    setInstance: ( state, model ) => {
        return ( state.instance = model )
    },

    updateInCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }

        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            return
        }

        Vue.set(state.collection, index, model )

    },
    setTrashedToggle: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }

        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            return
        }

        state.collection[index].trashed = !state.collection[index].trashed

    },

    removeFromCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)
        if ( index === -1 ){
            return
        }
        state.collection.splice(index,1)
    },
    countSoftDeletedInCollection: (state ) => {

        state.trashedCollectionCount = 0

        if ( state.collection !== null && state.collection.length > 0 )
        {

            for ( let i=0; i < state.collection.length; i++ )
            {
                if ( state.collection[i].trashed === true )
                {
                    state.trashedCollectionCount += 1
                }
            }
        }
    }
}

export const actions = {

    clearStore( {commit} ){
        commit("setInstance", null)
        commit("setActiveInstanceByUuid", null)
        commit("setCollection", null)
        commit("setChildrenCollection", null)
        commit("setCollectionMeta", null)
    },

    async collection( { commit, rootState }, filters={}) {

        commit('setLoading', true)
        commit('setCollectionMeta', null)

        let offlineCollection = null

        try {

            await idbs.getAllFromOffline('evaluation-categories','evaluation_uuid', filters.evaluation ).then( (response) => {

                offlineCollection = response

                commit('setCollection', response )
                commit('countSoftDeletedInCollection')
                commit('setLoading', false)
            })

        }catch( error )
        {

        }

        try {

            await axios
                .get( process.env.apiUrl + '/evaluation-categories',{
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                }).then( function( response ) {
                    // commit('setCollection',[] )

                    const updatedOfflineCollection = []

                    if ('meta' in response.data ){
                        commit('setCollectionMeta', response.data.meta )
                    }
                    for ( const model of response.data.data ){

                        if ( offlineCollection !== null )
                        {
                            const index = offlineCollection.findIndex((entry) => entry.uuid === model.uuid)

                            if ( index === -1 )
                            {
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }else if ( moment(offlineCollection[index].updated_at ).isBefore( model.updated_at ) )
                            {
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }else{
                                commit('addToCollection',offlineCollection[index] )

                                if (!offlineCollection[index].hasOwnProperty('evaluation_type_category_uuid') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                            }

                        }else{
                            commit('addToCollection', model )
                            updatedOfflineCollection.push( model )
                        }
                    }

                    if ( updatedOfflineCollection.length > 0 ){
                        idbs.saveToOfflineStorage('evaluation-categories', updatedOfflineCollection )
                    }

                    commit('countSoftDeletedInCollection')

                    return response.data.data
                })
                .catch(function( error ){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }
    },
    setInstanceAsActive({commit}, {uuid}) {
       commit('setActiveInstanceByUuid', uuid)
    },
    setInstanceAsActiveParent({commit}, {uuid}) {
        commit('setActiveParentInstanceByUuid', uuid)
    },
    setChildrenCollectionByParentUuid({commit},{parentUuid}) {
        commit('setChildrenCollection',parentUuid)
    },
    instance( { commit, rootState }, { id, filters }) {
        return new Promise( (resolve, reject ) => {
            axios
                .get( process.env.apiUrl +`/evaluation-categories/${id}`, {
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                }).then(function( response ){
                commit('setInstance', response.data.data )
                resolve( response )
            }).catch( function( error ){
                return error
            })
        })
    },
    store( { commit, rootState }, data ){
        return new Promise((resolve, reject ) => {

            try {

                data.uuid = uuidService.generate()
                data.offline_only = true
                data.updated_at = moment().format("YYYY-MM-DD HH:mm:ss")

                if ( "evaluation" in data ){
                    data.model_uuid = data.evaluation
                }

                idbs.saveToOfflineStorage('evaluation-categories', [data]).then((response) => {
                    commit('addToCollection', data )
                    commit('setLoading', false)

                })

            }catch( error ){
                console.log("offline save error")
                console.log( error )
            }


            axios
                .post( process.env.apiUrl +`/evaluation-categories/`,data,{
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then(function( response ){
                    commit('addToCollection', response.data.data)
                    idbs.saveToOfflineStorage('evaluation-categories', [response.data.data])
                    resolve( response )
                })
                .catch(function(error){
                    reject( error )
                })
        })
    },
    update( { commit, rootState }, data ){

        return new Promise((resolve, reject ) => {

            data.offline_only = true
            data.updated_at = moment().format("YYYY-MM-DD HH:mm:ss")

            axios
                .patch( process.env.apiUrl + `/evaluation-categories/${data.uuid}`,data)
                .then(function( response ){
                    commit('updateInCollection', response.data.data)
                    resolve( response )
                })
                .catch(function(error){
                    reject( error )
                })
        })
    },
    updateCollectionFromOffline( { commit, rootState }, data ) {

        commit('setBusy', true)

        return new Promise( ( resolve, reject ) => {

            try {

                idbs.getAllFromOffline('evaluation-categories','evaluation_uuid', data.evaluation ).then( (response) => {

                    const offlineOnlyCollection = [...response.filter(model => (model.offline_only === true))]

                    if ( offlineOnlyCollection.length > 0 ){
                        axios.put( process.env.apiUrl + `/evaluation-categories/batch-upsert`,{ 'evaluation_categories': offlineOnlyCollection },{
                            headers: {
                                Authorization: 'Bearer ' + rootState.auth.authToken,
                                Accept: 'application/json'
                            }
                        })
                            .then(function( response ){

                                resolve( response.data )
                            })
                            .catch(function(error){
                                reject( error )
                            })
                    }else{
                        resolve({ message: "No offline only models to sync"})
                    }


                }).finally(()=> {
                    commit('setBusy', false)
                })

            }catch( error ){
                commit('setBusy', false)
                reject( error )
            }
        })

    },
    async destroy( { commit, rootState }, data ){

        commit('setBusy', true)
        commit('setTrashedToggle', data)
        commit('countSoftDeletedInCollection')

        try {

            if ( data.force ){

                data.offline_only = true

                await idbs.deleteFromOfflineStore('evaluation-categories', data.uuid).then((response) => {
                    commit('removeFromCollection', data)
                    commit('setBusy', false)
                })

            }else{

                idbs.getFromOfflineByPrimaryKey('evaluation-categories', data.uuid).then((response) => {
                    data.trashed = true
                    data.offline_only = true
                    data.updated_at = moment().format("YYYY-MM-DD HH:mm:ss")

                    data = {...response, ...data }

                    idbs.saveToOfflineStorage('evaluation-categories', [data]).then((response) => {
                        commit('updateInCollection', [data] )
                        commit('setBusy', false)
                    })
                })

            }

        }catch( error ){

        }

        if ( data.local_only !== true ){

            try {
                await axios
                    .delete( process.env.apiUrl +`/evaluation-categories/${data.uuid}`,{
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            'Cache-Control': 'no-cache',
                            Accept: 'application/json'
                        }
                    })
                    .then(function( response ){

                        // was it soft-deleted ?
                        if ( response.data.data.trashed === true ){
                            // console.log("trying to update on softDelete")
                            commit('updateInCollection', response.data.data)
                            idbs.saveToOfflineStorage('evaluation-categories',[response.data.data])
                        }else{
                            // console.log("forceDelete")
                            commit('removeFromCollection', response.data.data)
                            idbs.deleteFromOfflineStore('evaluation-categories', data.uuid)
                        }

                        return response.data.data
                    })
                    .catch(function(error){
                        return error
                    })
            }catch ( e ){
                throw e
            }finally {
                commit('setBusy', false)
                commit('countSoftDeletedInCollection')
            }

        }else{
            return data
        }

    },
    async destroyChildren( { commit, dispatch, rootState }, data ){

        try {

            const children = [];
            await idbs.getAllFromOffline('evaluation-categories','evaluation_uuid', data.evaluation_uuid ).then( (response) => {
                response.forEach((category) => {
                    if (category.parent_uuid === data.uuid) {
                        children.push(category);
                        dispatch('destroy', { uuid: category.uuid, local_only: true });
                    }
                })
            })

            return children;

        }catch( error ){

        }

    },

    async restore({ commit, rootState }, data) {

        commit('setBusy', true)
        commit('setTrashedToggle', data)
        commit('countSoftDeletedInCollection')

        try {
            idbs.getFromOfflineByPrimaryKey('evaluation-categories', data.uuid).then((response) => {

                data.trashed = false
                data.offline_only = true
                data.updated_at = moment().format("YYYY-MM-DD HH:mm:ss")

                data = {...response, ...data }

                idbs.saveToOfflineStorage('evaluation-categories', [data]).then((response) => {
                    commit('updateInCollection', [data] )
                    commit('setBusy', false)
                })
            })

        }catch( error ){
            console.log( error )
        }

        if ( data.local_only !== true ){
            try {
                await axios
                    .patch( process.env.apiUrl +`/evaluation-categories/${data.uuid}/restore`,data,{
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            'Cache-Control': 'no-cache',
                            Accept: 'application/json'
                        }
                    })
                    .then(function( response ){

                        idbs.saveToOfflineStorage('evaluation-categories',[response.data.data])

                        return response.data.data
                    })
                    .catch(function(error){
                        return error
                    })
            }catch ( e ){
                throw e
            }finally {
                commit('setBusy', false)
                commit('countSoftDeletedInCollection')
            }

        }else{
            return data
        }

    },

    async restoreChildren( { commit, dispatch, rootState }, data ){

        try {

            const children = [];
            await idbs.getAllFromOffline('evaluation-categories','evaluation_uuid', data.evaluation_uuid ).then( (response) => {
                response.forEach((category) => {
                    if (category.parent_uuid === data.uuid) {
                        children.push(category);
                        dispatch('restore', { uuid: category.uuid, local_only: true });
                    }
                })
            })

            return children;

        }catch( error ){

        }

    },
}
