import Vue from 'vue'
import axios from '../middleware/axios'

export const state = () => ({
    isLoading: false,
    collection: null,
    instance: null,
    collectionMeta: null,
})

export const getters = {
    getIsLoading: (state) => {
        return state.isLoading
    },
    getCollection: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return []
        }
        return [...state.collection].sort(function( a, b )
        {
            const order = 1
            return a.running_order < b.running_order ? -order : a.running_order > b.running_order ? order : 0;
        })
    },
    getInstance: (state) => {
        return state.instance
    },
    getPaginationOptions: (state) => {

        if ( state.collectionMeta === null || state.collectionMeta.length === 0 ){
            return null
        }
        return ( "pagination" in state.collectionMeta ) ? state.collectionMeta.pagination : null
    },
}

export const mutations = {
    setIsLoading: (state, loading) => {
        state.isLoading = loading === true
    },
    setCollection: ( state, data ) => {
        state.collection = data
    },

    setCollectionMeta: ( state, data ) => {
        state.collectionMeta = data
    },

    addToCollection: ( state, model ) => {
        if ( state.collection === null )
        {
            state.collection = []
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)
        if ( index === -1){
            state.collection.push(model)
        }else{
            Vue.set(state.collection, index, model )
        }
    },

    setInstance: ( state, model ) => {
        return ( state.instance = model )
    },

    updateInCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }

        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            return
        }

        Vue.set(state.collection, index, model )

    },

    removeFromCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)
        if ( index === -1 ){
            return
        }
        state.collection.splice(index,1)
    }
}

export const actions = {
    clearStore( {commit} ){
        commit("setInstance", null)
        commit("setCollection", null)
        commit("setCollectionMeta", null)
    },
    collection( { commit, rootState }, filters={}) {

        commit('setIsLoading', true)
        commit('setCollectionMeta', null)

        return new Promise( ( resolve, reject ) => {

            try {
                axios
                    .get( process.env.apiUrl + '/evaluation-type-prompts',{
                        params: filters,
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then( function( response ) {

                        if ('meta' in response.data ){
                            commit('setCollectionMeta', response.data.meta )
                        }
                        for ( const model of response.data.data ){
                            commit('addToCollection', model )
                        }
                        resolve( response )
                    })
                    .catch(function( error ){
                        reject( error )
                    })
            }catch ( e ){
                reject(e)
            }finally {
                commit('setIsLoading', false)
            }
        })
    },
    instance( { commit, rootState }, { id, filters }) {
        commit('setIsLoading', true)
        commit('setCollectionMeta', null)
        return new Promise( (resolve, reject ) => {
            axios
                .get( process.env.apiUrl +`/evaluation-type-prompts/${id}`, {
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                }).then(function( response ){
                commit('setInstance', response.data.data )

                if ('meta' in response.data ){
                    commit('setCollectionMeta', response.data.meta )
                }

                commit('setIsLoading', false)
                resolve( response )
            }).catch( function( error ){
                commit('setIsLoading', false)
                reject( error )
            })
        })
    },
    store( { commit, rootState }, data ){

        return new Promise((resolve, reject ) => {
            axios
                .post( process.env.apiUrl +`/evaluation-type-prompts`,data,{
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then(function( response ){
                    commit('addToCollection', response.data.data)
                    resolve( response )
                })
                .catch(function(error){
                    if ( error.response.data !== undefined ){
                        reject( error.response.data )
                    }else{
                        reject( error )
                    }
                })
        })
    },
    update( { commit, rootState }, data ){
        return new Promise((resolve, reject ) => {
            axios
                .patch( process.env.apiUrl + `/evaluation-type-prompts/${data.uuid}`,data,{
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then(function( response ){
                    commit('updateInCollection', response.data.data)
                    resolve( response )
                })
                .catch(function(error){
                    if ( error.response.data !== undefined ){
                        reject( error.response.data )
                    }else{
                        reject( error )
                    }
                })
        })
    },
    destroy( { commit, rootState }, data ){
        return new Promise((resolve, reject ) => {
            axios
                .delete( process.env.apiUrl +`/evaluation-type-prompts/${data.uuid}`,{
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }, 
                     data: {data}
                })
                .then(function( response ){
                    commit('removeFromCollection', response.data.data)
                    resolve( response )
                })
                .catch(function(error){
                    reject( error )
                })
        })
    }
}
