const cookieKey = 'havio_' + process.env.NODE_ENV + '_user'

export default function({ store, redirect, route, app }) {
  const now = new Date()
  const refreshDate = new Date(store.state.auth.userData.login_expires_at)

  if ( app.$cookies.get( cookieKey ) && ! store.state.auth.authenticated) {
    // dispatch 'auth/loadTokenFromLocal' with cookie content
    // to set user based on old login
    store.dispatch('auth/loadTokenFromLocal', cookieKey )
  }

  if ( store.state.auth.authenticated && store.state.auth.rolePermissions === null )
  {
      store.dispatch('auth/refreshUserDataFromOffline')
  }

  // if store isn't authenticated and
  // the route isn't index/login
  if ( ! store.state.auth.authenticated && (route.name !== 'index' && route.name !== 'forgotten-password' && route.name !== 'reset-token' && route.name !== 'accept-invite')) {

    // redirect to index/login
    redirect('/')

    // if store is authenticated and
    // the route is index/login
  } else if (store.state.auth.authenticated && (route.name === 'index' || route.name === 'forgotten-password' || route.name === 'reset-token' || route.name === 'accept-invite')) {
    // redirect to the logged in page on the user data

    const REDIRECT_LINK_CONST = app.router.resolve({
      path: store.state.auth.userData.after_login_redirect
    })

    if (
      REDIRECT_LINK_CONST !== undefined &&
      Object.keys(REDIRECT_LINK_CONST).length > 0 &&
      REDIRECT_LINK_CONST.hasOwnProperty('location') &&
      REDIRECT_LINK_CONST.location !== undefined &&
      REDIRECT_LINK_CONST.location.hasOwnProperty('path') &&
      REDIRECT_LINK_CONST.location.path !== undefined
    ) {
      // redirect to the link if its valid
      redirect(REDIRECT_LINK_CONST.location.path)
    } else {

      // else redirect to inspections
      redirect('/inspections')
    }

    // if the date now is larger than the refreshDate in the user data
  } else if (refreshDate < now) {

    // logout user
    store.dispatch('auth/logout')
  }
}
