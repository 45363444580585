import axios from '../../middleware/axios'
import idbs from "../../api/offline/indexedDbService";
import uuidService from "../../api/offline/uuidService";

export const state = () => ({
  isLoading: true,
  currentEp: {},
  currentEpUuid: '',
  currentEpObservation: {},
  currentEpAnswers: []
})

export const getters = {
  getIsLoading: (state) => {
    return state.isLoading
  },
  getCurrentEp: (state) => {
    return state.currentEp
  },
  getCurrentEpUuid: (state) => {
    return state.currentEpUuid
  },
  getCurrentEpObservation: (state) => {
    return state.currentEpObservation
  },
  getCurrentEpAnswers: (state) => {
    return state.currentEpAnswers
  }
}

export const mutations = {
  setIsLoading: (state, loading) => {
    state.isLoading = loading === true
  },
  setCurrentEp: (state, data) => {
    state.currentEp = data
  },
  setCurrentEpUuid: (state, data) => {
    state.currentEpUuid = data
  },
  setCurrentEpObservation: (state, data) => {
    state.currentEpObservation = data
  },
  addImageToCurrentEpObservation: (state, data) => {
    state.currentEpObservation.assets.data.push(data)
  },
  deleteImageFromCurrentEpObservation: (state, dataObj) => {
    state.currentEpObservation.total_assets = state.currentEpObservation.total_assets - 1

    const ASSET_INDEX = state.currentEpObservation.assets.data.findIndex((i) => i.id === dataObj.id)

    state.currentEpObservation.assets.data.splice(ASSET_INDEX, 1)
  },
  setCurrentEpAnswers: (state, data) => {
    state.currentEpAnswers = data
  }
}

export const actions = {

  setLoadingState({commit}, isLoading )
  {
    commit('setIsLoading', isLoading)
  },
  async getCurrentEp({ commit, dispatch, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    // set current evaluation prompts uuid
    commit('setCurrentEpUuid', data.uuid)

    await axios
      .get(process.env.apiUrl + '/evaluation-prompts/' + data.uuid, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        // set the current prompt
        commit('setCurrentEp', response.data.data)

        // get current answers for this prompt
        dispatch('getCurrentEpAnswers', data.rating)
      })
      .catch((e) => {
        console.log(e)
      })
    // highlightng out because interfers with dispatch('getCurrentEpAnswers')
    // .finally(() => {
    // unset loading
    // commit('setIsLoading', false)
    // })
  },
  setCurrentEpAndGetAnswers({ commit, dispatch, rootState }, data) {
    commit('setIsLoading', true)

    commit('setCurrentEpUuid', data.prompt.uuid)

    commit('setCurrentEp', data.prompt)

    commit('setIsLoading', false)


  },
  clearCurrentEp({ commit }) {
    commit('setCurrentEp', {})
  },
  getCurrentEpObservation({ state, commit }, data) {
    const ItemIndex = state.currentEpAnswers.findIndex((item) => item.uuid === data)

    // set current prompts observations
    commit('setCurrentEpObservation', state.currentEpAnswers[ItemIndex])
  },
  clearCurrentEpObservation({ commit }) {
    commit('setCurrentEpObservation', {})
  },
  async getCurrentEpAnswers({ state, commit, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    if (!data) {
      data = 'non-compliant'
    }

    let answersFound = false

    // try offline first!
    await idbs.getAllFromOffline('answers','evaluation_prompt_uuid', state.currentEpUuid ).then( function( offlineAnswers )
    {
        if ( offlineAnswers.length > 0 )
        {
          answersFound = true

          // sort them by date updated
          offlineAnswers.sort( function( a, b ){
            const order = 1
            return a.created_at < b.created_at ? -order : a.created_at > b.updated_at ? order : 0;
          })

        }

        commit('setCurrentEpAnswers', offlineAnswers )
        commit('setIsLoading', false)

    })

    if ( answersFound === false )
    {

      await axios
          .get(process.env.apiUrl + '/answers', {
            params: {
              prompt: state.currentEpUuid,
              include: ['contractor', 'assets', 'actions'],
              rating: data
            },
            headers: {
              Authorization: 'Bearer ' + rootState.auth.authToken
            }
          })
          .then((response) => {
            // store these answers back to offline

            idbs.saveToOfflineStorage('answers', response.data.data )

            // set the current prompt
            commit('setCurrentEpAnswers', response.data.data)

          })
          .catch((e) => {
            console.log(e)
          })
          .finally(() => {
            // unset loading
            commit('setIsLoading', false)
          })
    }

  },
  async newObservation({ commit, dispatch, state, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    // put data in "FormData" for axios transit
    const formDataConst = new FormData()
    const offlineData = {
      uuid : uuidService.generate()
    }

    formDataConst.append('prompt', state.currentEpUuid)
    offlineData.evaluation_prompt_uuid = state.currentEpUuid

    formDataConst.append('uuid', offlineData.uuid )

    if (data.data.hasOwnProperty('criticalScore')) {
      formDataConst.append('grade', data.data.criticalScore)
      offlineData.is_escalated = ( data.data.criticalScore > 5 ) ? 1 : 0
      offlineData.score = data.data.criticalScore
      offlineData.is_scored = 1
    }else{
      offlineData.is_scored = 0
      offlineData.score = 0;
      offlineData.is_escalated = false
    }

    if (data.data.hasOwnProperty('justification')) {
      formDataConst.append('justification', data.data.justification)
      offlineData.justification = data.data.justification
    }else{
      offlineData.justification = ""
    }

    if (data.data.hasOwnProperty('description')) {
      formDataConst.append('description', data.data.description)
      offlineData.description = data.data.description
    }else{
      offlineData.description = ""
    }

    if (data.data.hasOwnProperty('contractor')) {

      formDataConst.append('contractor', data.data.contractor)

      // try to get this contractor from offline store
      // console.log("Looking up offline contractor by uuid " + data.data.contractor )

      idbs.getFromOfflineByPrimaryKey("contractors", data.data.contractor )
          .then( function( offlineContractor )
          {
            offlineData.contractor = {
              data: {
                uuid:  offlineContractor.uuid,
                title: offlineContractor.title
              }
            }

          })
          .catch(function(e){

            offlineData.contractor = null
          })

    }else{
      offlineData.contractor = null
    }

    // loop all actions and put data in "FormData" for axios transit
    if (data.data.hasOwnProperty('action')) {

      offlineData.actions = {
        data: []
      }

      data.data.action.forEach((action) => {
        formDataConst.append('actions[]', action.data)
        offlineData.actions.data.push({ action : action.data })
      })
    } else {
      formDataConst.append('actions[]', '')
    }

    if (data.data.hasOwnProperty('actionedNow')) {
      if (data.data.actionedNow === true || data.data.actionedNow === 1) {
        formDataConst.append('is_closed', 1)
        offlineData.is_closed = 1;
        offlineData.is_closed_during_inspection = 1;
        
      } else {
        formDataConst.append('is_closed', 0)
        offlineData.is_closed = 0;
        offlineData.is_closed_during_inspection = 0;
      }
    } else {
      formDataConst.append('is_closed', 0)
      offlineData.is_closed = 0;
      offlineData.is_closed_during_inspection = 0;
    }

    offlineData.rating = data.rating

    const savedOfflineAt = new Date
    savedOfflineAt.toISOString()

    // first up we store this locally!

    // console.log("pre-offline data to pass")

    // console.log( offlineData)

    await idbs.saveToOfflineStorage("answers",[ offlineData ]).then( function( response )
    {
      console.log("offline answer response")
      console.log( response )

      dispatch('getCurrentEpAnswers', data.rating)
      dispatch('clearCurrentEpObservation')

      dispatch('newInspection/evaluationPrompts/getEpsList', rootState.newInspection.evaluationCategories.currentEc, {
        root: true
      })

    }).catch( function(e){
      console.log("save offline error")
      console.log( e )
    })

    await axios
      .post(process.env.apiUrl + '/answers', formDataConst, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(( response ) => {
        // we fire this immediately after storing locally to speed this up

        // We update the local store with any extras we may have
        idbs.saveToOfflineStorage('answers', [response.data.data ] )

        // Update the offline totals for the related prompt
        idbs.getFromOfflineByPrimaryKey("evaluation-prompts", offlineData.evaluation_prompt_uuid ).then( function( evaluationPrompt )
        {

            const updatedTotals = {
              total_answers : evaluationPrompt.total_answers + 1,
              total_assets  : evaluationPrompt.total_assets,
              total_escalated_actions : evaluationPrompt.total_escalated_actions,
              has_answers : true
            }

            if ( offlineData.is_escalated )
            {
              updatedTotals.total_escalated_actions += 1;
            }

            idbs.updateOfflineStorage('evaluation-prompts', evaluationPrompt, updatedTotals ).then( function(response )
            {
              updatedTotals.uuid = offlineData.evaluation_prompt_uuid
              // mutate this on the local store too
              commit('newInspection/evaluationPrompts/updatePromptAnswerTotals',{ data: updatedTotals })
            })


        })

        /*
        dispatch('getCurrentEpAnswers', data.rating)
        dispatch('clearCurrentEpObservation')

        dispatch('newInspection/evaluationPrompts/getEpsList', rootState.newInspection.evaluationCategories.currentEc, {
          root: true
        })
        */

      })
      .catch((e) => {
        console.log(e)
      })
    // highlightng out because interfers with dispatch('getCurrentEpAnswers')
    // .finally(() => {
    // unset loading
    //   commit('setIsLoading', false)
    // })
  },
  async editObservation({ commit, dispatch, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    const OBSERVATION_DATA = {
      grade: data.data.criticalScore,
      description: data.data.description,
      actions: []
    }

    if (data.data.hasOwnProperty('justification')) {
      OBSERVATION_DATA.justification = data.data.justification
    }

    const offlineData = OBSERVATION_DATA


    if (data.data.hasOwnProperty('action')) {
      if (data.data.action.length > 0) {
        data.data.action.forEach((item) => {
          OBSERVATION_DATA.actions.push(item.data)
        })
      } else {
        OBSERVATION_DATA.actions = []
      }
    } else {
      OBSERVATION_DATA.actions = []
    }


    if (data.data.hasOwnProperty('relatingToCurrentValue')) {

      OBSERVATION_DATA.contractor = data.data.relatingToCurrentValue

      // try to get this contractor from offline store
      try {
        const offlineContractor = idbs.getFromOfflineByPrimaryKey("contractors", data.data.relatingToCurrentValue )

        offlineData.contractor = {
          data: {
            uuid:  offlineContractor.uuid,
            title: offlineContractor.title
          }
        }

      }catch( e ){

      }

    }else{
      offlineData.contractor = null
    }

    if (data.data.hasOwnProperty('actionedNow')) {
      OBSERVATION_DATA.is_closed = data.data.actionedNow
      OBSERVATION_DATA.is_closed_during_inspection = data.data.actionedNow
    } else {
      OBSERVATION_DATA.is_closed = 0
      OBSERVATION_DATA.is_closed_during_inspection = 0
    }

    offlineData.score = data.data.criticalScore
    offlineData.is_escalated = ( data.data.criticalScore > 5 ) ? 1 : 0

    // Attempt to update offline

    idbs.getFromOfflineByPrimaryKey("answers", data.data.uuid ).then( function( offlineAnswer )
    {
        idbs.updateOfflineStorage("answers", offlineAnswer, offlineData )

        dispatch('getCurrentEpAnswers', data.rating)
        dispatch('clearCurrentEpObservation')

        dispatch('newInspection/evaluationPrompts/getEpsList', rootState.newInspection.evaluationCategories.currentEc, {
          root: true
        })

    })

    await axios
      .patch(process.env.apiUrl + '/answers/' + data.data.uuid, OBSERVATION_DATA, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          'Cache-Control': 'no-cache'
        }
      })
      .then((response) => {
        /*
        dispatch('getCurrentEpAnswers', data.rating)
        dispatch('clearCurrentEpObservation')

        dispatch('newInspection/evaluationPrompts/getEpsList', rootState.newInspection.evaluationCategories.currentEc, {
          root: true
        })

         */

        idbs.saveToOfflineStorage("answers", [response.data.data])

      })
      .catch((e) => {
        console.log(e)
      })
    // highlightng out because interferes with dispatch('getCurrentEpAnswers')
    // .finally(() => {
    // unset loading
    //   commit('setIsLoading', false)
    // })
  },
  async deleteObservation({ commit, dispatch, rootState }, data) {

    console.log("destroySavedAnswer still called...")

    // set loading state
    commit('setIsLoading', true)

    await axios
      .delete(process.env.apiUrl + '/answers/' + data, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(() => {
        dispatch('getCurrentEpAnswers')
        dispatch('clearCurrentEpObservation')

        dispatch('newInspection/evaluationPrompts/getEpsList', rootState.newInspection.evaluationCategories.currentEc, {
          root: true
        })
      })
      .catch((e) => {
        console.log(e)
      })
    // highlightng out because interferes with dispatch('getCurrentEpAnswers')
    // .finally(function () {
    // unset loading
    //   commit('setIsLoading', false)
    // })
  },
  async addImageToObservation({ commit, dispatch, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    // put data in "FormData" for axios transit
    const FORM_DATA_CONST = new FormData()

    const offlineAsset = {}

    if (data.hasOwnProperty('answer')) {
      FORM_DATA_CONST.append('answer', data.answer)
    }

    if (data.hasOwnProperty('prompt')) {
      FORM_DATA_CONST.append('prompt', data.prompt)
      offlineAsset.prompt = data.prompt
    }

    if (data.hasOwnProperty('image')) {
      FORM_DATA_CONST.append('image', data.image)
      offlineAsset.image = data.image
    }

    if (data.hasOwnProperty('filename')) {
      FORM_DATA_CONST.append('filename', data.filename)
    }

    // set this offline ??
    // @todo - store this offline before we hit the network

    if ( data.type === 'provisional')
    {
      // @todo we need to setup a fake answer uuid to attach this all to

    }else{
      // @todo commit this to the store whilst we then wait on the network

    }

    await axios
      .post(process.env.apiUrl + '/answers/assets', FORM_DATA_CONST, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .then((response) => {
        // if we are saving an image to a non existant prompt

        if (data.type === 'provisional') {
          // save response data to currentEpObservation state data

          // 1. get the whole response data, remove the answer key/value
          // this is to obtain just the info about the asset
          const IMAGE_RESPONSE_DATA_OBJECT = Object.assign({}, response.data.data)
          delete IMAGE_RESPONSE_DATA_OBJECT.answer

          // 2. get the answer data from the response,
          // this is most of the data object for the current observation
          // - create assets with empty object
          // - in assets create data that is empty array
          // - push the iamge const above into assets.data
          const OBS_RESPONSE_DATA_OBJECT = Object.assign({}, response.data.data.answer.data)
          OBS_RESPONSE_DATA_OBJECT.assets = {}
          OBS_RESPONSE_DATA_OBJECT.assets.data = []
          OBS_RESPONSE_DATA_OBJECT.assets.data.push(IMAGE_RESPONSE_DATA_OBJECT)

          commit('setCurrentEpObservation', OBS_RESPONSE_DATA_OBJECT)

        } else {
          // this prompt already exists, simply push new image to asset list in currentEpObservation
          const RESPONSE_DATA_OBJECT = response.data.data
          delete RESPONSE_DATA_OBJECT.answer


          // @todo store this offline - somehow

          commit('addImageToCurrentEpObservation', RESPONSE_DATA_OBJECT)

        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // unset loading
        commit('setIsLoading', false)
      })
  },

  async deleteImageFromObservation({ commit, dispatch, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    commit('deleteImageFromCurrentEpObservation', {
      id: data.id
    })

    commit('setIsLoading', false )

    // @todo - offline assets store

    try {

      await axios
          .delete(process.env.apiUrl + '/answers/assets/' + data.id, {
            headers: {
              Authorization: 'Bearer ' + rootState.auth.authToken,
              Accept: 'application/json'
            }
          })

    }catch( e )
    {
      console.log("DELETE asset api error")
      console.log(e)

    }

    commit('setIsLoading', false )
  },
  clearStoreData({ commit }) {
    commit('setIsLoading', true)
    commit('setCurrentEp', {})
    commit('setCurrentEpUuid', '')
    commit('setCurrentEpObservation', {})
    commit('setCurrentEpAnswers', [])
  }
}
