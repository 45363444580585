import moment from 'moment'
import axios from '../middleware/axios'

export const state = () => ({
  isLoading: false,
  reportsList: [],
  reportsListIsLoading: false,
  reportsListPagination: {
    total: 0,
    count: 0,
    per_page: 8,
    current_page: 0,
    total_pages: 0,
    links: {}
  },
  reportsListPaginationIsLoading: false,
  reportCurrent: {},
  reportSections: {},
  masterReportModalResponse: ''
})

export const getters = {
  getIsLoading: (state) => {
    return state.isLoading
  },
  getReportsList: (state) => {
    return state.reportsList
  },
  getReportsListIsLoading: (state) => {
    return state.reportsListIsLoading
  },
  getReportsListPagination: (state) => {
    return state.reportsListPagination
  },
  getReportsListPaginationIsLoading: (state) => {
    return state.reportsListPaginationIsLoading
  },
  getReportCurrent: (state) => {
    return state.reportCurrent
  },
  getMasterReportModalResponse: (state) => {
    return state.masterReportModalResponse
  }
}

export const mutations = {
  setIsLoading: (state, data) => {
    state.isLoading = data
  },
  setReportsList: (state, data) => {
    state.reportsList.push(data)
  },
  clearReportsList: (state) => {
    state.reportsList = []
  },
  setReportsListIsLoading: (state, loading) => {
    state.reportsListIsLoading = loading === true
  },
  setReportsListPagination: (state, data) => {
    state.reportsListPagination = data
  },
  clearReportsListPagination: (state, data) => {
    state.reportsListPagination = {
      total: 0,
      count: 0,
      per_page: 8,
      current_page: 0,
      total_pages: 0,
      links: {}
    }
  },
  setReportsListPaginationIsLoading: (state, loading) => {
    state.reportsListPaginationIsLoading = loading === true
  },
  setReportCurrent: (state, data) => {
    state.reportCurrent = data
  },
  setMasterReportModalResponse: (state, data) => {
    state.masterReportModalResponse = data
  }
}

export const actions = {
  async getReportsList({ commit, state, rootState }, data) {
    if (data.type === 'firstLoad') {
      commit('clearReportsList')
      commit('clearReportsListPagination')
    }

    if (
      data.type === 'paginate' &&
      state.reportsListPagination.current_page === state.reportsListPagination.total_pages
    ) {
      return false
    }

    // set loading state
    if (data.type === 'firstLoad') {
      commit('setReportsListIsLoading', true)
    } else {
      commit('setReportsListPaginationIsLoading', true)
    }

    let apiLinkValue
    const PARAMS_CONST = {}

    if (Object.keys(state.reportsListPagination.links).length === 0) {
      apiLinkValue = process.env.apiUrl + '/master-reports?include[]=team'

      // PARAMS_CONST.pageSize = 15
      PARAMS_CONST.pageSize = 500

      if (data.hasOwnProperty('search') && data.search.length > 0) {
        PARAMS_CONST.query = data.search
      }

      if (data.hasOwnProperty('team') && data.team.length > 0) {
        PARAMS_CONST.team = data.team
      }
    } else {
      apiLinkValue = state.reportsListPagination.links.next
    }

    await axios
      .get(apiLinkValue, {
        params: PARAMS_CONST,
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        if (response.data.data.length) {
          response.data.data.forEach((item) => {
            commit('setReportsList', item)
          })
        }
        commit('setReportsListPagination', response.data.meta.pagination)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // unset loading state
        if (data.type === 'firstLoad') {
          commit('setReportsListIsLoading', false)
        } else {
          commit('setReportsListPaginationIsLoading', false)
        }
      })
  },
  async createReport({ commit, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    const DISPATCH_DATA = {}

    if (data.report_name.length > 0) {
      DISPATCH_DATA.title = data.report_name
    }

    if (data.summary.length > 0) {
      DISPATCH_DATA.summary = data.summary
    }

    if (data.teamData.length > 0) {
      DISPATCH_DATA.team = data.teamData
    }

    if (data.siteData.length > 0) {
      DISPATCH_DATA.site = data.siteData
    }

    await axios
      .post(process.env.apiUrl + '/master-reports', DISPATCH_DATA, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .then((response) => {
        this.$router.push({ path: '/dashboard/report-builder/' + response.data.data.uuid + '/edit' })
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // unset loading state
        commit('setIsLoading', false)
      })
  },
  async editReport({ commit, state, dispatch, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    const DISPATCH_DATA = {}

    if (data.report.length > 0) {
      DISPATCH_DATA.report = data.report
    }

    if (data.date_point.length > 0) {
      DISPATCH_DATA.date_point = moment(data.date_point).format('YYYY-MM-DD')
    }

    if (data.favourite.length > 0) {
      DISPATCH_DATA.favourite = data.favourite
    }

    if (data.teamData.length > 0) {
      DISPATCH_DATA.team = data.teamData
    }

    await axios
      .patch(process.env.apiUrl + '/reports/' + data.uuid, DISPATCH_DATA, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          'Cache-Control': 'no-cache'
        }
      })
      .then(() => {})
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // set loading state
        commit('setIsLoading', false)
      })
  },
  async getCurrentReport({ commit, state, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    await axios
      .get(process.env.apiUrl + '/master-reports/' + data.uuid + '?include[]=sections&include[]=team&include[]=site', {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setReportCurrent', response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // set loading state
        commit('setIsLoading', false)
      })
  },
  async updateCurrentReport({ commit, state, rootState }, data) {
    if (state.isLoading === false) {
      // set loading state
      commit('setIsLoading', true)
    }
    await axios
      .patch(process.env.apiUrl + '/reports/' + data.uuid, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setReportCurrent', response.data.data)
      })
      .catch((e) => {
        console.log(e)

        if (e.response.status === 404) {
          this.$router.push({ path: '/reports' })
        }
      })
      .finally(() => {
        // set loading state
        commit('setIsLoading', false)
      })
  },
  async deleteReport({ commit, dispatch, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    await axios
      .delete(process.env.apiUrl + '/master-reports/' + data.uuid, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .then(() => {
        dispatch('getReportsList', {
          type: 'firstLoad'
        })
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        commit('setIsLoading', false)
      })
  },
  async createReportFromModal({ commit, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    const DISPATCH_DATA = {}

    if (data.hasOwnProperty('title') && data.title.length > 0) {
      DISPATCH_DATA.title = data.title
    }

    if (data.hasOwnProperty('formData') && Object.keys(data.formData).length > 0) {
      if (data.formData.hasOwnProperty('clientData') && data.formData.clientData.length > 0) {
        DISPATCH_DATA.team = data.formData.clientData
      }

      if (data.formData.hasOwnProperty('siteData') && data.formData.siteData.length > 0) {
        DISPATCH_DATA.site = data.formData.siteData
      }
    }

    await axios
      .post(process.env.apiUrl + '/master-reports', DISPATCH_DATA, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .then((response) => {
        commit('setMasterReportModalResponse', response.data.data.uuid)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // unset loading state
        commit('setIsLoading', false)
      })
  },
  async createReportSection({ commit, rootState }, data) {
    const DISPATCH_DATA = new FormData()

    if (data.hasOwnProperty('reportUuid') && data.reportUuid.length > 0) {
      DISPATCH_DATA.append('master_report', data.reportUuid)
    }

    if (data.hasOwnProperty('section_type') && data.section_type.length > 0) {
      DISPATCH_DATA.append('section_type', data.section_type)
    }

    if (data.hasOwnProperty('metric_type') && Object.keys(data.metric_type).length > 0) {
      DISPATCH_DATA.append('metric_type', data.metric_type.key)
    }

    if (data.hasOwnProperty('formData') && Object.keys(data.formData).length > 0) {
      DISPATCH_DATA.append('filters[before]', moment(data.formData.date.end).format('YYYY-MM-DD'))
      DISPATCH_DATA.append('filters[since]', moment(data.formData.date.start).format('YYYY-MM-DD'))

      if (data.formData.hasOwnProperty('clientData') && data.formData.clientData.length > 0) {
        DISPATCH_DATA.append('filters[team]', data.formData.clientData)
      }

      if (data.formData.hasOwnProperty('siteData') && data.formData.siteData.length > 0) {
        DISPATCH_DATA.append('filters[site]', data.formData.siteData)
      }

      if (data.formData.hasOwnProperty('userData') && data.formData.userData.length > 0) {
        DISPATCH_DATA.append('filters[owner]', data.formData.userData)
      }

      if (data.formData.hasOwnProperty('statusData') && data.formData.statusData.length > 0) {
        DISPATCH_DATA.append('filters[status]', data.formData.statusData)
      }

      if (data.formData.hasOwnProperty('compare') && data.formData.compare.length > 0) {
        data.formData.compare.forEach((item, index) => {
          DISPATCH_DATA.append('compare[' + index + ']', item)
        })
      }

      if (data.formData.hasOwnProperty('compare_key') && data.formData.compare_key.length > 0) {
        DISPATCH_DATA.append('compare_key', data.formData.compare_key)
      }
    }

    await axios
      .post(process.env.apiUrl + '/master-report-sections', DISPATCH_DATA, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // unset loading state
        commit('setIsLoading', false)
      })
  },
  async setReportsListIsLoadingAction({ commit }, data) {
    await commit('setReportsListIsLoading', data)
  },
  async clearMasterReportModalResponseAction({ commit }, data) {
    await commit('setMasterReportModalResponse', '')
  }
}
