import axios from '../middleware/axios'
import idbs from '../api/offline/indexedDbService'

export const state = () => ({
  isLoading: false,
  clientsCategories: [],
  clientsTypes: [],
  clientsList: [],
  clientsListIsLoading: false,
  clientsListPagination: {
    total: 0,
    count: 0,
    per_page: 8,
    current_page: 0,
    total_pages: 0,
    links: {}
  },
  clientsListPaginationIsLoading: false,
  clientCurrent: {},
  clientCreationErrors: null,
  evaluationTypes: []
})

export const getters = {
  getIsLoading: (state) => {
    return state.isLoading
  },
  getClientsCategories: (state) => {
    return state.clientsCategories
  },
  getClientsTypes: (state) => {
    return state.clientsTypes
  },
  getClientsList: (state) => {
    return state.clientsList
  },
  getClientsListIsLoading: (state) => {
    return state.clientsListIsLoading
  },
  getClientsListPagination: (state) => {
    return state.clientsListPagination
  },
  getClientsListPaginationIsLoading: (state) => {
    return state.clientsListPaginationIsLoading
  },
  getCurrentClient: (state) => {
    return state.currentClient
  },
  getClientCreationErrors: (state) => {
    return state.clientCreationErrors
  },
  getClientCurrent: (state) => {
    return state.clientCurrent
  },
  getEvaluationTypes: (state) => {
    return state.evaluationTypes
  }
}

export const mutations = {
  setIsLoading: (state, loading) => {
    state.isLoading = loading === true
  },
  setClientsCategories: (state, data) => {
    state.clientsCategories = data
  },
  setClientsTypes: (state, data) => {
    state.clientsTypes = data
  },
  setClientsList: (state, data) => {
    state.clientsList.push(data)
  },
  clearClientsList: (state) => {
    state.clientsList = []
  },
  setClientsListIsLoading: (state, loading) => {
    state.clientsListIsLoading = loading === true
  },
  setClientsListPagination: (state, data) => {
    state.clientsListPagination = data
  },
  clearClientsListPagination: (state, data) => {
    state.clientsListPagination = {
      total: 0,
      count: 0,
      per_page: 8,
      current_page: 0,
      total_pages: 0,
      links: {}
    }
  },
  setClientsListPaginationIsLoading: (state, loading) => {
    state.clientsListPaginationIsLoading = loading === true
  },
  setClientCreationErrors: (state, data) => {
    state.clientCreationErrors = data
  },
  setClientCurrent: (state, data) => {
    state.clientCurrent = data
  },
  setEvaluationTypes: (state, data) => {
    state.evaluationTypes = data
  }
}

export const actions = {
  async getClientCategories({ commit, state, rootState }) {
    if (state.isLoading === false) {
      // set loading state
      commit('setIsLoading', true)
    }

    await axios
      .get(process.env.apiUrl + '/team-categories', {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setClientsCategories', response.data.data)

      })
      .catch((e) => {
        console.log(e)

      })
    // .finally(() => {
    //   // unset loading state
    //   commit('setIsLoading', false)
    // })
  },
  async getClientTypes({ commit, rootState }, data) {
    if (state.isLoading === false) {
      // set loading state
      commit('setIsLoading', true)
    }

    await axios
      .get(process.env.apiUrl + '/team-types', {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setClientsTypes', response.data.data)
      })
      .catch((e) => {
        console.log(e)

      })
      .finally(() => {
        if (data.removeLoad === true) {
          // unset loading state
          commit('setIsLoading', false)
        }
      })
  },
  async getEvaluationTypes({ commit, state, rootState }, data) {
    if (state.isLoading === false) {
      // set loading state
      commit('setIsLoading', true)
    }

    await axios
      .get(process.env.apiUrl + '/evaluation-types', {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setEvaluationTypes', response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        if (data.removeLoad === true) {
          // unset loading state
          commit('setIsLoading', false)
        }
      })
  },
  async getClientsList({ commit, state, rootState }, data) {
    if (data.type === 'firstLoad') {
      commit('clearClientsList')
      commit('clearClientsListPagination')
    }

    if (
      data.type === 'paginate' &&
      state.clientsListPagination.current_page === state.clientsListPagination.total_pages
    ) {
      return false
    }

    // set loading state
    if (data.type === 'firstLoad') {
      // commit('setClientsListIsLoading', true)
    } else {
      commit('setClientsListPaginationIsLoading', true)
    }

    let apiLinkValue
    const PARAMS_CONST = {}

    if (Object.keys(state.clientsListPagination.links).length === 0) {
      apiLinkValue = process.env.apiUrl + '/teams'

      PARAMS_CONST.pageSize = 8

      if (data.searchData.length > 0) {
        PARAMS_CONST.query = data.searchData
      }

      if (data.categoryData.length > 0) {
        PARAMS_CONST.category = data.categoryData
      }

      if (data.typeData.length > 0) {
        PARAMS_CONST.type = data.typeData
      }
    } else {
      apiLinkValue = state.clientsListPagination.links.next
    }

    await axios
      .get(apiLinkValue, {
        params: PARAMS_CONST,
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        if (response.data.data.length) {
          response.data.data.forEach((item) => {
            commit('setClientsList', item)
          })
          // save to offline
          idbs.saveToOfflineStorage('teams', response.data.data )

        }
        commit('setClientsListPagination', response.data.meta.pagination)
      })
      .catch((e) => {
        console.log(e)
        idbs.getAllFromOffline('teams',PARAMS_CONST.query).then( function(data){
          data.forEach((item) => {
            commit('setClientsList', item)
          })
        })
      })
      .finally(() => {
        // unset loading state
        if (data.type === 'firstLoad') {
          // commit('setClientsListIsLoading', false)
        } else {
          commit('setClientsListPaginationIsLoading', false)
        }
      })
  },
  async createClient({ commit, rootState }, data) {
    let requestHasErrors = false

    // set loading state
    commit('setIsLoading', true)

    // set login error message null
    commit('setClientCreationErrors', null)

    // put all form data into a FormData object
    const DISPTACH_DATA = new FormData()

    if (data.clientName.length > 0) {
      DISPTACH_DATA.append('title', data.clientName)
    } else {
      DISPTACH_DATA.append('title', '')
    }

    if (data.clientType.length > 0) {
      DISPTACH_DATA.append('type', data.clientType)
    }

    if (data.clientCategory.length > 0) {
      DISPTACH_DATA.append('category', data.clientCategory)
    }

    if ( data.clientAddressLineOne !== null && data.clientAddressLineOne.length > 0) {
      DISPTACH_DATA.append('street_address', data.clientAddressLineOne)
    }

    if ( data.clientAddressLineTwo !== null && data.clientAddressLineTwo.length > 0) {
      DISPTACH_DATA.append('town', data.clientAddressLineTwo)
    }

    if ( data.clientPostcode !== null && data.clientPostcode.length > 0) {
      DISPTACH_DATA.append('postal_code', data.clientPostcode)
    }

    if (data.clientContactDetails.length > 0) {
      data.clientContactDetails.forEach((item, index) => {
        DISPTACH_DATA.append('members[' + index + '][given_name]', item.givenName)
        DISPTACH_DATA.append('members[' + index + '][family_name]', item.familyName)
        DISPTACH_DATA.append('members[' + index + '][email]', item.email)
        DISPTACH_DATA.append('members[' + index + '][phone]', item.phone)
      })
    }

    await axios
      .post(process.env.apiUrl + '/teams', DISPTACH_DATA, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .then((response) => {
        this.$router.push({ path: '/clients/' + response.data.data.uuid + '/edit' })
      })
      .catch((error) => {
        requestHasErrors = true

        commit('setClientCreationErrors', error.response.data.errors)
      })
      .finally(() => {
        if (requestHasErrors === true) {
          // unset loading state
          commit('setIsLoading', false)
        }
      })
  },
  async editClient({ commit, state, dispatch, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    const DISPATCH_DATA = {}

    if (data.clientName.length > 0) {
      DISPATCH_DATA.title = data.clientName
    }

    if (data.clientType.length > 0) {
      DISPATCH_DATA.type = data.clientType
    }

    if (data.clientCategory.length > 0) {
      DISPATCH_DATA.category = data.clientCategory
    }

    if (data.clientAddressLineOne.length > 0) {
      DISPATCH_DATA.street_address = data.clientAddressLineOne
    }

    if (data.clientAddressLineTwo.length > 0) {
      DISPATCH_DATA.town = data.clientAddressLineTwo
    }

    if (data.clientPostcode.length > 0) {
      DISPATCH_DATA.postal_code = data.clientPostcode
    }

    if (data.clientContactDetails.length > 0) {
      DISPATCH_DATA.members = []

      data.clientContactDetails.forEach((item, index) => {
        DISPATCH_DATA.members[index] = {}
        if (item.uuid !== '') {
          DISPATCH_DATA.members[index].uuid = item.uuid
        }
        DISPATCH_DATA.members[index].given_name = item.givenName
        DISPATCH_DATA.members[index].family_name = item.familyName
        DISPATCH_DATA.members[index].email = item.email
        DISPATCH_DATA.members[index].phone = item.phone
      })
    }

    await axios
      .patch(process.env.apiUrl + '/teams/' + state.clientCurrent.uuid, DISPATCH_DATA, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          'Cache-Control': 'no-cache'
        }
      })
      .then(() => {
        dispatch('getCurrentClient', state.clientCurrent.uuid)
      })
      .catch((e) => {
        console.log(e)
      })
  },
  async editClientSettings({ commit, state, dispatch, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    const DISPATCH_DATA = {}

    if (data.settingsAnalytics === true) {
      DISPATCH_DATA.enable_analytics = 1
    } else {
      DISPATCH_DATA.enable_analytics = 0
    }

    if (data.settingsInspectionFrequency.length > 0) {
      DISPATCH_DATA.evaluation_frequency = data.settingsInspectionFrequency
    }

    if (data.settingsInspectionType.length > 0) {
      DISPATCH_DATA.evaluation_types = []

      data.settingsInspectionType.forEach((item, index) => {
        DISPATCH_DATA.evaluation_types[index] = item
      })
    }

    DISPATCH_DATA.medium_score_threshold = data.settingsInspectionRiskMed

    DISPATCH_DATA.high_score_threshold = data.settingsInspectionRiskHigh

    if (data.settingsInspectionWeighting.length > 0) {
      DISPATCH_DATA.category_weightings = {}

      data.settingsInspectionWeighting.forEach((item) => {
        DISPATCH_DATA.category_weightings[item.uuid] = {}

        Object.keys(item.category_weightings).forEach((key) => {
          DISPATCH_DATA.category_weightings[item.uuid][key] = parseFloat(item.category_weightings[key])
        })
      })
    }

    await axios
      .patch(process.env.apiUrl + '/teams/' + state.clientCurrent.uuid, DISPATCH_DATA, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          'Cache-Control': 'no-cache'
        }
      })
      .then(() => {
        const SETTINGS_ALERT_LENGTH = data.settingsAlerts.length - 1
        let dispatchLocation = ''

        return Promise.all([
          data.settingsAlerts.forEach((item, index) => {
            if (item.is_new === true) {
              dispatchLocation = 'notificationTriggers/addNotificationTrigger'
            } else if (item.is_deleted === true) {
              dispatchLocation = 'notificationTriggers/deleteNotificationTrigger'
            } else {
              dispatchLocation = 'notificationTriggers/editNotificationTrigger'
            }

            dispatch(
              dispatchLocation,
              {
                setLoad: index === 0,
                unsetLoad: index === SETTINGS_ALERT_LENGTH,
                item
              },
              {
                root: true
              }
            )
          })
        ])
          .then(() => {
            dispatch('getCurrentClient', state.clientCurrent.uuid)
          })
          .catch((e) => {
            console.log(e)
          })
      })
      .catch((e) => {
        console.log(e)
      })
  },
  async editClientEvaluationTypes({ commit, state, dispatch, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    const DISPATCH_DATA = {}

    if (data.length > 0) {
      DISPATCH_DATA.evaluation_types = []

      data.forEach((item, index) => {
        DISPATCH_DATA.evaluation_types[index] = item
      })
    }

    await axios
      .patch(process.env.apiUrl + '/teams/' + state.clientCurrent.uuid, DISPATCH_DATA, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          'Cache-Control': 'no-cache'
        }
      })
      .then(() => {
        dispatch('getCurrentClient', state.clientCurrent.uuid)
      })
      .catch((e) => {
        console.log(e)
      })
  },
  async getCurrentClient({ commit, state, rootState }, data) {
    if (state.isLoading === false) {
      // set loading state
      commit('setIsLoading', true)
    }

    await axios
      .get(process.env.apiUrl + '/teams/' + data, {
        params: {
          include: ['type', 'category', 'members', 'evaluationTypes', 'evaluationTypes.questionSections']
        },
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setClientCurrent', response.data.data)
      })
      .catch((e) => {
        console.log(e)

        if (e.response.status === 404) {
          this.$router.push({ path: '/clients' })
        }
      })
      .finally(() => {
        // set loading state
        commit('setIsLoading', false)
      })
  },
  async clientsListIsLoadingAction({ commit }, data) {
    await commit('setClientsListIsLoading', data)
  }
}
