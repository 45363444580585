import Vue from 'vue'
import axios from '../middleware/axios'

export const state = () => ({
  loading: false,
  busy: false,
  collection: null,
  filteredCollection: null,
  instance: null,
  collectionMeta: null,
  isBeingCloned: false
})

export const getters = {
  getCollection: (state) => {
    if (state.collection === null || state.collection.length === 0) {
      return null
    }
    return [...state.collection].sort(function(a, b) {
      if (a.updated_at < b.updated_at) {
        return 1
      }
      if (a.updated_at > b.updated_at) {
        return -1
      }
      return 0
    })
  },
  getMetricKeysCollection: (state) => {
    if (state.collectionMeta === null || state.collectionMeta.length === 0) {
      return []
    }

    if ('metric_keys' in state.collectionMeta) {
      return state.collectionMeta.metric_keys
    }

    return []
  },
  getTriggerTypesCollection: (state) => {
    if (state.collectionMeta === null || state.collectionMeta.length === 0) {
      return []
    }

    if ('trigger_types' in state.collectionMeta) {
      return state.collectionMeta.trigger_types
    }

    return []
  },
  getInstance: (state) => {
    return state.instance
  },
  getIsLoading: (state) => {
    return state.loading
  }
}

export const mutations = {
  setLoading(state, isLoading) {
    state.loading = isLoading === true
  },
  setBusy(state, isBusy) {
    state.busy = isBusy === true
  },
  setCollection: (state, data) => {
    state.collection = data
  },

  setCollectionMeta: (state, data) => {
    state.collectionMeta = data
  },

  setFilteredCollection: (state, data) => {
    state.filteredCollection = data
  },

  addToCollection: (state, model) => {
    if (state.collection === null) {
      state.collection = []
    }
    const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

    if (index === -1) {
      state.collection.push(model)
    }
  },

  upsertToCollection: (state, model) => {
    if (state.collection === null) {
      state.collection = []
    }
    const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

    if (index === -1) {
      state.collection.push(model)
    } else {
      Vue.set(state.collection, index, model)
    }
  },

  setInstance: (state, model) => {
    return (state.instance = model)
  },
  clearInstance: (state) => {
    state.instance = null
  },
  updateInCollection: (state, model) => {
    if (!state.collection || state.collection.length < 1) {
      return
    }

    const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

    if (index === -1) {
      return
    }

    Vue.set(state.collection, index, model)

    // also update any filtered collection

    if (state.filteredCollection && state.filteredCollection.length > 0) {
      const index = state.filteredCollection.findIndex((entry) => entry.uuid === model.uuid)
      if (index !== -1) {
        Vue.set(state.filteredCollection, index, model)
      }
    }
  },

  removeFromCollection: (state, model) => {
    if (!state.collection || state.collection.length < 1) {
      return
    }
    const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)
    if (index === -1) {
      return
    }
    state.collection.splice(index, 1)
  }
}

export const actions = {
  clearStore({ commit }) {
    commit('setInstance', null)
    commit('setCollection', null)
    commit('setCollectionMeta', null)
  },

  async collection({ commit, rootState }, filters = {}) {
    commit('setLoading', true)
    commit('setCollectionMeta', null)

    try {
      await axios
        .get(process.env.apiUrl + '/metric-notification-triggers', {
          params: filters,
          headers: {
            Authorization: 'Bearer ' + rootState.auth.authToken,
            Accept: 'application/json'
          }
        })
        .then(function(response) {
          commit('setCollection', [])
          if ('meta' in response.data) {
            commit('setCollectionMeta', response.data.meta)
          }
          for (const model of response.data.data) {
            commit('addToCollection', model)
          }
          return response.data
        })
        .catch(function(error) {
          return error
        })
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async instance({ commit, rootState }, { id, filters }) {
    commit('setLoading', true)

    try {
      await axios
        .get(process.env.apiUrl + `/metric-notification-triggers/${id}`, {
          params: filters,
          headers: {
            Authorization: 'Bearer ' + rootState.auth.authToken,
            Accept: 'application/json'
          }
        })
        .then(function(response) {
          commit('setInstance', response.data.data)

          return response.data.data
        })
        .catch(function(error) {
          return error
        })
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  setInstanceFromCollectionByUuid({ commit, state }, { uuid }) {
    if (state.collection && state.collection.length > 0) {
      const index = state.collection.findIndex((entry) => entry.uuid === uuid)

      if (index !== -1) {
        commit('setInstance', state.collection[index])
      }
    }
  },
  async store({ commit, rootState }, data) {
    commit('setBusy', true)

    try {
      await axios
        .post(process.env.apiUrl + `/metric-notification-triggers`, data, {
          headers: {
            Authorization: 'Bearer ' + rootState.auth.authToken,
            Accept: 'application/json'
          }
        })
        .then(function(response) {
          commit('addToCollection', response.data.data)

          return response.data.data
        })
        .catch(function(error) {
          return error
        })
    } catch (e) {
      throw e
    } finally {
      commit('setBusy', false)
    }
  },

  async update({ commit, rootState }, data) {
    commit('setBusy', true)

    try {
      await axios
        .patch(process.env.apiUrl + `/metric-notification-triggers/${data.uuid}`, data, {
          headers: {
            Authorization: 'Bearer ' + rootState.auth.authToken,
            Accept: 'application/json'
          }
        })
        .then(function(response) {
          commit('updateInCollection', response.data.data)
          return response.data.data
        })
        .catch(function(error) {
          return error
        })
    } catch (e) {
      throw e
    } finally {
      commit('setBusy', false)
    }
  },
  async destroy({ commit, rootState }, data) {
    commit('setBusy', true)

    try {
      await axios
        .delete(process.env.apiUrl + `/metric-notification-triggers/${data.uuid}`, {
          headers: {
            Authorization: 'Bearer ' + rootState.auth.authToken,
            Accept: 'application/json'
          }
        })
        .then(function(response) {
          commit('removeFromCollection', response.data.data)

          return response.data.data
        })
        .catch(function(error) {
          return error
        })
    } catch (e) {
      throw e
    } finally {
      commit('setBusy', false)
    }
  }
}
