import moment from 'moment'
import axios from '../middleware/axios'

export const state = () => ({
  isLoading: false,
  reportsList: [],
  reportsListIsLoading: false,
  reportsListPagination: {
    total: 0,
    count: 0,
    per_page: 8,
    current_page: 0,
    total_pages: 0,
    links: {}
  },
  reportsListPaginationIsLoading: false,
  reportCurrent: {}
})

export const getters = {
  getIsLoading: (state) => {
    return state.isLoading
  },
  getReportsList: (state) => {
    return state.reportsList
  },
  getReportsListIsLoading: (state) => {
    return state.reportsListIsLoading
  },
  getReportsListPagination: (state) => {
    return state.reportsListPagination
  },
  getReportsListPaginationIsLoading: (state) => {
    return state.reportsListPaginationIsLoading
  },
  getReportCurrent: (state) => {
    return state.reportCurrent
  }
}

export const mutations = {
  setIsLoading: (state, loading) => {
    state.isLoading = loading === true
  },
  setReportsList: (state, data) => {
    state.reportsList.push(data)
  },
  clearReportsList: (state) => {
    state.reportsList = []
  },
  setReportsListIsLoading: (state, loading) => {
    state.reportsListIsLoading = loading === true
  },
  setReportsListPagination: (state, data) => {
    state.reportsListPagination = data
  },
  clearReportsListPagination: (state, data) => {
    state.reportsListPagination = {
      total: 0,
      count: 0,
      per_page: 8,
      current_page: 0,
      total_pages: 0,
      links: {}
    }
  },
  setReportsListPaginationIsLoading: (state, loading) => {
    state.reportsListPaginationIsLoading = loading === true
  },
  setReportCurrent: (state, data) => {
    state.reportCurrent = data
  }
}

export const actions = {
  async getReportsList({ commit, state, rootState }, data) {
    if (data.type === 'firstLoad') {
      commit('clearReportsList')
      commit('clearReportsListPagination')
    }

    if (
      data.type === 'paginate' &&
      state.reportsListPagination.current_page === state.reportsListPagination.total_pages
    ) {
      return false
    }

    // set loading state
    if (data.type === 'firstLoad') {
      commit('setReportsListIsLoading', true)
    } else {
      commit('setReportsListPaginationIsLoading', true)
    }

    let apiLinkValue
    const PARAMS_CONST = {}

    if (Object.keys(state.reportsListPagination.links).length === 0) {
      apiLinkValue = process.env.apiUrl + '/reports'

      PARAMS_CONST.include = ['team', 'distributions']

      if (data.hasOwnProperty('query') && data.query.length > 0) {
        PARAMS_CONST.query = data.query
      }

      if (data.hasOwnProperty('masterReportUuid') && data.masterReportUuid.length > 0) {
        PARAMS_CONST.master_report = data.masterReportUuid
      }

      if (data.hasOwnProperty('pageSize')) {
        PARAMS_CONST.pageSize = data.pageSize
      } else {
        PARAMS_CONST.pageSize = 15
      }

      if (data.hasOwnProperty('evaluation') && data.evaluation.length > 0) {
        PARAMS_CONST.evaluation = data.evaluation
      }
    } else {
      apiLinkValue = state.reportsListPagination.links.next
    }

    await axios
      .get(apiLinkValue, {
        params: PARAMS_CONST,
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        if (response.data.data.length) {
          response.data.data.forEach((item) => {
            commit('setReportsList', item)
          })
        }
        commit('setReportsListPagination', response.data.meta.pagination)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // unset loading state
        if (data.type === 'firstLoad') {
          commit('setReportsListIsLoading', false)
        } else {
          commit('setReportsListPaginationIsLoading', false)
        }
      })
  },
  async runReport({ commit, rootState, dispatch }, data) {
    // set loading state
    commit('setReportsListIsLoading', true)

    const DISPATCH_DATA = {}

    if (data.hasOwnProperty('master_report') && data.master_report.length > 0) {
      DISPATCH_DATA.master_report = data.master_report
    }

    if (data.hasOwnProperty('evaluation') && data.evaluation.length > 0) {
      DISPATCH_DATA.evaluation = data.evaluation
    }

    await axios
      .post(process.env.apiUrl + '/reports', DISPATCH_DATA, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .then(() => {
        if (data.hasOwnProperty('master_report') && data.master_report.length > 0) {
          dispatch('getReportsList', {
            type: 'firstLoad',
            masterReportUuid: data.uuid
          })
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // unset loading state
        commit('setReportsListIsLoading', false)
      })
  },
  async createReport({ commit, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    // set login error message null
    commit('setReportCreationErrors', null)

    const DISPATCH_DATA = {}

    if (data.form.report_name.length > 0) {
      DISPATCH_DATA.title = data.form.report_name
    }

    if (data.form.summary.length > 0) {
      DISPATCH_DATA.summary = data.form.summary
    }

    if (data.form.teamData.length > 0) {
      DISPATCH_DATA.team = data.form.teamData
    }

    if (data.form.siteData.length > 0) {
      DISPATCH_DATA.site = data.form.siteData
    }

    await axios
      .post(process.env.apiUrl + '/master-reports', DISPATCH_DATA, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .then(() => {
        this.$router.push({ path: '/dashboard/report-builder' })
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // unset loading state
        commit('setIsLoading', false)
      })
  },
  async editReport({ commit, state, dispatch, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    const DISPATCH_DATA = {}

    if (data.form.report.length > 0) {
      DISPATCH_DATA.report = data.form.report
    }

    if (data.form.date_point.length > 0) {
      DISPATCH_DATA.date_point = moment(data.form.date_point).format('YYYY-MM-DD')
    }

    if (data.form.favourite.length > 0) {
      DISPATCH_DATA.favourite = data.form.favourite
    }

    if (data.form.teamData.length > 0) {
      DISPATCH_DATA.team = data.form.teamData
    }

    await axios
      .patch(process.env.apiUrl + '/reports/' + data.uuid, DISPATCH_DATA, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          'Cache-Control': 'no-cache'
        }
      })
      .then(() => {})
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // set loading state
        commit('setIsLoading', false)
      })
  },
  async getCurrentReport({ commit, state, rootState }, data) {
    if (state.isLoading === false) {
      // set loading state
      commit('setIsLoading', true)
    }

    await axios
      .get(process.env.apiUrl + '/master-reports/' + data.uuid, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setReportCurrent', response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // set loading state
        commit('setIsLoading', false)
      })
  },
  async updateCurrentReport({ commit, state, rootState }, data) {
    if (state.isLoading === false) {
      // set loading state
      commit('setIsLoading', true)
    }
    await axios
      .patch(process.env.apiUrl + '/reports/' + data.uuid, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setReportCurrent', response.data.data)
      })
      .catch((e) => {
        console.log(e)

        if (e.response.status === 404) {
          this.$router.push({ path: '/reports' })
        }
      })
      .finally(() => {
        // set loading state
        commit('setIsLoading', false)
      })
  },
  async deleteReport({ commit, dispatch, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    await axios
      .delete(process.env.apiUrl + '/master-reports/' + data.uuid, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .then(() => {
        dispatch('getReportsList', {
          type: 'firstLoad'
        })
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        commit('setIsLoading', false)
      })
  },
  async setIsLoadingAction({ commit }, data) {
    await commit('setIsLoading', data)
  },
  async setReportsListIsLoadingAction({ commit }, data) {
    await commit('setReportsListIsLoading', data)
  }
}
