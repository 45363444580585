import Vue from 'vue'
import * as moment from 'moment'
import axios from '../middleware/axios'
import idbs from "../api/offline/indexedDbService"

export const state = () => ({
    loading: false,
    busy: false,
    collection: null,
    filteredCollection: null,
    instance: null,
    collectionMeta: null
})

export const getters = {

    getCollection: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }
        return [...state.collection].sort(function(a,b) {
            if(a.running_order < b.running_order) { return -1; }
            if(a.running_order > b.running_order) { return 1; }
            return 0;
        })
    },
    getFilteredCollection: (state) => {
        if (state.filteredCollection === null || state.filteredCollection.length === 0) {
            return null
        }
        return [...state.filteredCollection].sort(function(a,b) {
            if(a.running_order < b.running_order) { return -1; }
            if(a.running_order > b.running_order) { return 1; }
            return 0;
        })
    },
    getAlphabetisedFilteredCollection: ( state ) => {
        if (state.filteredCollection === null || state.filteredCollection.length === 0) {
            return null
        }
        return [...state.filteredCollection].sort( function(a,b) {
            if(a.alphabet_key < b.alphabet_key) { return -1; }
            if(a.alphabet_key > b.alphabet_key) { return 1; }
            return 0;
        })
    },
    getTotalPending: ( state ) => {
        
        if (state.collection === null || state.collection.length === 0) {
            return 0
        }
        return [...state.collection].filter( model => (model.rating === null && model.trashed === false )).length
    },
    getTotalPendingNew: ( state ) => (evaluation) => {

        if (state.collection === null || state.collection.length === 0) {
            return 0
        }
        return [...state.collection].filter( model => (model.rating === null && model.trashed === false && model.evaluation_uuid === evaluation )).length
    }
}

export const mutations = {

    setLoading( state, isLoading ){
        state.loading = isLoading === true
    },

    setBusy( state, isBusy ){
        state.busy = isBusy === true
    },

    setCollection: ( state, data ) => {
        state.collection = data
    },

    setFilteredCollection: ( state, data ) => {
        state.filteredCollection = data
    },

    setCollectionMeta: ( state, data ) => {
        state.collectionMeta = data
    },

    addToCollection: ( state, model ) => {
        if ( state.collection === null )
        {
            state.collection = []
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ) {
            state.collection.push(model)
        }
    },

    setInstance: ( state, model ) => {
        return ( state.instance = model )
    },

    updateInCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }

        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            return
        }

        Vue.set(state.collection, index, model )
        // state.collection[index] = model

        if ( state.filteredCollection.length > 0 )
        {
            const index = state.filteredCollection.findIndex((entry) => entry.uuid === model.uuid)

            if ( index !== -1 ){
                // state.filteredCollection[index] = model
                Vue.set(state.filteredCollection, index, model )
            }
        }

    },
    updateRatingInCollection: ( state, { uuid, rating }) => {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }

        const index = state.collection.findIndex((entry) => entry.uuid === uuid)

        if ( index === -1 ){
            return
        }

        state.collection[index].rating = rating

        if ( state.filteredCollection.length > 0 )
        {
            const index = state.filteredCollection.findIndex((entry) => entry.uuid === uuid)

            if ( index !== -1 ){
               // console.log("rating changed in state for i: "+ index + " to "+ rating )
                state.filteredCollection[index].rating = rating
            }
        }
    },
    trashCollectionByCategoryUuid: ( state, {categoryUuid, trashedState }) => {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }
        for ( let i = 0; i < state.collection.length; i++ )
        {
            if ( state.collection[i].evaluation_category_uuid === categoryUuid ) {
                // console.log("trashing prompt for: " + state.collection[i].uuid + " matching " + state.collection[i].evaluation_category_uuid + " to: " + categoryUuid )
                state.collection[i].trashed = trashedState
            }

        }
    },
    batchUpdateCollectionRating: ( state, { uuids, rating }) => {

        if ( state.collection === null || state.collection.length === 0 ) {
            return
        }

        for ( let i = 0; i < state.collection.length; i++ )
        {
            if ( uuids.includes(state.collection[i].uuid ) )
            {
                state.collection[i].rating = rating
            }
        }

    },
    removeFromCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)
        if ( index === -1 ){
            return
        }
        state.collection.splice(index,1)
    },
    filterCollectionByCategoryUuid: ( state, categoryUuid ) => {

        state.filteredCollection = []

        if (state.collection === null || state.collection.length === 0) {
            return
        }

        for ( let i = 0; i < state.collection.length; i++ )
        {
            // console.log("checking: " + i )
            if ( state.collection[i].evaluation_category_uuid === categoryUuid )
            {
                // console.log("adding " + state.collection[i].uuid )
                state.filteredCollection.push( state.collection[i] )
            }
        }

    },
    filterCollectionByRating: ( state, rating ) => {

        // console.log("filtering prompts by rating: " + rating )
        state.filteredCollection = []

        if (state.collection === null || state.collection.length === 0) {
            return
        }
        for ( const model of state.collection ){

            // if ( model.rating === rating )
            // {
            //     console.log("trashed state for " + model.uuid )
            //     console.log( model.trashed )
            // }

            if ( model.hasOwnProperty('trashed') === false || model.trashed === false ) {
                if ( rating === "pending" && model.rating === null ){ // handle inconsistencies between PWA and rating default
                    state.filteredCollection.push( model )
                }else if ( model.rating === rating )
                {
                    state.filteredCollection.push( model )
                }
            }

        }
    },
    filterCollectionByRatingAndEvaluation: ( state, {rating, evaluation} ) => {

        // console.log("filtering prompts by rating: " + rating )
        state.filteredCollection = []

        if (state.collection === null || state.collection.length === 0) {
            return
        }

        for ( const model of state.collection.filter(ep => ep.evaluation_uuid === evaluation) ){

            // if ( model.rating === rating )
            // {
            //     console.log("trashed state for " + model.uuid )
            //     console.log( model.trashed )
            // }

            if ( model.hasOwnProperty('trashed') === false || model.trashed === false ) {
                if ( rating === "pending" && model.rating === null ){ // handle inconsistencies between PWA and rating default
                    state.filteredCollection.push( model )
                }else if ( model.rating === rating )
                {
                    state.filteredCollection.push( model )
                }
            }

        }

    },
}

export const actions = {

    clearStore( {commit} ){
        commit("setInstance", null)
        commit("setCollection", null)
        commit("setFilteredCollection", null)
        commit("setCollectionMeta", null)
    },
    clearInstanceFromStore( {commit} ){
        commit("setInstance", null)
    },
    filterCollectionByCategoryUuid( {commit}, {categoryUuid} )
    {
        commit("filterCollectionByCategoryUuid", categoryUuid)
    },
    filterCollectionByRating({commit},{rating} ){
        commit("filterCollectionByRating", rating )
    },
    updatingRatingInCollection({commit},{uuid, rating} ) {

        // console.log("rating state update for " + uuid )

        commit("updateRatingInCollection", { uuid, rating } )
    },
    batchUpdateRatingStateOnly({commit}, { uuids, rating } )
    {
        // console.log("updating rating in state to " + rating )
        commit("batchUpdateCollectionRating", { uuids, rating } )
        commit('setBusy', false)
    },
    trashCollectionByCategoryUuid( {commit}, {categoryUuid, evaluationUuid, trashedState } )
    {
        commit("trashCollectionByCategoryUuid", { categoryUuid, trashedState } )

        // we also need to handle storing these changes offline
        try {

            idbs.getAllFromOffline('evaluation-prompts','evaluation_uuid', evaluationUuid ).then( (response) => {

                if ( response !== undefined ) {
                    
                    
                    const trashedOffline = []

                    for ( let i=0; i < response.length; i++ )
                    {
                        if ( response[i].evaluation_category_uuid === categoryUuid ){
                            trashedOffline.push({ ...response[i], ...{ "trashed": trashedState, "offline_only": true }})
                        }
                    }

                    if ( trashedOffline.length > 0 ){
                        idbs.saveToOfflineStorage('evaluation-prompts', trashedOffline )
                    }

                }

            })

        }catch( error )
        {

        }
    },
    setInstanceFromCollectionByUuid( {commit, state }, {uuid})
    {
        // console.log("setting instance by uuid: "+ uuid )
        if ( state.collection !== null && state.collection.length > 0 )
        {
           // console.log("collection found try for index")
            const index = state.collection.findIndex((entry) => entry.uuid === uuid)
            if ( index !== -1 ){
                commit("setInstance", state.collection[index] )
            }
        }
    },
    async collection( { commit, rootState }, filters={}) {

        commit('setBusy', true)
        commit('setCollectionMeta', null)

        let offlineCollection = null

        try {

            await idbs.getAllFromOffline('evaluation-prompts','evaluation_uuid', filters.evaluation ).then( (response) => {

                if ( response !== undefined ) {

                    offlineCollection = response

                    for ( let i=0; i < response.length; i++ )
                    {

                        commit('addToCollection', response[i] )
                    }

                }
                commit('setBusy', false)
            })

        }catch( error )
        {

        }

        try {

            await axios
                .get( process.env.apiUrl + '/evaluation-prompts',{
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then( function( response ) {

                    const updatedOfflineCollection = []

                    if ('meta' in response.data ){
                        commit('setCollectionMeta', response.data.meta )
                    }

                    for ( const model of response.data.data ){
                        if ( offlineCollection !== null )
                        {
                            const index = offlineCollection.findIndex((entry) => entry.uuid === model.uuid)

                            if ( index === -1 )
                            {
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }else if ( moment(offlineCollection[index].updated_at ).isBefore( model.updated_at ) )
                            {
                                commit('addToCollection', model )

                                if(offlineCollection[index].trashed !== true)
                                {
                                    updatedOfflineCollection.push( model )
                                }
                                else if(offlineCollection[index].offline_only !== true)
                                {
                                    updatedOfflineCollection.push( model )
                                }                                
                            }
                            else{
                                commit('addToCollection',offlineCollection[index] )

                                if (!offlineCollection[index].hasOwnProperty('previous_evaluation_uuid') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                                else if (!offlineCollection[index].hasOwnProperty('evaluation_type_prompt_id') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                                else if (!offlineCollection[index].hasOwnProperty('has_previous_open_action') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                                else if (!offlineCollection[index].hasOwnProperty('cloned_from_evaluation_uuid') )
                                {
                                    updatedOfflineCollection.push( model )
                                } 
                                // else if (!offlineCollection[index].hasOwnProperty('previous_prompt') )
                                // {
                                //     updatedOfflineCollection.push( model )
                                // } 
                            }                            

                        }else{
                            commit('addToCollection', model )
                            updatedOfflineCollection.push( model )
                        }

                    }

                    if ( updatedOfflineCollection.length > 0 ) {
                        idbs.saveToOfflineStorage('evaluation-prompts', updatedOfflineCollection )
                    }
                    return response.data
                })
                .catch(function( error ){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    async instance( { commit, rootState }, { id, filters }) {

        try {

            commit('setLoading', true)

            await axios
                .get( process.env.apiUrl +`/evaluation-prompts/${id}`, {
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                }).then(function( response ){
                    commit('setInstance', response.data.data )

                    return response.data.data

                }).catch( function( error ){
                    return error
                })

        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    async store( { commit, rootState }, data ){
        try {
            commit('setLoading', true)

            data.offline_only = true
            data.updated_at = moment().format("YYYY-MM-DD HH:mm:ss")

            await axios
                .post( process.env.apiUrl +`/evaluation-prompts`,data,{
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then(function( response ){
                    commit('addToCollection', response.data.data)
                    return response.data.data
                })
                .catch(function(error){
                    return error
                })

        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }
    },
    async update( { commit, dispatch, rootState }, data ){

        commit('setBusy', true)

        data.offline_only = true
        data.updated_at = moment().format("YYYY-MM-DD HH:mm:ss")

        const mergedData = await dispatch('mergeFreshDataWithState', data )

        try {
            await idbs.saveToOfflineStorage('evaluation-prompts', [mergedData]).then((response) => {

                if ( response !== undefined ){
                    // console.log("offline prompt updates state to ")
                    // console.log( mergedData )
                    commit('updateInCollection', mergedData )
                    commit('setBusy', false)
                }

            })

        }catch( error ){

        }

        if ( data.local_only !== true ){
            try {

                await axios
                    .patch( process.env.apiUrl + `/evaluation-prompts/${data.uuid}`,data,{
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function( response ){
                        // console.log("API updates prompt state to ")
                        // console.log( response.data.data )

                        commit('updateInCollection', response.data.data)
                        idbs.saveToOfflineStorage('evaluation-prompts', [response.data.data])
                        return response.data.data
                    })
                    .catch(function(error){
                        return error
                    })
            }catch ( e ){
                throw e
            }finally {
                commit('setBusy', false)
            }

        }else{
            return data
        }

    },
    async batchUpdate( { commit, rootState }, data ){

        try {
            commit('setBusy', true)

            // get all from offline (currently)
            try {

                idbs.getAllFromOffline('evaluation-prompts', 'evaluation_uuid', data.evaluation).then((response) => {

                    if (response.length > 0) {
                        const mergedOfflineCollection = []
                        const updatedAt = moment().format("YYYY-MM-DD HH:mm:ss")
                        for (const model of response) {

                            if (data.prompts.includes(model.uuid)) {
                                mergedOfflineCollection.push({
                                    ...model, ...{
                                        rating: data.rating,
                                        offline_only: true,
                                        updated_at: updatedAt
                                    }
                                })
                            }

                        }
                        // console.log("stashing the merged offline collection")
                        idbs.saveToOfflineStorage('evaluation-prompts', mergedOfflineCollection)
                    }

                })

            } catch (error) {

                console.log(error)
            }

            if (data.local_only !== true) {

                await axios
                    .patch(process.env.apiUrl + `/evaluation-prompts/batch`, data, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function (response) {
                        // commit('updateInCollection', response.data.data)
                        // returns a message with the job log rather than mutated data... so no can do
                        return response.data.data
                    })
                    .catch(function (error) {
                        return error
                    })
                }else{
                    return data
                }

            }catch(e){
                        throw e
            }finally{
                        commit('setBusy', false)
            }

    },
    updateCollectionFromOffline( { commit, rootState }, data ) {

        commit('setBusy', true)

        return new Promise( ( resolve, reject ) => {

            try {

                idbs.getAllFromOffline('evaluation-prompts','evaluation_uuid', data.evaluation ).then( (response) => {

                    const offlineOnlyCollection = [...response.filter(model => (model.offline_only === true))]

                    if ( offlineOnlyCollection.length > 0 ){

                        axios.put( process.env.apiUrl + `/evaluation-prompts/batch-upsert`,{ 'evaluation': data.evaluation, 'evaluation_prompts': offlineOnlyCollection },{
                            headers: {
                                Authorization: 'Bearer ' + rootState.auth.authToken,
                                Accept: 'application/json'
                            }
                        })
                            .then(function( response ){

                                resolve( response.data )
                            })
                            .catch(function(error){
                                reject( error )
                            })
                    }else{
                        resolve({ message: "No offline only models to sync"})
                    }


                }).finally(()=> {
                    commit('setBusy', false)
                })

            }catch( error ){
                commit('setBusy', false)
                reject( error )
            }
        })

    },
    async destroy( { commit, rootState }, data ){

        try {
            commit('setLoading', true)
            await axios
                .delete( process.env.apiUrl +`/evaluation-prompts/${data.uuid}`,data,{
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then(function( response ){
                    commit('removeFromCollection', response.data.data)
                    return response.data.data
                })
                .catch(function(error){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }
    },
    // utility
    mergeFreshDataWithState( {state}, data ){
        if (state.collection === null || state.collection.length === 0) {
            return data
        }
        const index = state.collection.findIndex((entry) => entry.uuid === data.uuid)
        if ( index === -1 ){
            return data
        }

        // const instance = state.collection[index]
        // console.log("data in the state")
        // console.log( instance )

        return  {...state.collection[index], ...data}
    },
    filterCollectionByRatingAndEvaluation({commit},{rating, evaluation} ){
        commit("filterCollectionByRatingAndEvaluation", {rating, evaluation} )
    },
}
