import axios from '../../middleware/axios'
import idbs from '../../api/offline/indexedDbService'
import uuid from '../../api/offline/uuidService'

export const state = () => ({
  offlineEvaluation: '',
  totalPeopleOnSite: '',
  isLoading: true,
  noOnSiteIsLoading: false,
  subcontractorIsLoading: false,
  currentEvaluation: '',
  cloneEvaluationJobEndpoint: null,
  evaluationIsBeingCloned: false,
  currentEvaluationData: {},
  currentEvaluationSignoff: {},
  evaluationClientList: [],
  evaluationTypes: [],
  evaluationsListIsLoading: true,
  evaluationsList: [],
  siteSpecificEvaluationsList: [],
  evaluationsListPaginationIsLoading: false,
  evaluationsListPagination: {
    total: 0,
    count: 0,
    per_page: 0,
    current_page: 0,
    total_pages: 0,
    links: {}
  },
  evaluationListDateRange: []
})

export const getters = {
  getIsLoading: (state) => {
    return state.isLoading
  },
  getNoOnSiteIsLoading: (state) => {
    return state.noOnSiteIsLoading
  },
  getSubcontractorIsLoading: (state) => {
    return state.subcontractorIsLoading
  },
  getCurrentEvaluation: (state) => {
    return state.currentEvaluation
  },
  getCurrentEvaluationData: (state) => {
    return state.currentEvaluationData
  },
  getCurrentEvaluationSignoff: (state) => {
    return state.currentEvaluationSignoff
  },
  getEvaluationClientList: (state) => {
    return state.evaluationClientList
  },
  getCloneEvaluationJobEndpoint: ( state ) => {
    return state.cloneEvaluationJobEndpoint
  },
  getEvaluationIsBeingCloned: ( state ) => {
    return state.evaluationIsBeingCloned
  },
  getEvaluationTypes: (state) => {
    return state.evaluationTypes
  },
  getEvaluationsListIsLoading: (state) => {
    return state.evaluationsListIsLoading
  },
  getEvaluationsList: (state) => {
    return state.evaluationsList
  },
  getSiteSpecificEvaluationsList: (state) => {
    return state.siteSpecificEvaluationsList
  },
  getEvaluationsListPaginationIsLoading: (state) => {
    return state.evaluationsListPaginationIsLoading
  },
  getEvaluationsListPagination: (state) => {
    return state.evaluationsListPagination
  },
  getEvaluationRelatedContractors: (state) => {
    if (Object.keys(state.currentEvaluationData).length > 0) {

      // idbs.saveToOfflineStorage("contractors", state.currentEvaluationData.contractors)

      return state.currentEvaluationData.contractors
    } else {
      return {}
    }
  },
  getEvaluationListDateRange: (state) => {
    return state.evaluationListDateRange
  }
}

export const mutations = {
  setIsLoading: (state, loading) => {
    state.isLoading = loading === true
  },
  setOfflineEvaluation: (state, data) => {
    state.offlineEvaluation = data
  },
  setTotalPeopleOnSite: (state, data) => {
    state.totalPeopleOnSite = data
  },
  setNoOnSiteIsLoading: (state, loading) => {
    state.noOnSiteIsLoading = loading === true
  },
  setSubcontractorIsLoading: (state, loading) => {
    state.subcontractorIsLoading = loading === true
  },
  setCurrentEvaluation: (state, data) => {
    state.currentEvaluation = data
  },
  setCurrentEvaluationData: (state, data) => {
    state.currentEvaluationData = data
  },
  setCloneEvaluationJobEndpoint:( state, data ) => {
    state.cloneEvaluationJobEndpoint = data
  },
  setEvaluationIsBeingCloned: ( state, data ) => {
    state.evaluationIsBeingCloned = data
  },
  setCurrentEvaluationSignoff: (state, data) => {
    state.currentEvaluationSignoff = data
  },
  setEvaluationClientList: (state, data) => {
    state.evaluationClientList = data
  },
  setEvaluationTypes: (state, data) => {
    state.evaluationTypes = data
  },
  setEvaluationsListIsLoading: (state, loading) => {
    state.evaluationsListIsLoading = loading === true
  },
  setEvaluationsList: (state, data) => {
    state.evaluationsList.push(data)
  },
  clearEvaluationsList: (state) => {
    state.evaluationsList = []
  },
  setSiteSpecificEvaluationsList: (state, data) => {
    state.siteSpecificEvaluationsList.push(data)
  },
  clearSiteSpecificEvaluationsList: (state) => {
    state.siteSpecificEvaluationsList = []
  },
  setEvaluationsListPaginationIsLoading: (state, loading) => {
    state.evaluationsListPaginationIsLoading = loading === true
  },
  setEvaluationsListPagination: (state, data) => {
    state.evaluationsListPagination = data
  },
  clearEvaluationsListPagination: (state) => {
    state.evaluationsListPagination = {
      total: 0,
      count: 0,
      per_page: 0,
      current_page: 0,
      total_pages: 0,
      links: {}
    }
  },
  addContactToCurrentEvaluation: (state, data) => {

    const INDEX_CONST = state.currentEvaluationData.contractors.data.findIndex((i) => i.uuid === data.uuid)

    if ( INDEX_CONST <= -1) {
      state.currentEvaluationData.contractors.data.push(data)
    }
  },
  removeContractor: (state, data) => {
    if (
      state.currentEvaluationData.hasOwnProperty('contacts') &&
      state.currentEvaluationData.contacts !== undefined &&
      state.currentEvaluationData.contacts.hasOwnProperty('data') &&
      state.currentEvaluationData.contacts.data !== undefined
    ) {
      const INDEX_CONST = state.currentEvaluationData.contacts.data.findIndex((i) => i.uuid === data.uuid)

      if (INDEX_CONST > -1) {
        state.currentEvaluationData.contacts.data.splice(INDEX_CONST, 1)
      }
    }
  },
  updateNumberPeopleOnSiteInCurrentEvaluation: (state, data) => {
    state.currentEvaluationData.total_people_on_site = data
  },
  setEvaluationListDateRange: (state, data) => {
    state.evaluationListDateRange = data
  },
  clearEvaluationListDateRange: (state, data) => {
    state.evaluationListDateRange = []
  }
}

export const actions = {
  async getEvaluationClientList({ commit, rootState }) {
    // set loading state
    commit('setIsLoading', true)

    await axios
      .get(process.env.apiUrl + '/teams', {
        params: {
          include: 'sites'
        },
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setEvaluationClientList', response.data.data)

        idbs.saveToOfflineStorage('teams', response.data.data)
      })
      .catch((e) => {
        console.log(e)

        const offlineTeams = idbs.getAllFromOffline('teams')

        offlineTeams.then(
          function(offlineTeams) {
            commit('setEvaluationClientList', offlineTeams)
          },
          function(e) {
            console.log('offline data error')
          }
        )
      })
      .finally(() => {
        // unset loading state
        commit('setIsLoading', false)
      })
  },
  async getEvaluationTypes({ commit, rootState }) {
    // set loading state
    commit('setIsLoading', true)

    await axios
      .get(process.env.apiUrl + '/evaluation-types', {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setEvaluationTypes', response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // unset loading state
        commit('setIsLoading', false)
      })
  },
  async pollJobLog({commit, rootState }, jobLogEndpoint ){

    try {

      const response = await axios
          .get(jobLogEndpoint, {
            headers: {
              Authorization: 'Bearer ' + rootState.auth.authToken
            }
          })

        if ( typeof response.data.data.is_finished !== "undefined" && response.data.data.is_finished === true )
        {
          commit('setCloneEvaluationJobEndpoint', null)
          commit('setEvaluationIsBeingCloned', false )
        }else{
          commit('setEvaluationIsBeingCloned', true )
        }

    }catch(e){
      console.log(e)
    }
  },
  async getSingleEvaluation({ commit, rootState, state }, uuid) {
    // set loading state
    commit('setSubcontractorIsLoading', true)
    // if ( state.currentEvaluationData && state.currentEvaluationData.uuid === uuid ) {
    //     return true
    // }

      try{

        try {
          const local = await idbs.getFromOfflineByPrimaryKey('evaluations', uuid )

          commit('setCurrentEvaluation', local.uuid)
          commit('setCurrentEvaluationData', local )

        }catch( e )
        {
          commit('setIsLoading', true, {
            root: true
          })

        }

        // console.log("its here")

        const response = await axios
            .get(process.env.apiUrl + '/evaluations/' + uuid, {
              params: {
                include: ['contacts']
              },
              headers: {
                Authorization: 'Bearer ' + rootState.auth.authToken
              }
            })

       // console.log( response.data.data )

        commit('setCurrentEvaluation', response.data.data.uuid)
        commit('setCurrentEvaluationData', response.data.data)

        idbs.saveToOfflineStorage('evaluations', [response.data.data])

        // Update the contractors offline store too (for elsewhere)

        if ( typeof response.data.data.contacts.data !== 'undefined' && response.data.data.contacts.data.length > 0 )
        {
          idbs.saveToOfflineStorage('contacts', response.data.data.contacts.data )
        }

      }catch(e){
        console.log(e)
      }finally{
        // set loading state
        commit('setIsLoading', false, {
          root: true
        })
        commit('setSubcontractorIsLoading', false)
        // setLocal isLoading to false
        commit('setIsLoading', false)
      }


  },
  async getCurrentEvaluation({ commit, rootState }, credentials) {
    // set loading state
    commit('setIsLoading', true, {
      root: true
    })

    await axios
      .post(process.env.apiUrl + '/evaluations', credentials, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then((response) => {
        // set new evaluation
        commit('setCurrentEvaluation', response.data.data.uuid)
        commit('setCurrentEvaluationData', response.data.data)

        if ( typeof response.headers.location !== 'undefined' )
        {
          commit('setCloneEvaluationJobEndpoint', response.headers.location )
          commit('setEvaluationIsBeingCloned', true )
        }

        idbs.saveToOfflineStorage('evaluations', [response.data.data])

        // redirect to waiting stage to check that the job queue has run ?

        // redirect to new-inspection page
        this.$router.push({
          path: '/new-inspection/' + response.data.data.uuid
        })
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // set loading state
        commit('setIsLoading', false, {
          root: true
        })
      })
  },
  async finaliseCurrentEvaluation({ commit, state, dispatch, rootState }, data) {
    // set root loading state
    commit('setIsLoading', true, {
      root: true
    })

    await axios
      .patch(
        process.env.apiUrl + '/evaluations/' + state.currentEvaluation,
        {
          finalise: data.finalise,
          summary: data.summary,
          good_practice_observed: data.goodPractice
        },
        {
          headers: {
            Authorization: 'Bearer ' + rootState.auth.authToken,
            'Cache-Control': 'no-cache'
          }
        }
      )
      .then((response) => {
        // dispatch this modules store data...then
        dispatch('clearStoreData').then(() => {
          idbs.saveToOfflineStorage('evaluations', [response.data.data])
          // redirect to the inspections listing page
          this.$router.push({ path: '/inspections' })

          // dispatch other removals of store data
          dispatch('newInspection/evaluationActions/clearStoreData', null, {
            root: true
          })

          dispatch('newInspection/evaluationCategories/clearStoreData', null, {
            root: true
          })

          dispatch('newInspection/evaluationPrompt/clearStoreData', null, {
            root: true
          })

          dispatch('newInspection/evaluationPrompts/clearStoreData', null, {
            root: true
          })
          // dispatch other removals of store data
        })
      })
      .catch((e) => {
        console.log(e)

        const router = this.$router
        const offlineEvaluation = idbs.getFromOfflineByPrimaryKey('evaluations', state.currentEvaluation)

        offlineEvaluation.then(
          function(offlineEvaluation) {
            idbs.updateOfflineStorage('evaluations', offlineEvaluation, {
              finalise: 1,
              summary: data.summary,
              good_practice_observed: data.goodPractice
            })

            // redirect to the inspections listing page
            dispatch('clearStoreData').then(() => {
              router.push({ path: '/inspections' })

              // dispatch other removals of store data
              dispatch('newInspection/evaluationActions/clearStoreData', null, {
                root: true
              })

              dispatch('newInspection/evaluationCategories/clearStoreData', null, {
                root: true
              })

              dispatch('newInspection/evaluationPrompt/clearStoreData', null, {
                root: true
              })

              dispatch('newInspection/evaluationPrompts/clearStoreData', null, {
                root: true
              })
            })
          },
          function(e) {
            console.log('offline data get error')
          }
        )
      })
      // hidden due to conflictions
      .finally(() => {
        // unset root loading state
        commit('setIsLoading', false, {
          root: true
        })
      })
  },
  async getEvaluationsList({ commit, state, rootState }, data) {
    // if data equals 'firstLoad'
    // we are getting dispatch from the main inspection page
    if (data.type === 'firstLoad') {
      // clear all pagination data
      commit('clearEvaluationsList')
      commit('clearEvaluationsListPagination')
      commit('clearEvaluationListDateRange')
    }

    // if data equals 'paginate'
    // and paginate current page is smaller than total pages
    if (
      data.type === 'paginate' &&
      state.evaluationsListPagination.current_page === state.evaluationsListPagination.total_pages
    ) {
      // escape whole thing
      return false
    }

    // set loading state
    if (data.type === 'firstLoad') {
      commit('setEvaluationsListIsLoading', true)
    } else {
      commit('setEvaluationsListPaginationIsLoading', true)
    }

    // set empty let for api link
    let apiLinkValue
    // set params object
    const PARAMS_CONST = {}

    // if state.evaluationsListPagination.links object is empty
    if (Object.keys(state.evaluationsListPagination.links).length === 0) {
      // this is first load
      // fill empty api link with base api for evaluationsList data
      apiLinkValue = process.env.apiUrl + '/evaluations'

      // set default params
      PARAMS_CONST.pageSize = 8
      PARAMS_CONST.include = ['contacts']
      PARAMS_CONST.meta = ['date-range-options']

      if (data.search.length > 0) {
        PARAMS_CONST.query = data.search
      }

      if (data.client.length > 0) {
        PARAMS_CONST.team = data.client
      }

      if (data.date.length > 0) {
        PARAMS_CONST.dateRange = data.date
      }
    } else {
      // else evaluationsListPagination next link has data
      // fill empty api link with next pagination link address
      apiLinkValue = state.evaluationsListPagination.links.next
    }

    await axios
      .get(apiLinkValue, {
        params: PARAMS_CONST,
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        if (response.data.data.length) {
          idbs.saveToOfflineStorage('evaluations', response.data.data)
          // idbs.saveToOfflineStorage('syncs',[{ handle: "evaluations", updated_at: new Date().toISOString()}]);

          response.data.data.forEach((item) => {
            commit('setEvaluationsList', item)
          })
        }

        commit('setEvaluationsListPagination', response.data.meta.pagination)
        commit('setEvaluationListDateRange', response.data.meta['date-range-options'])
      })
      .catch((e) => {
        console.log(e)

        const offlineData = idbs.getAllFromOffline('evaluations', PARAMS_CONST.query)

        offlineData.then(
          function(offlineData) {
            console.log('offline data is good')

            offlineData.forEach((item) => {
              commit('setEvaluationsList', item)
            })
          },
          function(e) {
            console.log('offline data error')
          }
        )
      })
      .finally(() => {
        // unset loading state
        if (data.type === 'firstLoad') {
          commit('setEvaluationsListIsLoading', false)
        } else {
          commit('setEvaluationsListPaginationIsLoading', false)
        }
      })
  },
  async getSiteSpecificEvaluationsList({ commit, state, rootState }, data) {
    // if data equals 'firstLoad'
    // we are getting dispatch from the main inspection page
    if (data.type === 'firstLoad') {
      // clear all pagination data
      commit('clearSiteSpecificEvaluationsList')
      commit('clearEvaluationListDateRange')
      commit('clearEvaluationsListPagination')
    }

    // if data equals 'paginate'
    // and paginate current page is smaller than total pages
    if (
      data.type === 'paginate' &&
      state.evaluationsListPagination.current_page === state.evaluationsListPagination.total_pages
    ) {
      // escape whole thing
      return false
    }

    // set loading state
    if (data.type === 'firstLoad') {
      commit('setEvaluationsListIsLoading', true)
    } else {
      commit('setEvaluationsListPaginationIsLoading', true)
    }

    // set empty let for api link
    let apiLinkValue
    // set params object
    const PARAMS_CONST = {}

    // if state.evaluationsListPagination.links object is empty
    if (Object.keys(state.evaluationsListPagination.links).length === 0) {
      // this is first load
      // fill empty api link with base api for evaluationsList data
      apiLinkValue = process.env.apiUrl + '/evaluations'

      // set default params
      PARAMS_CONST.pageSize = 8

      PARAMS_CONST.include = ['latestReport']

      PARAMS_CONST.status = 'sent'

      PARAMS_CONST.meta = ['date-range-options']

      PARAMS_CONST.site = data.site

      if (data.hasOwnProperty('date') && data.date.length > 0) {
        PARAMS_CONST.dateRange = data.date
      }
    } else {
      // else evaluationsListPagination next link has data
      // fill empty api link with next pagination link address
      apiLinkValue = state.evaluationsListPagination.links.next
    }

    await axios
      .get(apiLinkValue, {
        params: PARAMS_CONST,
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        if (response.data.data.length) {
          response.data.data.forEach((item) => {
            commit('setSiteSpecificEvaluationsList', item)
          })
        }

        commit('setEvaluationsListPagination', response.data.meta.pagination)
        commit('setEvaluationListDateRange', response.data.meta['date-range-options'])
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // unset loading state
        if (data.type === 'firstLoad') {
          commit('setEvaluationsListIsLoading', false)
        } else {
          commit('setEvaluationsListPaginationIsLoading', false)
        }
      })
  },
  getCurrentEvaluationProgrammatically({ commit }, data) {
    commit('setIsLoading', false, {
      root: true
    })

    commit('setCurrentEvaluation', data.uuid)
    commit('setCurrentEvaluationData', data)
  },
  async getCurrentEvaluationSignoff({ commit, state, rootState }) {
    // empty old data
    commit('setCurrentEvaluationSignoff', {})

    // set loading state
    commit('setIsLoading', true)

    // retrieve from offline storage
    const offlineSignOffs = idbs.getAllFromOffline('sign_offs', 'evaluation_uuid', state.currentEvaluation)

    offlineSignOffs.then(
        function(offlineSignOffs) {
          commit('setCurrentEvaluationSignoff', offlineSignOffs.shift())
        },
        function(e) {
          console.log('offline data error sign-offs')
        }
    )

    commit('setIsLoading', false)

    await axios
      .get(process.env.apiUrl + '/sign-offs', {
        params: {
          fields: ['signature_asset_base64', 'cross_signature_asset_base64'],
          evaluation: state.currentEvaluation
        },
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          idbs.saveToOfflineStorage('sign_offs', response.data.data)
          commit('setCurrentEvaluationSignoff', response.data.data[0])
        }
      })
      .catch((e) => {
        console.log(e)

        // retrieve from offline storage
        const offlineSignOffs = idbs.getAllFromOffline('sign_offs', 'evaluation_uuid', state.currentEvaluation)

        offlineSignOffs.then(
          function(offlineSignOffs) {
            commit('setCurrentEvaluationSignoff', offlineSignOffs.shift())
          },
          function(e) {
            console.log('offline data error')
          }
        )
      })
      .finally(() => {
        // unset loading state
        commit('setIsLoading', false)
      })
  },

  async saveCurrentEvaluationSignoff({ commit, state, dispatch, rootState }, data) {
    // if data is empty, don't do this!
    if (Object.keys(data).length === 0) {
      return false
    }

    // push current evaluation into the data sent in put
    data.evaluation = state.currentEvaluation

    // console.log('signoff payload data')
    // console.log( data )

    // set loading state
    commit('setIsLoading', true)

    await axios
      .put(process.env.apiUrl + '/sign-offs', data, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          'Cache-Control': 'no-cache'
        }
      })
      .then((response) => {
        dispatch('getCurrentEvaluationSignoff')
      })
      .catch((e) => {
        console.log(e)
      })
      // don't unset loading state as it interrfers with the dispatch
      .finally(() => {
        // set loading state
        commit('setIsLoading', true)
      })
  },
  async wipeAllContentFromOfflineStorage({ commit, state, rootState }, data )
  {
      await idbs.deleteAllFromOfflineStore('evaluation-questionSections')
      await idbs.deleteAllFromOfflineStore('evaluation-prompts')
      await idbs.deleteAllFromOfflineStore('answers')
      await idbs.deleteAllFromOfflineStore('actions')
  },
  async addNewSubcontractor({ commit, state, rootState }, data) {
    commit('setSubcontractorIsLoading', true)

    const FORM_DATA_CONST = new FormData()
    FORM_DATA_CONST.append('title', data)
    FORM_DATA_CONST.append('evaluation', state.currentEvaluation)

    // store offline
    const offlineContractor = {
      uuid: uuid.generate(),
      title: data,
      evaluation_uuid: state.currentEvaluation
    }

    FORM_DATA_CONST.append('uuid', offlineContractor.uuid )

    idbs.saveToOfflineStorage('contacts', [offlineContractor])

    commit('addContactorToCurrentEvaluation', offlineContractor)
    commit('setSubcontractorIsLoading', false)

    await axios
      .post(process.env.apiUrl + '/contacts', FORM_DATA_CONST, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then((response) => {
        commit('addContactToCurrentEvaluation', response.data.data)
        idbs.saveToOfflineStorage('contractors', [response.data.data])
      })
      .catch((e) => {
        console.log(e)
      })

      .finally(() => {
        commit('setSubcontractorIsLoading', false)
      })
  },
  async removeSubcontractor({ commit, state, rootState }, data) {
    // set loading state
    commit('setSubcontractorIsLoading', true)

    commit('removeContractor', data)
    // remove from offline storage
    idbs.deleteFromOfflineStore('contractors', data.uuid )

    commit('setSubcontractorIsLoading', false )

    await axios
      .delete(process.env.apiUrl + '/contacts/' + data.uuid, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          'Cache-Control': 'no-cache'
        }
      })
      .then(() => {
        commit('removeContractor', data)
        idbs.deleteFromOfflineStore('contractors', data.uuid )

      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        commit('setSubcontractorIsLoading', false)
      })
  },
  async updateNoPeopleOnSite({ commit, state, rootState, dispatch }, data) {

    commit('setNoOnSiteIsLoading', true)

    commit('setTotalPeopleOnSite', data.peopleOnSite)

    commit('setOfflineEvaluation', data.currentEvaluation)

    idbs.updateOfflineStorage('evaluations', data.currentEvaluation, { total_people_on_site: data.peopleOnSite })

    commit('updateNumberPeopleOnSiteInCurrentEvaluation', data.peopleOnSite )

    commit('setNoOnSiteIsLoading', false)

    await axios
      .patch(
        process.env.apiUrl + '/evaluations/' + state.currentEvaluation,
        {
          total_people_on_site: data.peopleOnSite
        },
        {
          headers: {
            Authorization: 'Bearer ' + rootState.auth.authToken,
            'Cache-Control': 'no-cache'
          }
        }
      )
      .then((response) => {
        // also keep offline in sync
        idbs.saveToOfflineStorage('evaluations', [response.data.data])

        commit('updateNumberPeopleOnSiteInCurrentEvaluation', response.data.data.total_people_on_site)
      })
      .catch((e) => {
        console.log(e)

        // store this update locally
        idbs.updateOfflineStorage('evaluations', data.currentEvaluation, { total_people_on_site: data.peopleOnSite })

        commit('updateNumberPeopleOnSiteInCurrentEvaluation', data.peopleOnSite)
      })
      .finally(() => {
        commit('setNoOnSiteIsLoading', false)
      })
  },
  clearStoreData({ commit }) {
    commit('setIsLoading', true)
    commit('setNoOnSiteIsLoading', false)
    commit('setSubcontractorIsLoading', false)
    commit('setCurrentEvaluation', '')
    commit('setCurrentEvaluationData', {})
    commit('setCurrentEvaluationSignoff', {})
    commit('setEvaluationTypes', [])
    commit('setEvaluationsListIsLoading', true)
    commit('clearEvaluationsList')
    commit('setEvaluationsListPaginationIsLoading', true)
    commit('clearEvaluationsListPagination')
  }
}
