import axios from '../middleware/axios'

export const state = () => ({
    loading: false,
    busy: false,
    collection: null,
    nestedTeamsCollection: null,
    instance: null,
    instanceByCode: null,
    collectionMeta: null
})

export const getters = {

    getCollection: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return []
        }
        return state.collection
    },
    getInstance: (state) => {
        return state.instance
    },
    getNestedTeamsCollection: (state) => {
        if ( state.nestedTeamsCollection === null || state.nestedTeamsCollection === undefined || state.nestedTeamsCollection.length === 0 ){
            return {}
        }
        return state.nestedTeamsCollection
    },
    getIsLoading: (state) => {
        return state.loading
    }
}

export const mutations = {

    setLoading( state, isLoading ){
        state.loading = isLoading === true
    },
    setBusy( state, isBusy )
    {
      state.busy = isBusy === true
    },
    setCollection: ( state, data ) => {
        state.collection = data
    },

    setCollectionMeta: ( state, data ) => {
        state.collectionMeta = data
    },

    addToCollection: ( state, model ) => {
        if ( state.collection === null )
        {
            state.collection = []
        }
        state.collection.push(model)

        if ( "teams" in model ){

            if ( state.nestedTeamsCollection === null ){
                state.nestedTeamsCollection = {}
            }

            state.nestedTeamsCollection[model.uuid] = []

            for ( const team of model.teams.data ){

                state.nestedTeamsCollection[model.uuid].push( team.uuid )
            }
        }
    },

    setInstance: ( state, model ) => {
        return ( state.instance = model )
    },

    updateInCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }

        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            return
        }

        state.collection[index] = model

    },

    removeFromCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)
        if ( index === -1 ){
            return
        }
        state.collection.splice(index,1)
    }
}

export const actions = {

    clearStore( {commit} ){
        commit("setInstance", null)
        commit("setCollection", null)
        commit("setCollectionMeta", null)
    },
    async collection( { commit, rootState }, filters={}) {

        try {
            commit('setLoading', true)
            commit('setCollectionMeta', null)

            await axios
                .get( process.env.apiUrl + '/evaluation-types',{
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then( function( response ) {
                    commit('setCollection',[] )
                    if ('meta' in response.data ){
                        commit('setCollectionMeta', response.data.meta )
                    }

                    for ( const model of response.data.data ){
                        commit('addToCollection', model )
                    }
                    return response.data
                })
                .catch(function( error ){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    async instance( { commit, rootState }, { id, filters }) {

        commit('setLoading', true)

        try {

            await axios
                .get( process.env.apiUrl +`/evaluation-types/${id}`, {
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                }).then(function( response ){

                    commit('setInstance', response.data.data )

                    return response.data.data

                }).catch( function( error ){
                    return error
                })

        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    // async instanceByCode( { commit, rootState }, { code, filters }) {

    //     commit('setLoading', true)

    //     try {

    //         await axios
    //             .get( process.env.apiUrl +`/evaluation-types/${code}`, {
    //                 params: filters,
    //                 headers: {
    //                     Authorization: 'Bearer ' + rootState.auth.authToken,
    //                     Accept: 'application/json'
    //                 }
    //             }).then(function( response ){

    //                 commit('setInstance', response.data.data )

    //                 return response.data.data

    //             }).catch( function( error ){
    //                 return error
    //             })

    //     }catch ( e ){
    //         throw e
    //     }finally {
    //         commit('setLoading', false)
    //     }

    // },
    store( { commit, rootState }, data ){

        return new Promise( ( resolve, reject ) => {
            try {
                commit('setBusy',true)
                axios
                    .post( process.env.apiUrl +`/evaluation-types`,data,{
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function( response ){
                        commit('addToCollection', response.data.data)

                        if ('location' in response.headers ) {
                            // we have a location to poll for the "building" state
                            commit('setBuildingJobLog', response.headers.location )
                            commit('setBuilding', true )
                        }

                        resolve( response )
                    })
                    .catch(function(error){
                        if ( error.response.data !== undefined ){
                            reject( error.response.data )
                        }else{
                            reject( error )
                        }
                    })

            }catch ( error ){
                reject( error )
            }finally {
                commit('setBusy', false)
            }
        })

    },
    clone( { commit, rootState }, data ){

        return new Promise( ( resolve, reject ) => {
            try {
                commit('setBusy',true)
                axios
                    .post( process.env.apiUrl +`/evaluation-types/${data.uuid}/clone`,data,{
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function( response ){
                        commit('addToCollection', response.data.data)
                        resolve( response )
                    })
                    .catch(function(error){

                        if ( error.response.data !== undefined ){
                            reject( error.response.data )
                        }else{
                            reject( error )
                        }
                    })

            }catch ( error ){
                reject( error )
            }finally {
                commit('setBusy', false)
            }
        })

    },
    update( { commit, rootState }, data ){

        commit('setBusy', true)

        return new Promise( ( resolve, reject ) => {

            try {

                axios
                    .patch(process.env.apiUrl + `/evaluation-types/${data.uuid}`, data, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function (response) {
                        commit('updateInCollection', response.data.data)
                        commit('setInstance', response.data.data)

                       resolve( response.data.data )
                    })
                    .catch(function (error) {
                        if ( error.response.data !== undefined ){
                            reject( error.response.data )
                        }else{
                            reject( error )
                        }
                    })

            } catch (e) {
                resolve( e )
            } finally {
                commit('setBusy', false)
            }
        })
    },
    async destroy( { commit, rootState }, data ){

        commit('setBusy', true)

        try {
            commit('setBusy', true)
            await axios
                .delete( process.env.apiUrl +`/evaluation-types/${data.uuid}`,{
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then(function( response ){
                    commit('removeFromCollection', response.data.data)
                    return response.data.data
                })
                .catch(function(error){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setBusy', false)
        }
    }
}
