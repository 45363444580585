import axios from '../../middleware/axios'
import idbs  from '../../api/offline/indexedDbService'

export const state = () => ({
  isLoading: true,
  ecList: [],
  currentEc: '',
  currentEcTitle: ''
})

export const getters = {
  getIsLoading: (state) => {
    return state.isLoading
  },
  getEcList: (state) => {
    return state.ecList
  },
  getCurrentEc: (state) => {
    return state.currentEc
  },
  getCurrentEcTitle: (state) => {
    return state.currentEcTitle
  }
}

export const mutations = {
  setIsLoading: (state, loading) => {
    state.isLoading = loading === true
  },
  setEcListData: (state, data) => {
    // empty list
    state.ecList = []

    // refill with new data
    data.forEach((item) => {
      state.ecList.push(item)
    })
  },
  clearEcListData: (state) => {
    state.ecList = []
  },
  setCurrentEc: (state, data) => {
    console.log("setCurrentECTo: " + data )
    state.currentEc = data
  },
  setCurrentEcTitle: (state, data) => {
    console.log("setCurrentECTitleTo: " + data )
    state.currentEcTitle = data
  },
  setTrashedEc: (state, data) => {
    // get index of element to toggle trash
    const cardTrashToggle = state.ecList.findIndex((i) => i.uuid === data.cardUuid)

    // inverse current trashed boolean
    state.ecList[cardTrashToggle].trashed = !state.ecList[cardTrashToggle].trashed
  }
}

export const actions = {

  async prefetchCategoriesToOffline({ commit, state, rootState, dispatch }, { evaluationUuid })
  {
    // console.log("prefetch questionSections to offline was called")

    await axios
        .get(process.env.apiUrl + '/evaluation-categories', {
          params: {
            evaluation: evaluationUuid
          },
          headers: {
            Authorization: 'Bearer ' + rootState.auth.authToken
          }
        })
        .then((response) => {
          // commit full category list from api
          console.log('Saving questionSections to offline (after prefetch)')
          idbs.saveToOfflineStorage('evaluation-questionSections',response.data.data )
        })
        .catch((e) => {
          console.log(e)
        })
  },
  async getEcList({ commit, dispatch, rootState }) {
    // unset loading state
    commit('setIsLoading', false, {
      root: true
    })

    // set loading state
    commit('setIsLoading', true)

    const offlineEvaluationCategories = idbs.getAllFromOffline("evaluation-questionSections","evaluation_uuid", rootState.newInspection.evaluation.currentEvaluation )

    offlineEvaluationCategories.then(function( offlineEvaluationCategories)
    {
      // we need to sort by running order here to avoid this re-jigging when the network catches up

      offlineEvaluationCategories.sort(function( a, b )
      {
        const order = 1
        return a.running_order < b.running_order ? -order : a.running_order > b.running_order ? order : 0;
      })

      if ( offlineEvaluationCategories.length > 0 )
      {
        console.log("offline store is not empty, so we use it")

        commit('setEcListData', offlineEvaluationCategories )

        dispatch('getCurrentEc', {
          populate: true,
          value: null
        })

        commit('setIsLoading', false )
      }


    }, function( e )
    {
      console.log( e )
    })

    await axios
      .get(process.env.apiUrl + '/evaluation-categories', {
        params: {
          evaluation: rootState.newInspection.evaluation.currentEvaluation
        },
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        // commmit full category list from api
        console.log("setEcListData from axios response")
        commit('setEcListData', response.data.data)
        idbs.saveToOfflineStorage('evaluation-questionSections',response.data.data )
        // set current category
        /*
        dispatch('getCurrentEc', {
          populate: true,
          value: null
        })

         */
      })
      .catch((e) => {
        console.log(e)

        const offlineEvaluationCategories = idbs.getAllFromOffline('evaluation-questionSections',
            'evaluation_uuid',
            rootState.newInspection.evaluation.currentEvaluation )

        offlineEvaluationCategories.then(function( offlineEvaluationCategories)
        {
          commit('setEcListData', offlineEvaluationCategories )

          console.log("getCurrentEc fired with backup offlineData")
          dispatch('getCurrentEc', {
            populate: true,
            value: null
          })

        }, function( e )
        {
          console.log( 'offline data error' )
        })
      })
      .finally(() => {
        // unset loading
        commit('setIsLoading', false)
      })
  },
  getCurrentEc({ state, commit }, data) {
    // set the current item by uuid
    if (data.populate && data.value === null) {
      console.log("getCurrentEc populate and null value branch ....")
      // Do we have any questionSections in state at the moment ?
      if ( typeof state.ecList[0] !== 'undefined' )
      {
        // if populate is true and data value is null
        // it is first load, just populate with the first option
        commit('setCurrentEc', state.ecList[0].uuid)

        // set modalTitle of the current eval category to the state
        commit('setCurrentEcTitle', state.ecList[0].title)

      }else{
        commit('setCurrentEcTitle', 'Loading')
        console.log('Cannot populate on first load, the ecList is empty')
      }

    } else {
      console.log("getCurrentEc else branch....")
      // else use the value passed to it
      commit('setCurrentEc', data.value)

      // find index in array of current eval category
      const ItemIndex = state.ecList.findIndex((item) => item.uuid === data.value)

      // set modalTitle of the current eval category to the state
      commit('setCurrentEcTitle', state.ecList[ItemIndex].title)
    }
  },
  async trashToggleEc({ state, commit, rootState }, data) {
    commit('setTrashedEc', data)

    // get index of element to toggle trash
    const cardTrashToggle = state.ecList.findIndex((i) => i.uuid === data.cardUuid)

    if (state.ecList[cardTrashToggle].trashed === true) {
      // if this card is being trashed
      // axios update db to reflect this
      await axios
        .delete(process.env.apiUrl + '/evaluation-categories/' + data.cardUuid, {
          headers: {
            Authorization: 'Bearer ' + rootState.auth.authToken,
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        .then((response) => {
          idbs.saveToOfflineStorage('evaluation-questionSections',response.data.data )
        })
        .catch((e) => {
          console.log(e)

          // save this change in offline
          idbs.updateOfflineStorage('evaluation-questionSections', state.ecList[cardTrashToggle],{ trashed: true })

        })
    } else {
      // else this card is being restored
      // axios update db to reflect this
      await axios
        .patch(
          process.env.apiUrl + '/evaluation-categories/' + data.cardUuid + '/restore',
          {
            challenge: 'DELETE'
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootState.auth.authToken,
              'Cache-Control': 'no-cache',
              Accept: 'application/json',
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )
        .then((response) => {
          idbs.saveToOfflineStorage('evaluation-questionSections',response.data.data )
        })
        .catch((e) => {
          console.log(e)

          idbs.updateOfflineStorage('evaluation-questionSections', state.ecList[cardTrashToggle],{ trashed: false })
        })
    }
  },
  clearStoreData({ commit }) {
    commit('setIsLoading', true)
    commit('clearEcListData')
    commit('setCurrentEc', '')
    commit('setCurrentEcTitle', '')
  }
}
