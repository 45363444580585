import { openDB, deleteDB } from 'idb';

const dbPromise = _ => {

    if ( process.browser && !('indexedDB' in window ) )
    {
        throw new Error("browser does not support IDB");
    }

    const dbVersion = 13

    return openDB('havioOffline',dbVersion,
    {
        upgrade(db, oldVersion, newVersion, trx )
        {
            // abandoned idea of using syncs table as impractical with indexed DBs poor ability to filter data

            if ( ! db.objectStoreNames.contains('evaluations') )
            {
                const evaluationsStore = db.createObjectStore('evaluations',{
                    keyPath: 'uuid',
                    autoIncrement: false,
                });

                evaluationsStore.createIndex('dated_at','dated_at')
                evaluationsStore.createIndex('title','title')
            }

            if ( ! db.objectStoreNames.contains('evaluation-categories') )
            {
                const evaluationCategoriesStore = db.createObjectStore('evaluation-categories',{
                    keyPath: 'uuid',
                    autoIncrement: false,
                });
                evaluationCategoriesStore.createIndex('evaluation_uuid','evaluation_uuid', { unique: false })
                evaluationCategoriesStore.createIndex('running_order','running_order', { unique: false })
            }else{
                const evaluationCategoriesStore = trx.objectStore('evaluation-categories')

                if ( ! evaluationCategoriesStore.indexNames.contains('running_order') )
                {
                    evaluationCategoriesStore.createIndex('running_order','running_order', { unique: false })
                }
            }


            if ( ! db.objectStoreNames.contains('evaluation-prompts') )
            {
                const evaluationPromptsStore = db.createObjectStore('evaluation-prompts',{
                    keyPath: 'uuid',
                    autoIncrement: false,
                });

                evaluationPromptsStore.createIndex('evaluation_uuid','evaluation_uuid', { unique: false } )
                evaluationPromptsStore.createIndex('evaluation_category_uuid','evaluation_category_uuid', { unique: false } )
                evaluationPromptsStore.createIndex('offline_only','offline_only', { unique: false } )
                // evaluationPromptsStore.createIndex('rating','rating', { unique: false } )
            }else{
                const evaluationPromptsStore = trx.objectStore('evaluation-prompts')
                if ( ! evaluationPromptsStore.indexNames.contains('offline_only') )
                {
                    evaluationPromptsStore.createIndex('offline_only','offline_only', { unique: false } )
                }
            }

            if ( ! db.objectStoreNames.contains('teams') )
            {
                const teamsStore = db.createObjectStore('teams',{
                    keyPath: 'uuid',
                    autoIncrement: true,
                });

               teamsStore.createIndex('title','title')
            }

            if ( ! db.objectStoreNames.contains('actions') )
            {
                const actionStore = db.createObjectStore('actions',{
                    keyPath: 'uuid',
                    autoIncrement: true,
                });

                actionStore.createIndex('site_uuid','site_uuid', { unique: false })
                actionStore.createIndex('evaluation_uuid','evaluation_uuid', { unique: false })
                //  actionStore.createIndex('answer_uuid','answer_uuid', { unique: false })

            }else{
                const actionStore = trx.objectStore('actions')

                if ( ! actionStore.indexNames.contains('evaluation_uuid') )
                {
                    actionStore.createIndex('evaluation_uuid','evaluation_uuid', { unique: false })
                }
            }

            if ( ! db.objectStoreNames.contains('answers') )
            {
                const answersStore = db.createObjectStore('answers',{
                    keyPath: 'uuid',
                    autoIncrement: true,
                });

                answersStore.createIndex('evaluation_prompt_uuid','evaluation_prompt_uuid', { unique: false })
                answersStore.createIndex('evaluation_uuid','evaluation_uuid', { unique: false })

            }else{
                const answersStore = trx.objectStore('answers')

                if ( ! answersStore.indexNames.contains('evaluation_uuid') )
                {
                    answersStore.createIndex('evaluation_uuid','evaluation_uuid', { unique: false })
                }
            }

            if ( ! db.objectStoreNames.contains('answer-assets') )
            {
                const answerAssetsStore = db.createObjectStore('answer-assets',{
                    keyPath: 'uuid',
                    autoIncrement: true,
                });

                answerAssetsStore.createIndex('model_uuid','model_uuid', { unique: false })
                answerAssetsStore.createIndex('evaluation_uuid','evaluation_uuid', { unique: false } )
            }else{
                const answerAssetsStore = trx.objectStore('answer-assets')
                if ( ! answerAssetsStore.indexNames.contains("evaluation_uuid") )
                {
                    answerAssetsStore.createIndex('evaluation_uuid','evaluation_uuid', { unique: false } )
                }
            }

            if ( ! db.objectStoreNames.contains('contacts') )
            {
                const contractorsStore = db.createObjectStore('contacts',{
                    keyPath: 'uuid',
                    autoIncrement: true,
                });

                contractorsStore.createIndex('model_uuid','model_uuid', { unique: false })
            }

            if ( ! db.objectStoreNames.contains('sign_offs') )
            {
                const signOffStore = db.createObjectStore('sign_offs',{
                    keyPath: 'uuid',
                    autoIncrement: true,
                });

                signOffStore.createIndex('evaluation_uuid','evaluation_uuid')
            }

            if ( ! db.objectStoreNames.contains('offline_stores') )
            {
                db.createObjectStore('offline_stores',{
                    keyPath: 'key',
                    autoIncrement: true,
                });
            }

            if ( ! db.objectStoreNames.contains('secrets') )
            {
                db.createObjectStore('secrets',{
                    keyPath: 'key',
                    autoIncrement: true,
                });
            }

            if ( ! db.objectStoreNames.contains('evaluation-question-sections') )
            {
                const questionSection = db.createObjectStore('evaluation-question-sections',{
                    keyPath: 'uuid',
                    autoIncrement: true,
                });

                questionSection.createIndex('evaluation_uuid','evaluation_uuid')
            }

            if ( ! db.objectStoreNames.contains('evaluation-question-answers') )
            {
                const questionAnswers = db.createObjectStore('evaluation-question-answers',{
                    keyPath: 'uuid',
                    autoIncrement: true,
                });
                questionAnswers.createIndex('evaluation_uuid','evaluation_uuid')
            }


        }
    })

}

const deleteDatabase = async () => {

    try {

        await deleteDB('havioOffline')

    }catch( error ){

        return error
    }

}

const saveToOfflineStorage = async ( storeName, data ) => {

    try {
        const db = await dbPromise()
        const tx = db.transaction( storeName ,'readwrite')
        const store = tx.objectStore( storeName )

        return Promise.all( data.map( datum => store.put( datum ) ))
            .catch(() => {
                tx.abort()
                throw new Error('data was not added to the offline store')
            }).finally(()=> {
                return tx.complete
            })

    }catch( error )
    {
        return error;
    }
}

const updateOfflineStorage = async ( storeName, object, data ) => {

    // console.log('update offline default object is:')
    // console.log( object )
    // console.log( data )

    try {
        const db = await dbPromise()
        const tx = db.transaction( storeName ,'readwrite')
        const store = tx.objectStore( storeName )

        const updatedObject = Object.assign({}, object, data )

        // console.log('updated Obj:')
        // console.log( updatedObject )

        return store.put( updatedObject )

    }catch( error )
    {
        return error;
    }
}

const checkOfflineStorage = async storeName => {

    try {
        const db = await dbPromise()
        const tx = db.transaction( storeName, 'readonly')
        const store = tx.objectStore( storeName )

        return store.get( storeName )

    }catch( error )
    {
        return error;
    }

}

const getAllFromOffline = async ( storeName, indexKey = "", query = "" ) => {

    try{
        const db = await dbPromise()

        if ( indexKey.length > 0 && query.length > 0 ){
            // console.log( "attempting index check from " + storeName + ": " + indexKey + ": " + query )

            const tx    = db.transaction( storeName, 'readonly')
            const store = tx.objectStore( storeName )
            const index = store.index( indexKey )

            return await index.getAll( query );
        }else{
            return await db.getAll( storeName );
        }


    }catch( error )
    {
        return error;
    }
}

const getFromOfflineByPrimaryKey = async ( storeName, primaryKey = "" ) => {

    try{
        const db = await dbPromise()

        const tx    = db.transaction( storeName, 'readonly')
        const store = tx.objectStore( storeName )

        return await store.get( primaryKey );


    }catch( error )
    {
        return error;
    }

}

const deleteFromOfflineStore = async ( storeName, primaryKey ="" ) => {

    // alert(primaryKey);
    try{
        const db = await dbPromise()

        const tx    = db.transaction( storeName, 'readwrite')
        const store = tx.objectStore( storeName )

        return await store.delete( primaryKey );

    }catch( error )
    {
        return error;
    }
}

const deleteAllFromOfflineStore = async ( storeName ) => {
    try {
        const db    = await dbPromise()
        const tx    = db.transaction( storeName, 'readwrite')
        const store = tx.objectStore( storeName )

        return await store.clear();

    }catch( error )
    {
        return error
    }
}

const getAllFromOfflineMultiple = async ( storeName, indexKey = "", query = [] ) => {

    try{

        const db = await dbPromise()

        if ( indexKey.length > 0 && query.length > 0 ){
            // console.log( "attempting index check from " + storeName + ": " + indexKey + ": " + query )

            const tx    = db.transaction( storeName, 'readonly')
            const store = tx.objectStore( storeName )
            const index = store.index( indexKey )

            return await index.getAll(query);
        }else{
            return await db.getAll( storeName );
        }


    }catch( error )
    {
        return error;
    }
}

export default {
    checkOfflineStorage,
    getAllFromOffline,
    getFromOfflineByPrimaryKey,
    saveToOfflineStorage,
    updateOfflineStorage,
    deleteFromOfflineStore,
    deleteAllFromOfflineStore,
    deleteDatabase,
    getAllFromOfflineMultiple
}