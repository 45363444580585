import Vue from 'vue'
import VCalendar from 'v-calendar'

Vue.use(VCalendar, {
  firstDayOfWeek: 2,
  masks: {
    input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD', 'DD/MM/YYYY'],
    data: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD', 'DD/MM/YYYY']
  }
})
