import axios from '../middleware/axios'

export const state = () => ({
  isLoading: false,
  notificationTriggers: [],
  metricKeys: [],
  triggerTypes: []
})

export const getters = {
  getIsLoading: (state) => {
    return state.isLoading
  },
  getNotificationTriggers: (state) => {
    return state.notificationTriggers
  },
  getMetricKeys: (state) => {
    return state.metricKeys
  },
  getTriggerTypes: (state) => {
    return state.triggerTypes
  }
}

export const mutations = {
  setIsLoading: (state, loading) => {
    state.isLoading = loading === true
  },
  setNotificationTriggers: (state, data) => {
    state.notificationTriggers = data
  },
  setMetricKeys: (state, data) => {
    state.metricKeys = data
  },
  setTriggerTypes: (state, data) => {
    state.triggerTypes = data
  }
}

export const actions = {
  async getCurrentClientNotificationTriggers(
    { commit, state, rootState },
    data
  ) {
    if (
      data.inClientSettings === true &&
      rootState.clients.isLoading === false
    ) {
      // set loading state
      commit('clients/setIsLoading', true, {
        root: true
      })
    } else if (data.inClientSettings === false && state.isLoading === false) {
      // set loading state
      commit('setIsLoading', true)
    }

    await axios
      .get(process.env.apiUrl + '/metric-notification-triggers', {
        params: {
          include: ['notifiables'],
          team: data.clientUuid
        },
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setNotificationTriggers', response.data.data)
        commit('setMetricKeys', response.data.meta.metric_keys)
        commit('setTriggerTypes', response.data.meta.trigger_types)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        if (data.removeLoad === true) {
          if (data.inClientSettings === true) {
            // unset loading state
            commit('clients/setIsLoading', false, {
              root: true
            })
          } else {
            // unset loading state
            commit('setIsLoading', false)
          }
        }
      })
  },
  async addNotificationTrigger({ commit, dispatch, rootState }, data) {
    if (data.setLoad) {
      commit('setIsLoading', true)
    }

    // put all form data into a FormData object
    const DISPATCH_DATA = new FormData()
    DISPATCH_DATA.append('metric_key', data.item.metric_key)
    DISPATCH_DATA.append('trigger_value', data.item.trigger_value)
    DISPATCH_DATA.append('notify[]', rootState.auth.userData.uuid)
    DISPATCH_DATA.append('is_enabled', data.item.is_enabled)
    DISPATCH_DATA.append('title', 'My daily inspection trigger')
    DISPATCH_DATA.append('trigger_type', data.item.trigger_type)
    DISPATCH_DATA.append('team', data.item.teamUuid)

    await axios
      .post(
        process.env.apiUrl + '/metric-notification-triggers',
        DISPATCH_DATA,
        {
          headers: {
            Authorization: 'Bearer ' + rootState.auth.authToken,
            Accept: 'application/json'
          }
        }
      )
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        if (data.unsetLoad) {
          // commit('setIsLoading', false)
          dispatch('getCurrentClientNotificationTriggers', {
            inClientSettings: false,
            removeLoad: true,
            clientUuid: data.item.uuid
          })
        }
      })
  },
  async editNotificationTrigger({ commit, dispatch, rootState }, data) {
    if (data.setLoad) {
      commit('setIsLoading', true)
    }

    const DISPATCH_DATA = {}
    DISPATCH_DATA.metric_key = data.item.metric_key
    DISPATCH_DATA.trigger_value = data.item.trigger_value
    DISPATCH_DATA.notify = []
    DISPATCH_DATA.notify[0] = rootState.auth.userData.uuid
    DISPATCH_DATA.is_enabled = data.item.is_enabled
    // DISPATCH_DATA.modalTitle = 'My daily inspection trigger'
    DISPATCH_DATA.trigger_type = data.item.trigger_type
    DISPATCH_DATA.team = data.item.teamUuid

    await axios
      .patch(
        process.env.apiUrl + '/metric-notification-triggers/' + data.item.uuid,
        DISPATCH_DATA,
        {
          headers: {
            Authorization: 'Bearer ' + rootState.auth.authToken,
            'Cache-Control': 'no-cache'
          }
        }
      )
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        if (data.unsetLoad) {
          // commit('setIsLoading', false)
          dispatch('getCurrentClientNotificationTriggers', {
            inClientSettings: false,
            removeLoad: true,
            clientUuid: data.item.uuid
          })
        }
      })
  },
  async deleteNotificationTrigger({ commit, dispatch, rootState }, data) {
    if (data.setLoad) {
      commit('setIsLoading', true)
    }

    await axios
      .delete(
        process.env.apiUrl + '/metric-notification-triggers/' + data.item.uuid,
        {
          headers: {
            Authorization: 'Bearer ' + rootState.auth.authToken,
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        if (data.unsetLoad) {
          // commit('setIsLoading', false)
          dispatch('getCurrentClientNotificationTriggers', {
            inClientSettings: false,
            removeLoad: true,
            clientUuid: data.item.uuid
          })
        }
      })
  }
}
