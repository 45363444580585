import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _5d45b2aa = () => interopDefault(import('../pages/actions.vue' /* webpackChunkName: "pages/actions" */))
const _00588a40 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _55142a9f = () => interopDefault(import('../pages/assessments.vue' /* webpackChunkName: "pages/assessments" */))
const _cd95278e = () => interopDefault(import('../pages/clients/index.vue' /* webpackChunkName: "pages/clients/index" */))
const _aef8b776 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _0b1315a0 = () => interopDefault(import('../pages/forgotten-password.vue' /* webpackChunkName: "pages/forgotten-password" */))
const _7e30b32d = () => interopDefault(import('../pages/inspections.vue' /* webpackChunkName: "pages/inspections" */))
const _f5f72134 = () => interopDefault(import('../pages/organisation-dashboard/index.vue' /* webpackChunkName: "pages/organisation-dashboard/index" */))
const _0a6108bd = () => interopDefault(import('../pages/sites/index.vue' /* webpackChunkName: "pages/sites/index" */))
const _5f4729f9 = () => interopDefault(import('../pages/admin/inspection/index.vue' /* webpackChunkName: "pages/admin/inspection/index" */))
const _656c67cc = () => interopDefault(import('../pages/admin/me.vue' /* webpackChunkName: "pages/admin/me" */))
const _25b64010 = () => interopDefault(import('../pages/admin/membership-plans/index.vue' /* webpackChunkName: "pages/admin/membership-plans/index" */))
const _74940d41 = () => interopDefault(import('../pages/admin/my-people/index.vue' /* webpackChunkName: "pages/admin/my-people/index" */))
const _20cb10f0 = () => interopDefault(import('../pages/admin/my-sites/index.vue' /* webpackChunkName: "pages/admin/my-sites/index" */))
const _7776a35f = () => interopDefault(import('../pages/admin/organisation/index.vue' /* webpackChunkName: "pages/admin/organisation/index" */))
const _8dd15d36 = () => interopDefault(import('../pages/admin/presets/index.vue' /* webpackChunkName: "pages/admin/presets/index" */))
const _ec9fb106 = () => interopDefault(import('../pages/admin/team-categories/index.vue' /* webpackChunkName: "pages/admin/team-categories/index" */))
const _03cc41e0 = () => interopDefault(import('../pages/admin/user/index.vue' /* webpackChunkName: "pages/admin/user/index" */))
const _5ab76448 = () => interopDefault(import('../pages/clients/add.vue' /* webpackChunkName: "pages/clients/add" */))
const _6b384fe7 = () => interopDefault(import('../pages/dashboard/by-category.vue' /* webpackChunkName: "pages/dashboard/by-category" */))
const _6c786c18 = () => interopDefault(import('../pages/dashboard/compare.vue' /* webpackChunkName: "pages/dashboard/compare" */))
const _8f0555de = () => interopDefault(import('../pages/dashboard/inspection.vue' /* webpackChunkName: "pages/dashboard/inspection" */))
const _59c82d62 = () => interopDefault(import('../pages/dashboard/report-builder/index.vue' /* webpackChunkName: "pages/dashboard/report-builder/index" */))
const _31084f33 = () => interopDefault(import('../pages/dashboard/risk-assessment.vue' /* webpackChunkName: "pages/dashboard/risk-assessment" */))
const _419d9208 = () => interopDefault(import('../pages/admin/inspection/add.vue' /* webpackChunkName: "pages/admin/inspection/add" */))
const _2b1ebbc7 = () => interopDefault(import('../pages/admin/membership-plans/add.vue' /* webpackChunkName: "pages/admin/membership-plans/add" */))
const _5670208c = () => interopDefault(import('../pages/admin/team-categories/add.vue' /* webpackChunkName: "pages/admin/team-categories/add" */))
const _6a0ebe42 = () => interopDefault(import('../pages/admin/user/add.vue' /* webpackChunkName: "pages/admin/user/add" */))
const _06f1c99c = () => interopDefault(import('../pages/dashboard/report-builder/create.vue' /* webpackChunkName: "pages/dashboard/report-builder/create" */))
const _5b7e7795 = () => interopDefault(import('../pages/dashboard/report-builder/generate.vue' /* webpackChunkName: "pages/dashboard/report-builder/generate" */))
const _ea813b10 = () => interopDefault(import('../pages/admin/inspection/_inspectionId/index.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/index" */))
const _1c6b3494 = () => interopDefault(import('../pages/admin/my-people/_userId.vue' /* webpackChunkName: "pages/admin/my-people/_userId" */))
const _43958ee6 = () => interopDefault(import('../pages/admin/user/_userId/index.vue' /* webpackChunkName: "pages/admin/user/_userId/index" */))
const _2c27c74d = () => interopDefault(import('../pages/dashboard/report-builder/_id/index.vue' /* webpackChunkName: "pages/dashboard/report-builder/_id/index" */))
const _4a910569 = () => interopDefault(import('../pages/admin/inspection/_inspectionId/categories/index.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/categories/index" */))
const _d4d24cfa = () => interopDefault(import('../pages/admin/inspection/_inspectionId/clone.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/clone" */))
const _0c722c75 = () => interopDefault(import('../pages/admin/inspection/_inspectionId/delete.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/delete" */))
const _7f6adc58 = () => interopDefault(import('../pages/admin/inspection/_inspectionId/edit.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/edit" */))
const _3acf064a = () => interopDefault(import('../pages/admin/inspection/_inspectionId/question-answers/index.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/question-answers/index" */))
const _3091acc4 = () => interopDefault(import('../pages/admin/inspection/_inspectionId/question-sections/index.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/question-sections/index" */))
const _3c3e992a = () => interopDefault(import('../pages/admin/membership-plans/_membershipPlanId/edit.vue' /* webpackChunkName: "pages/admin/membership-plans/_membershipPlanId/edit" */))
const _7a0daada = () => interopDefault(import('../pages/admin/my-sites/_siteId/contacts.vue' /* webpackChunkName: "pages/admin/my-sites/_siteId/contacts" */))
const _0cfa58ca = () => interopDefault(import('../pages/admin/my-sites/_siteId/edit.vue' /* webpackChunkName: "pages/admin/my-sites/_siteId/edit" */))
const _0f4374b2 = () => interopDefault(import('../pages/admin/presets/_presetId/categories/index.vue' /* webpackChunkName: "pages/admin/presets/_presetId/categories/index" */))
const _5a9c6be9 = () => interopDefault(import('../pages/admin/team-categories/_teamCategoryId/edit.vue' /* webpackChunkName: "pages/admin/team-categories/_teamCategoryId/edit" */))
const _5ae61fa6 = () => interopDefault(import('../pages/admin/user/_userId/edit.vue' /* webpackChunkName: "pages/admin/user/_userId/edit" */))
const _59dc102a = () => interopDefault(import('../pages/dashboard/report-builder/_id/category-section.vue' /* webpackChunkName: "pages/dashboard/report-builder/_id/category-section" */))
const _ab2bd994 = () => interopDefault(import('../pages/dashboard/report-builder/_id/comparison-section.vue' /* webpackChunkName: "pages/dashboard/report-builder/_id/comparison-section" */))
const _32f08342 = () => interopDefault(import('../pages/dashboard/report-builder/_id/edit.vue' /* webpackChunkName: "pages/dashboard/report-builder/_id/edit" */))
const _2e577034 = () => interopDefault(import('../pages/dashboard/report-builder/_id/overview-section.vue' /* webpackChunkName: "pages/dashboard/report-builder/_id/overview-section" */))
const _00f0ed10 = () => interopDefault(import('../pages/admin/inspection/_inspectionId/categories/add.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/categories/add" */))
const _0d8a356a = () => interopDefault(import('../pages/admin/inspection/_inspectionId/question-answers/add.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/question-answers/add" */))
const _f6399226 = () => interopDefault(import('../pages/admin/inspection/_inspectionId/question-sections/add.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/question-sections/add" */))
const _1f6f3701 = () => interopDefault(import('../pages/admin/presets/_presetId/categories/add.vue' /* webpackChunkName: "pages/admin/presets/_presetId/categories/add" */))
const _6350ec1c = () => interopDefault(import('../pages/admin/inspection/_inspectionId/categories/_categoryId/index.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/categories/_categoryId/index" */))
const _3ce3f591 = () => interopDefault(import('../pages/dashboard/report-builder/_id/edit-category-section/_section.vue' /* webpackChunkName: "pages/dashboard/report-builder/_id/edit-category-section/_section" */))
const _6761d634 = () => interopDefault(import('../pages/dashboard/report-builder/_id/edit-comparison-section/_section.vue' /* webpackChunkName: "pages/dashboard/report-builder/_id/edit-comparison-section/_section" */))
const _c096d5c6 = () => interopDefault(import('../pages/dashboard/report-builder/_id/sections/_sectionId.vue' /* webpackChunkName: "pages/dashboard/report-builder/_id/sections/_sectionId" */))
const _ac9ad9cc = () => interopDefault(import('../pages/admin/inspection/_inspectionId/categories/_categoryId/edit.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/categories/_categoryId/edit" */))
const _623d3372 = () => interopDefault(import('../pages/admin/inspection/_inspectionId/categories/_categoryId/prompts/index.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/categories/_categoryId/prompts/index" */))
const _b0eaab6c = () => interopDefault(import('../pages/admin/inspection/_inspectionId/question-sections/_questionSectionId/edit.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/question-sections/_questionSectionId/edit" */))
const _30c1d480 = () => interopDefault(import('../pages/admin/inspection/_inspectionId/question-sections/_questionSectionId/questions/index.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/question-sections/_questionSectionId/questions/index" */))
const _cbc6279e = () => interopDefault(import('../pages/admin/presets/_presetId/categories/_categoryId/edit.vue' /* webpackChunkName: "pages/admin/presets/_presetId/categories/_categoryId/edit" */))
const _016cb47e = () => interopDefault(import('../pages/admin/inspection/_inspectionId/categories/_categoryId/prompts/add.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/categories/_categoryId/prompts/add" */))
const _64ff324f = () => interopDefault(import('../pages/admin/inspection/_inspectionId/question-sections/_questionSectionId/questions/add.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/question-sections/_questionSectionId/questions/add" */))
const _32a48268 = () => interopDefault(import('../pages/admin/inspection/_inspectionId/question-sections/_questionSectionId/questions/edit.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/question-sections/_questionSectionId/questions/edit" */))
const _547ff3a1 = () => interopDefault(import('../pages/admin/inspection/_inspectionId/categories/_categoryId/prompts/_promptId/index.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/categories/_categoryId/prompts/_promptId/index" */))
const _578bfb87 = () => interopDefault(import('../pages/admin/inspection/_inspectionId/categories/_categoryId/prompts/_promptId/answers/index.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/categories/_categoryId/prompts/_promptId/answers/index" */))
const _61e290ea = () => interopDefault(import('../pages/admin/inspection/_inspectionId/categories/_categoryId/prompts/_promptId/edit.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/categories/_categoryId/prompts/_promptId/edit" */))
const _673521d4 = () => interopDefault(import('../pages/admin/inspection/_inspectionId/categories/_categoryId/prompts/_promptId/answers/add.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/categories/_categoryId/prompts/_promptId/answers/add" */))
const _12cf94d0 = () => interopDefault(import('../pages/admin/inspection/_inspectionId/categories/_categoryId/prompts/_promptId/answers/_answerId/index.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/categories/_categoryId/prompts/_promptId/answers/_answerId/index" */))
const _06beb47c = () => interopDefault(import('../pages/admin/inspection/_inspectionId/categories/_categoryId/prompts/_promptId/answers/_answerId/edit.vue' /* webpackChunkName: "pages/admin/inspection/_inspectionId/categories/_categoryId/prompts/_promptId/answers/_answerId/edit" */))
const _7906da26 = () => interopDefault(import('../pages/accept/_invite/index.vue' /* webpackChunkName: "pages/accept/_invite/index" */))
const _629bc2a4 = () => interopDefault(import('../pages/clients/_id/index.vue' /* webpackChunkName: "pages/clients/_id/index" */))
const _58e3e1af = () => interopDefault(import('../pages/dashboard/_presetId/index.vue' /* webpackChunkName: "pages/dashboard/_presetId/index" */))
const _3b8c4413 = () => interopDefault(import('../pages/evaluations/_id/index.vue' /* webpackChunkName: "pages/evaluations/_id/index" */))
const _2384f4ff = () => interopDefault(import('../pages/reset/_token/index.vue' /* webpackChunkName: "pages/reset/_token/index" */))
const _1a84550f = () => interopDefault(import('../pages/sites/_siteId/index.vue' /* webpackChunkName: "pages/sites/_siteId/index" */))
const _1ee907b0 = () => interopDefault(import('../pages/clients/_id/edit.vue' /* webpackChunkName: "pages/clients/_id/edit" */))
const _64b5ab2e = () => interopDefault(import('../pages/clients/_id/members/index.vue' /* webpackChunkName: "pages/clients/_id/members/index" */))
const _6fc6c8ce = () => interopDefault(import('../pages/clients/_id/notification-alerts/index.vue' /* webpackChunkName: "pages/clients/_id/notification-alerts/index" */))
const _3b1062c1 = () => interopDefault(import('../pages/clients/_id/settings.vue' /* webpackChunkName: "pages/clients/_id/settings" */))
const _4b82dee1 = () => interopDefault(import('../pages/clients/_id/sites/index.vue' /* webpackChunkName: "pages/clients/_id/sites/index" */))
const _bf5abc3e = () => interopDefault(import('../pages/evaluations/_id/action-plan.vue' /* webpackChunkName: "pages/evaluations/_id/action-plan" */))
const _b1b5886a = () => interopDefault(import('../pages/evaluations/_id/evaluation.vue' /* webpackChunkName: "pages/evaluations/_id/evaluation" */))
const _4944c138 = () => interopDefault(import('../pages/evaluations/_id/questionnaire.vue' /* webpackChunkName: "pages/evaluations/_id/questionnaire" */))
const _6ac56840 = () => interopDefault(import('../pages/evaluations/_id/reports.vue' /* webpackChunkName: "pages/evaluations/_id/reports" */))
const _c9e15d32 = () => interopDefault(import('../pages/evaluations/_id/review.vue' /* webpackChunkName: "pages/evaluations/_id/review" */))
const _77c608fb = () => interopDefault(import('../pages/evaluations/_id/risk-evaluation.vue' /* webpackChunkName: "pages/evaluations/_id/risk-evaluation" */))
const _315272a7 = () => interopDefault(import('../pages/evaluations/_id/summary.vue' /* webpackChunkName: "pages/evaluations/_id/summary" */))
const _91b4e95e = () => interopDefault(import('../pages/sites/_siteId/dashboard.vue' /* webpackChunkName: "pages/sites/_siteId/dashboard" */))
const _6fb4dc7d = () => interopDefault(import('../pages/clients/_id/members/add.vue' /* webpackChunkName: "pages/clients/_id/members/add" */))
const _09b0e21d = () => interopDefault(import('../pages/clients/_id/notification-alerts/add.vue' /* webpackChunkName: "pages/clients/_id/notification-alerts/add" */))
const _48dd3e20 = () => interopDefault(import('../pages/clients/_id/sites/add.vue' /* webpackChunkName: "pages/clients/_id/sites/add" */))
const _0d5c280c = () => interopDefault(import('../pages/clients/_id/notification-alerts/_alertId.vue' /* webpackChunkName: "pages/clients/_id/notification-alerts/_alertId" */))
const _8de0719a = () => interopDefault(import('../pages/clients/_id/sites/_siteId/index.vue' /* webpackChunkName: "pages/clients/_id/sites/_siteId/index" */))
const _3418f6fa = () => interopDefault(import('../pages/sites/_siteId/inspections/_evaluationId.vue' /* webpackChunkName: "pages/sites/_siteId/inspections/_evaluationId" */))
const _7f724459 = () => interopDefault(import('../pages/clients/_id/members/_memberId/edit.vue' /* webpackChunkName: "pages/clients/_id/members/_memberId/edit" */))
const _028ccb9e = () => interopDefault(import('../pages/clients/_id/sites/_siteId/actions.vue' /* webpackChunkName: "pages/clients/_id/sites/_siteId/actions" */))
const _90f82df6 = () => interopDefault(import('../pages/clients/_id/sites/_siteId/contacts/index.vue' /* webpackChunkName: "pages/clients/_id/sites/_siteId/contacts/index" */))
const _d762b2c0 = () => interopDefault(import('../pages/clients/_id/sites/_siteId/inspections.vue' /* webpackChunkName: "pages/clients/_id/sites/_siteId/inspections" */))
const _ab2ebab6 = () => interopDefault(import('../pages/clients/_id/sites/_siteId/notes/index.vue' /* webpackChunkName: "pages/clients/_id/sites/_siteId/notes/index" */))
const _b0e4a4fa = () => interopDefault(import('../pages/clients/_id/sites/_siteId/paperwork-scores/index.vue' /* webpackChunkName: "pages/clients/_id/sites/_siteId/paperwork-scores/index" */))
const _f85c19b8 = () => interopDefault(import('../pages/clients/_id/sites/_siteId/siteinfo.vue' /* webpackChunkName: "pages/clients/_id/sites/_siteId/siteinfo" */))
const _43d6a414 = () => interopDefault(import('../pages/clients/_id/sites/_siteId/contacts/add.vue' /* webpackChunkName: "pages/clients/_id/sites/_siteId/contacts/add" */))
const _c4457498 = () => interopDefault(import('../pages/clients/_id/sites/_siteId/notes/add.vue' /* webpackChunkName: "pages/clients/_id/sites/_siteId/notes/add" */))
const _03e37012 = () => interopDefault(import('../pages/clients/_id/sites/_siteId/paperwork-scores/add.vue' /* webpackChunkName: "pages/clients/_id/sites/_siteId/paperwork-scores/add" */))
const _d2466842 = () => interopDefault(import('../pages/clients/_id/sites/_siteId/notes/_noteId.vue' /* webpackChunkName: "pages/clients/_id/sites/_siteId/notes/_noteId" */))
const _727b7fed = () => interopDefault(import('../pages/clients/_id/sites/_siteId/paperwork-scores/_scoreId.vue' /* webpackChunkName: "pages/clients/_id/sites/_siteId/paperwork-scores/_scoreId" */))
const _f334fd00 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

if (process.client) {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'

    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      window.history.scrollRestoration = 'auto'
    })

    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      window.history.scrollRestoration = 'manual'
    })
  }
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected and scrollToTop is not explicitly disabled
  if (
    to.matched.length < 2 &&
    to.matched.every(r => r.components.default.options.scrollToTop !== false)
  ) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/actions",
      component: _5d45b2aa,
      name: "actions"
    }, {
      path: "/admin",
      component: _00588a40,
      name: "admin"
    }, {
      path: "/assessments",
      component: _55142a9f,
      name: "assessments"
    }, {
      path: "/clients",
      component: _cd95278e,
      name: "clients"
    }, {
      path: "/dashboard",
      component: _aef8b776,
      name: "dashboard"
    }, {
      path: "/forgotten-password",
      component: _0b1315a0,
      name: "forgotten-password"
    }, {
      path: "/inspections",
      component: _7e30b32d,
      name: "inspections"
    }, {
      path: "/organisation-dashboard",
      component: _f5f72134,
      name: "organisation-dashboard"
    }, {
      path: "/sites",
      component: _0a6108bd,
      name: "sites"
    }, {
      path: "/admin/inspection",
      component: _5f4729f9,
      name: "admin-inspection"
    }, {
      path: "/admin/me",
      component: _656c67cc,
      name: "admin-me"
    }, {
      path: "/admin/membership-plans",
      component: _25b64010,
      name: "admin-membership-plans"
    }, {
      path: "/admin/my-people",
      component: _74940d41,
      name: "admin-my-people"
    }, {
      path: "/admin/my-sites",
      component: _20cb10f0,
      name: "admin-my-sites"
    }, {
      path: "/admin/organisation",
      component: _7776a35f,
      name: "admin-organisation"
    }, {
      path: "/admin/presets",
      component: _8dd15d36,
      name: "admin-presets"
    }, {
      path: "/admin/team-categories",
      component: _ec9fb106,
      name: "admin-team-categories"
    }, {
      path: "/admin/user",
      component: _03cc41e0,
      name: "admin-user"
    }, {
      path: "/clients/add",
      component: _5ab76448,
      name: "clients-add"
    }, {
      path: "/dashboard/by-category",
      component: _6b384fe7,
      name: "dashboard-by-category"
    }, {
      path: "/dashboard/compare",
      component: _6c786c18,
      name: "dashboard-compare"
    }, {
      path: "/dashboard/inspection",
      component: _8f0555de,
      name: "dashboard-inspection"
    }, {
      path: "/dashboard/report-builder",
      component: _59c82d62,
      name: "dashboard-report-builder"
    }, {
      path: "/dashboard/risk-assessment",
      component: _31084f33,
      name: "dashboard-risk-assessment"
    }, {
      path: "/admin/inspection/add",
      component: _419d9208,
      name: "admin-inspection-add"
    }, {
      path: "/admin/membership-plans/add",
      component: _2b1ebbc7,
      name: "admin-membership-plans-add"
    }, {
      path: "/admin/team-categories/add",
      component: _5670208c,
      name: "admin-team-categories-add"
    }, {
      path: "/admin/user/add",
      component: _6a0ebe42,
      name: "admin-user-add"
    }, {
      path: "/dashboard/report-builder/create",
      component: _06f1c99c,
      name: "dashboard-report-builder-create"
    }, {
      path: "/dashboard/report-builder/generate",
      component: _5b7e7795,
      name: "dashboard-report-builder-generate"
    }, {
      path: "/admin/inspection/:inspectionId",
      component: _ea813b10,
      name: "admin-inspection-inspectionId"
    }, {
      path: "/admin/my-people/:userId?",
      component: _1c6b3494,
      name: "admin-my-people-userId"
    }, {
      path: "/admin/user/:userId",
      component: _43958ee6,
      name: "admin-user-userId"
    }, {
      path: "/dashboard/report-builder/:id?",
      component: _2c27c74d,
      name: "dashboard-report-builder-id"
    }, {
      path: "/admin/inspection/:inspectionId/categories",
      component: _4a910569,
      name: "admin-inspection-inspectionId-categories"
    }, {
      path: "/admin/inspection/:inspectionId/clone",
      component: _d4d24cfa,
      name: "admin-inspection-inspectionId-clone"
    }, {
      path: "/admin/inspection/:inspectionId/delete",
      component: _0c722c75,
      name: "admin-inspection-inspectionId-delete"
    }, {
      path: "/admin/inspection/:inspectionId/edit",
      component: _7f6adc58,
      name: "admin-inspection-inspectionId-edit"
    }, {
      path: "/admin/inspection/:inspectionId/question-answers",
      component: _3acf064a,
      name: "admin-inspection-inspectionId-question-answers"
    }, {
      path: "/admin/inspection/:inspectionId/question-sections",
      component: _3091acc4,
      name: "admin-inspection-inspectionId-question-sections"
    }, {
      path: "/admin/membership-plans/:membershipPlanId?/edit",
      component: _3c3e992a,
      name: "admin-membership-plans-membershipPlanId-edit"
    }, {
      path: "/admin/my-sites/:siteId?/contacts",
      component: _7a0daada,
      name: "admin-my-sites-siteId-contacts"
    }, {
      path: "/admin/my-sites/:siteId?/edit",
      component: _0cfa58ca,
      name: "admin-my-sites-siteId-edit"
    }, {
      path: "/admin/presets/:presetId/categories",
      component: _0f4374b2,
      name: "admin-presets-presetId-categories"
    }, {
      path: "/admin/team-categories/:teamCategoryId?/edit",
      component: _5a9c6be9,
      name: "admin-team-categories-teamCategoryId-edit"
    }, {
      path: "/admin/user/:userId/edit",
      component: _5ae61fa6,
      name: "admin-user-userId-edit"
    }, {
      path: "/dashboard/report-builder/:id?/category-section",
      component: _59dc102a,
      name: "dashboard-report-builder-id-category-section"
    }, {
      path: "/dashboard/report-builder/:id?/comparison-section",
      component: _ab2bd994,
      name: "dashboard-report-builder-id-comparison-section"
    }, {
      path: "/dashboard/report-builder/:id?/edit",
      component: _32f08342,
      name: "dashboard-report-builder-id-edit"
    }, {
      path: "/dashboard/report-builder/:id?/overview-section",
      component: _2e577034,
      name: "dashboard-report-builder-id-overview-section"
    }, {
      path: "/admin/inspection/:inspectionId/categories/add",
      component: _00f0ed10,
      name: "admin-inspection-inspectionId-categories-add"
    }, {
      path: "/admin/inspection/:inspectionId/question-answers/add",
      component: _0d8a356a,
      name: "admin-inspection-inspectionId-question-answers-add"
    }, {
      path: "/admin/inspection/:inspectionId/question-sections/add",
      component: _f6399226,
      name: "admin-inspection-inspectionId-question-sections-add"
    }, {
      path: "/admin/presets/:presetId/categories/add",
      component: _1f6f3701,
      name: "admin-presets-presetId-categories-add"
    }, {
      path: "/admin/inspection/:inspectionId/categories/:categoryId",
      component: _6350ec1c,
      name: "admin-inspection-inspectionId-categories-categoryId"
    }, {
      path: "/dashboard/report-builder/:id?/edit-category-section/:section",
      component: _3ce3f591,
      name: "dashboard-report-builder-id-edit-category-section-section"
    }, {
      path: "/dashboard/report-builder/:id?/edit-comparison-section/:section",
      component: _6761d634,
      name: "dashboard-report-builder-id-edit-comparison-section-section"
    }, {
      path: "/dashboard/report-builder/:id?/sections/:sectionId",
      component: _c096d5c6,
      name: "dashboard-report-builder-id-sections-sectionId"
    }, {
      path: "/admin/inspection/:inspectionId/categories/:categoryId/edit",
      component: _ac9ad9cc,
      name: "admin-inspection-inspectionId-categories-categoryId-edit"
    }, {
      path: "/admin/inspection/:inspectionId/categories/:categoryId/prompts",
      component: _623d3372,
      name: "admin-inspection-inspectionId-categories-categoryId-prompts"
    }, {
      path: "/admin/inspection/:inspectionId/question-sections/:questionSectionId?/edit",
      component: _b0eaab6c,
      name: "admin-inspection-inspectionId-question-sections-questionSectionId-edit"
    }, {
      path: "/admin/inspection/:inspectionId/question-sections/:questionSectionId?/questions",
      component: _30c1d480,
      name: "admin-inspection-inspectionId-question-sections-questionSectionId-questions"
    }, {
      path: "/admin/presets/:presetId/categories/:categoryId/edit",
      component: _cbc6279e,
      name: "admin-presets-presetId-categories-categoryId-edit"
    }, {
      path: "/admin/inspection/:inspectionId/categories/:categoryId/prompts/add",
      component: _016cb47e,
      name: "admin-inspection-inspectionId-categories-categoryId-prompts-add"
    }, {
      path: "/admin/inspection/:inspectionId/question-sections/:questionSectionId?/questions/add",
      component: _64ff324f,
      name: "admin-inspection-inspectionId-question-sections-questionSectionId-questions-add"
    }, {
      path: "/admin/inspection/:inspectionId/question-sections/:questionSectionId?/questions/edit",
      component: _32a48268,
      name: "admin-inspection-inspectionId-question-sections-questionSectionId-questions-edit"
    }, {
      path: "/admin/inspection/:inspectionId/categories/:categoryId/prompts/:promptId",
      component: _547ff3a1,
      name: "admin-inspection-inspectionId-categories-categoryId-prompts-promptId"
    }, {
      path: "/admin/inspection/:inspectionId/categories/:categoryId/prompts/:promptId/answers",
      component: _578bfb87,
      name: "admin-inspection-inspectionId-categories-categoryId-prompts-promptId-answers"
    }, {
      path: "/admin/inspection/:inspectionId/categories/:categoryId/prompts/:promptId/edit",
      component: _61e290ea,
      name: "admin-inspection-inspectionId-categories-categoryId-prompts-promptId-edit"
    }, {
      path: "/admin/inspection/:inspectionId/categories/:categoryId/prompts/:promptId/answers/add",
      component: _673521d4,
      name: "admin-inspection-inspectionId-categories-categoryId-prompts-promptId-answers-add"
    }, {
      path: "/admin/inspection/:inspectionId/categories/:categoryId/prompts/:promptId/answers/:answerId",
      component: _12cf94d0,
      name: "admin-inspection-inspectionId-categories-categoryId-prompts-promptId-answers-answerId"
    }, {
      path: "/admin/inspection/:inspectionId/categories/:categoryId/prompts/:promptId/answers/:answerId/edit",
      component: _06beb47c,
      name: "admin-inspection-inspectionId-categories-categoryId-prompts-promptId-answers-answerId-edit"
    }, {
      path: "/accept/:invite?",
      component: _7906da26,
      name: "accept-invite"
    }, {
      path: "/clients/:id",
      component: _629bc2a4,
      name: "clients-id"
    }, {
      path: "/dashboard/:presetId",
      component: _58e3e1af,
      name: "dashboard-presetId"
    }, {
      path: "/evaluations/:id?",
      component: _3b8c4413,
      name: "evaluations-id"
    }, {
      path: "/reset/:token?",
      component: _2384f4ff,
      name: "reset-token"
    }, {
      path: "/sites/:siteId",
      component: _1a84550f,
      name: "sites-siteId"
    }, {
      path: "/clients/:id/edit",
      component: _1ee907b0,
      name: "clients-id-edit"
    }, {
      path: "/clients/:id/members",
      component: _64b5ab2e,
      name: "clients-id-members"
    }, {
      path: "/clients/:id/notification-alerts",
      component: _6fc6c8ce,
      name: "clients-id-notification-alerts"
    }, {
      path: "/clients/:id/settings",
      component: _3b1062c1,
      name: "clients-id-settings"
    }, {
      path: "/clients/:id/sites",
      component: _4b82dee1,
      name: "clients-id-sites"
    }, {
      path: "/evaluations/:id?/action-plan",
      component: _bf5abc3e,
      name: "evaluations-id-action-plan"
    }, {
      path: "/evaluations/:id?/evaluation",
      component: _b1b5886a,
      name: "evaluations-id-evaluation"
    }, {
      path: "/evaluations/:id?/questionnaire",
      component: _4944c138,
      name: "evaluations-id-questionnaire"
    }, {
      path: "/evaluations/:id?/reports",
      component: _6ac56840,
      name: "evaluations-id-reports"
    }, {
      path: "/evaluations/:id?/review",
      component: _c9e15d32,
      name: "evaluations-id-review"
    }, {
      path: "/evaluations/:id?/risk-evaluation",
      component: _77c608fb,
      name: "evaluations-id-risk-evaluation"
    }, {
      path: "/evaluations/:id?/summary",
      component: _315272a7,
      name: "evaluations-id-summary"
    }, {
      path: "/sites/:siteId/dashboard",
      component: _91b4e95e,
      name: "sites-siteId-dashboard"
    }, {
      path: "/clients/:id/members/add",
      component: _6fb4dc7d,
      name: "clients-id-members-add"
    }, {
      path: "/clients/:id/notification-alerts/add",
      component: _09b0e21d,
      name: "clients-id-notification-alerts-add"
    }, {
      path: "/clients/:id/sites/add",
      component: _48dd3e20,
      name: "clients-id-sites-add"
    }, {
      path: "/clients/:id/notification-alerts/:alertId?",
      component: _0d5c280c,
      name: "clients-id-notification-alerts-alertId"
    }, {
      path: "/clients/:id/sites/:siteId",
      component: _8de0719a,
      name: "clients-id-sites-siteId"
    }, {
      path: "/sites/:siteId/inspections/:evaluationId?",
      component: _3418f6fa,
      name: "sites-siteId-inspections-evaluationId"
    }, {
      path: "/clients/:id/members/:memberId/edit",
      component: _7f724459,
      name: "clients-id-members-memberId-edit"
    }, {
      path: "/clients/:id/sites/:siteId/actions",
      component: _028ccb9e,
      name: "clients-id-sites-siteId-actions"
    }, {
      path: "/clients/:id/sites/:siteId/contacts",
      component: _90f82df6,
      name: "clients-id-sites-siteId-contacts"
    }, {
      path: "/clients/:id/sites/:siteId/inspections",
      component: _d762b2c0,
      name: "clients-id-sites-siteId-inspections"
    }, {
      path: "/clients/:id/sites/:siteId/notes",
      component: _ab2ebab6,
      name: "clients-id-sites-siteId-notes"
    }, {
      path: "/clients/:id/sites/:siteId/paperwork-scores",
      component: _b0e4a4fa,
      name: "clients-id-sites-siteId-paperwork-scores"
    }, {
      path: "/clients/:id/sites/:siteId/siteinfo",
      component: _f85c19b8,
      name: "clients-id-sites-siteId-siteinfo"
    }, {
      path: "/clients/:id/sites/:siteId/contacts/add",
      component: _43d6a414,
      name: "clients-id-sites-siteId-contacts-add"
    }, {
      path: "/clients/:id/sites/:siteId/notes/add",
      component: _c4457498,
      name: "clients-id-sites-siteId-notes-add"
    }, {
      path: "/clients/:id/sites/:siteId/paperwork-scores/add",
      component: _03e37012,
      name: "clients-id-sites-siteId-paperwork-scores-add"
    }, {
      path: "/clients/:id/sites/:siteId/notes/:noteId",
      component: _d2466842,
      name: "clients-id-sites-siteId-notes-noteId"
    }, {
      path: "/clients/:id/sites/:siteId/paperwork-scores/:scoreId?",
      component: _727b7fed,
      name: "clients-id-sites-siteId-paperwork-scores-scoreId"
    }, {
      path: "/",
      component: _f334fd00,
      name: "index"
    }, {
      path: "/assessments/:id",
      component: _3b8c4413,
      name: "assessments-index"
    }, {
      path: "/assessments/:id/action-plan",
      component: _bf5abc3e,
      name: "assessments-action"
    }, {
      path: "/assessments/:id/evaluation",
      component: _b1b5886a,
      name: "assessments-evaluation"
    }, {
      path: "/assessments/:id/questionnaire",
      component: _4944c138,
      name: "assessments-question"
    }, {
      path: "/assessments/:id/reports",
      component: _6ac56840,
      name: "assessments-reports"
    }, {
      path: "/assessments/:id/review",
      component: _c9e15d32,
      name: "assessments-review"
    }, {
      path: "/assessments/:id/summary",
      component: _315272a7,
      name: "assessments-summary"
    }, {
      path: "/assessments/:id/risk-evaluation",
      component: _77c608fb,
      name: "assessments-risk"
    }],

    fallback: false
  })
}
