import vue from "vue";

export const state = () => ({
    isLoading: true,
    currentActions: [],
    currentAction: {}
})

export const getters = {

    getIsLoading: (state) => {
        return state.isLoading
    },
    getCurrentAction: (state ) => {
        return state.currentAction
    },
    getActions: ( state ) => {
        return state.currentActions
    }

}

export const mutations = {

    setIsLoading: (state, loading) => {
        state.isLoading = loading === true
    },
    setCurrentActions: (state, actions) => {
        state.currentActions = actions
    },
    setCurrentActionByUuid: ( state, actionUuid ) => {

        const index = state.currentActions.findIndex((i) => i.uuid === actionUuid )

        if ( index > -1 )
        {
            state.currentAction = state.currentActions[ index ]
        }
    },
    setActionInCurrentActions: ( state, newAction ) => {

        const existingIndex = state.currentActions.findIndex((i) => i.uuid === newAction.uuid )

        console.log('index ' + existingIndex )

        if ( existingIndex > -1 )
        {
            vue.set( state.currentActions, existingIndex, newAction )

        }else{
            state.currentActions.push( newAction )
        }
    },
    removeActionFromCurrentActions: ( state, actionUuid ) => {

        const existingActionIndex = state.currentActions.findIndex((i) => i.uuid === actionUuid )

        // console.log("removeAnswer :" + answerUuid + " index: " + existingAnswerIndex )

        if ( existingActionIndex > -1 )
        {
            state.currentActions.splice( existingActionIndex, 1 )
        }
    },
    clearCurrentAction: (state ) => {

        state.currentAction = {}
    }

}

export const evalActions = {

    clearCurrentAction({commit} )
    {
        console.log("clearCurrentAction triggered")

        commit("clearCurrentAction")
    },
    getActionByUuid({state,dispatch,commit}, actionUuid )
    {
        commit("setCurrentActionByUuid", actionUuid )
    },
    createAction({ commit, dispatch, state, rootState }, action)
    {
        commit('setIsLoading', true)
        commit("clearCurrentAction")
    },
    updateAction({ commit, dispatch, state, rootState }, action )
    {
        commit('setIsLoading', true)
        commit("clearCurrentAction")
    }
}