import axios from '../middleware/axios'

export const state = () => ({
  isTeamsLoading: true,
  isTeamLoading: true,
  creatingTeamLoading: false,
  team: {},
  teamsList: [],
  teamsListPaginationIsLoading: false,
  teamsListPagination: {
    total: 0,
    count: 0,
    per_page: 0,
    current_page: 0,
    total_pages: 0,
    links: {}
  }
})

export const getters = {
  getIsTeamsLoading: (state) => {
    return state.isTeamsLoading
  },
  getIsTeamLoading: (state) => {
    return state.isTeamLoading
  },
  getCreatingTeamLoading: (state) => {
    return state.creatingTeamLoading
  },
  getDeleting: (state) => {
    return state.deleting
  },
  getTeamsList: (state) => {
    return state.teamsList
  },
  getTeam: (state) => {
    return state.team
  }
}

export const mutations = {
  setIsTeamsLoading: (state, loading) => {
    state.isTeamsLoading = loading === true
  },
  setCreatingTeamLoading: (state, loading) => {
    state.creatingTeamLoading = loading === true
  },
  setIsTeamLoading: (state, loading) => {
    state.isTeamLoading = loading === true
  },
  setDeleting: (state, loading) => {
    state.deleting = loading === true
  },
  setTeamsList: (state, data) => {
    state.teamsList = data
  },
  setTeam: (state, data) => {
    state.team = data
  },
  clearTeamList: (state) => {
    state.teamsList = []
  }
}

export const actions = {
  async getTeamsList({ commit, rootState }, data) {
    if (data === undefined) {
      data = {}
    }

    if (data.hasOwnProperty('setMasterReportLoad')) {
      if (data.setMasterReportLoad === true) {
        commit('dashboard/masterReportSections/setMasterReportSectionIsLoading', true, {
          root: true
        })
      }
    } else {
      commit('setIsTeamsLoading', true)
    }

    await axios
      .get(process.env.apiUrl + '/teams', {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setTeamsList', response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        if (data.hasOwnProperty('unsetMasterReportLoad')) {
          if (data.unsetMasterReportLoad === true) {
            commit('dashboard/masterReportSections/setMasterReportSectionIsLoading', false, {
              root: true
            })
          }
        } else {
          commit('setIsTeamsLoading', false)
        }
      })
  },
  async getTeam({ commit, rootState }, uuid) {
    commit('setIsTeamLoading', true)

    await axios
      .get(process.env.apiUrl + '/teams/' + uuid, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .then((response) => {
        commit('setTeam', response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        commit('setIsTeamLoading', false)
      })
  },
  async createTeam({ commit, rootState, redirect }, formData) {
    commit('setCreatingTeamLoading', true)
    await axios
      .post(process.env.apiUrl + '/teams', formData, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        commit('setCreatingTeamLoading', false)
      })
  },
  async deleteTeam({ commit, rootState }, uuid) {
    commit('setIsTeamsLoading', true)

    await axios
      .delete(process.env.apiUrl + '/teams/' + uuid, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        commit('clearTeamList')
      })
  },
  async updateTeam({ commit, rootState }, formData) {
    commit('setCreatingTeamLoading', true)
    await axios
      .patch(process.env.apiUrl + '/teams', formData, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        commit('setCreatingTeamLoading', false)
      })
  }
}
