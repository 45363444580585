import Vue from 'vue'
import * as moment from 'moment'
import axios from '../middleware/axios'

import idbs from "../api/offline/indexedDbService";

export const state = () => ({
    loading: false,
    busy: false,
    building: false,
    buildingJobLog: null,
    collection: null,
    clientsCollection: null,
    instance: null,
    teamInstance: null,
    collectionMeta: null,
    isBeingCloned: false,
    incompletes: [],
})

export const getters = {

    getCollection: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }
        return [...state.collection].sort( function(a,b) {
            if (a.dated_at < b.dated_at ) { return 1; }
            if (a.dated_at > b.dated_at ) { return -1; }
            if ( a.reference_number < b.reference_number ){ return 1; }
            if ( a.reference_number > b.reference_number ){ return -1; }
            return 0;
        })
    },
    getInspectionsCollection: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }

        return [...state.collection].filter( evaluation => (evaluation.is_assessment === false ) ).sort( function(a,b) {
            if(a.dated_at < b.dated_at) { return 1; }
            if(a.dated_at > b.dated_at) { return -1; }
            if ( a.reference_number < b.reference_number ){ return 1; }
            if ( a.reference_number > b.reference_number ){ return -1; }
            return 0;
        })
    },
    getAssessmentsCollection: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }
        return [...state.collection].filter( evaluation => (evaluation.is_assessment === true ) ).sort( function(a,b) {
            if(a.dated_at < b.dated_at) { return 1; }
            if(a.dated_at > b.dated_at) { return -1; }
            if ( a.reference_number < b.reference_number ){ return 1; }
            if ( a.reference_number > b.reference_number ){ return -1; }
            return 0;
        })
    },
    getPaginationOptions: (state) => {

        if ( state.collectionMeta === null || state.collectionMeta.length === 0 ){
            return null
        }
        return ( "pagination" in state.collectionMeta ) ? state.collectionMeta.pagination : null
    },
    getDateRangeOptions: (state) => {
        if ( state.collectionMeta === null || state.collectionMeta.length === 0 ){
            return null
        }
        if ( 'date-range-options' in state.collectionMeta){
            return state.collectionMeta['date-range-options']
        }else{
            return null
        }
    },
    getStatusOptions: (state) => {
        if ( state.collectionMeta === null || state.collectionMeta.length === 0 ){
            return null
        }

        if ( 'status-options' in state.collectionMeta){
            return state.collectionMeta['status-options']
        }else{
            return null
        }
    },
    getClientsCollection: (state) => {

        if ( state.clientsCollection !== null && state.clientsCollection.length > 0 ){
            return state.clientsCollection
        }
        return []
    },
    getIsLoading: (state) => {
        return state.loading
    },
    getInstance: (state) => {
        return state.instance
    },
    getTeamInstance: (state) => {
        return state.teamInstance
    },
    getIncompletes: (state) => {
        return state.incompletes
    },
    getSubMenuItems: ( state ) => {
        if ( state.instance === null || state.instance === undefined ){
            return []
        }

        let subNav = [];

        switch (true) {

            case state.instance.is_assessment === true && state.instance.evaluation_type_code === 'WRA':
                subNav = [
                    {
                        href: '/assessments/' + state.instance.uuid,
                        linkText: 'Overview'
                    },
                    {
                        href: '/assessments/' + state.instance.uuid + '/evaluation',
                        linkText: 'Assessment'
                    },
                    {
                        href: '/assessments/' + state.instance.uuid + '/review',
                        linkText: 'Review'
                    },
                    {
                        href: '/assessments/' + state.instance.uuid + '/risk-evaluation',
                        linkText: 'Risk Evaluation'
                    },
                    {
                        href: '/assessments/' + state.instance.uuid + '/summary',
                        linkText: 'Sign Off'
                    }
                ];
            break;

            case state.instance.is_assessment === true:
                subNav = [
                    {
                        href: '/assessments/' + state.instance.uuid,
                        linkText: 'Overview'
                    },
                    {
                        href: '/assessments/' + state.instance.uuid + '/questionnaire',
                        linkText: 'General Info'
                    },
                    {
                        href: '/assessments/' + state.instance.uuid + '/evaluation',
                        linkText: 'Assessment'
                    },
                    {
                        href: '/assessments/' + state.instance.uuid + '/review',
                        linkText: 'Review'
                    },
                    {
                        href: '/assessments/' + state.instance.uuid + '/action-plan',
                        linkText: 'Action Plan'
                    },
                ];
            break;

            default:
                subNav = [
                    {
                        href: '/evaluations/' + state.instance.uuid,
                        linkText: 'Overview'
                    },
                    {
                        href: '/evaluations/' + state.instance.uuid + '/evaluation',
                        linkText: 'Inspection'
                    },
                    {
                        href: '/evaluations/' + state.instance.uuid + '/review',
                        linkText: 'Review'
                    },
                    {
                        href: '/evaluations/' + state.instance.uuid + '/action-plan',
                        linkText: 'Action Plan'
                    }
                ]
            break;
        }

        return subNav;
    },
    getEvaluationsCollection: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }
        return [...state.collection].sort( function(a,b) {
            if(a.dated_at < b.dated_at) { return 1; }
            if(a.dated_at > b.dated_at) { return -1; }
            if ( a.reference_number < b.reference_number ){ return 1; }
            if ( a.reference_number > b.reference_number ){ return -1; }
            return 0;
        })
    }
}

export const mutations = {

    setLoading( state, isLoading ){
        state.loading = isLoading === true
    },
    setBusy( state, isBusy )
    {
      state.busy = isBusy === true
    },
    setBuilding( state, isBuilding )
    {
        state.building = isBuilding === true
    },
    setBuildingJobLog( state, jobLog )
    {
        state.buildingJobLog = jobLog
    },
    setCollection: ( state, data ) => {
        state.collection = data
    },

    setCollectionMeta: ( state, data ) => {
        state.collectionMeta = data
    },

    setClientsCollection: ( state, data ) => {
        state.clientsCollection = data
    },

    addToCollection: ( state, model ) => {
        if ( state.collection === null )
        {
            state.collection = []
        }

        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ) {
            state.collection.push(model)
        }else{
            Vue.set(state.collection, index, model )
        }
    },

    setInstance: ( state, model ) => {
        return ( state.instance = model )
    },

    setTeamInstance: ( state, model ) => {
        return ( state.teamInstance = model )
    },

    setIncompleteInstance: ( state, models ) => {
        return ( state.incompletes = models )
    },

    updateInCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }

        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            return
        }
        Vue.set(state.collection, index, model )
        // state.collection[index] = model

    },
    setAsDeletingInCollection:( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }

        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            return
        }
        Vue.set(state.collection[index],"is_deleting", true )
        // state.collection[index] = model

    },

    removeFromCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)
        if ( index === -1 ){
            return
        }
        state.collection.splice(index,1)
    }
}

export const actions = {

    clearInstanceStore( {commit} ){
        commit("setInstance", null)
    },
    clearStore( {commit} ){

        commit("setInstance", null)
        commit("setCollection", null)
        commit("setClientsCollection", null)
        commit("setCollectionMeta", null)
    },
    setInstanceFromCollectionByUuid( {commit, state }, {uuid})
    {
        // console.log("setting instance by uuid: "+ uuid )
        if ( state.collection !== null && state.collection.length > 0 )
        {
            // console.log("collection found try for index")
            const index = state.collection.findIndex((entry) => entry.uuid === uuid)
            if ( index !== -1 ){

                commit("setInstance", state.collection[index] )
            }
        }
    },
    async collection( { commit, rootState }, filters={}) {

        commit('setLoading', true)
        commit('setCollectionMeta', null)

        let offlineCollection = null
        try {

            await idbs.getAllFromOffline('evaluations').then( (response) => {

                offlineCollection = response

            })

        }catch( error )
        {

        }

        try {

            await axios
                .get( process.env.apiUrl + '/evaluations',{
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then( function( response ) {
                    // commit('setCollection',[] )
                    if ('meta' in response.data ){
                        commit('setCollectionMeta', response.data.meta )
                    }

                    const updatedOfflineCollection = []

                    for ( const model of response.data.data ){

                        if ( offlineCollection !== null )
                        {
                            const index = offlineCollection.findIndex((entry) => entry.uuid === model.uuid)

                            if ( index === -1 )
                            {
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }else if ( moment(offlineCollection[index].updated_at ).isBefore( model.updated_at ) )
                            {
                                //  commit('addToCollection', model )
                                //  updatedOfflineCollection.push( model )

                                if(offlineCollection[index].offline_only !== true)
                                {
                                    commit('addToCollection', model )
                                    updatedOfflineCollection.push( model )
                                }
                                else
                                {
                                    commit('addToCollection',offlineCollection[index] )
                                }
                                
                            }
                            else  {
                                    commit('addToCollection',offlineCollection[index] )
                                    if (!offlineCollection[index].hasOwnProperty('previous_evaluation_uuid') )
                                    {
                                        updatedOfflineCollection.push( model )
                                    }
                                    if (!offlineCollection[index].hasOwnProperty('team_uuid') )
                                    {
                                        updatedOfflineCollection.push( model )
                                    }
                                    else if (!offlineCollection[index].hasOwnProperty('cloned_from_uuid') )
                                    {
                                        updatedOfflineCollection.push( model )
                                    }
                                    
                                    // if(!offlineCollection[index].hasOwnProperty('latestReport'))
                                    // {
                                    //     updatedOfflineCollection.push( model )    
                                    // }

                                }

                        }else{
                            updatedOfflineCollection.push( model )
                            commit('addToCollection', model )
                        }

                    }
                    if ( updatedOfflineCollection.length > 0 ){
                        idbs.saveToOfflineStorage('evaluations', updatedOfflineCollection )
                    }
                    return response.data
                })
                .catch(function( error ){

                    if ( offlineCollection !== null )
                    {
                        commit('setCollection', offlineCollection )
                    }
                    return error
                })
        }catch ( e ){

            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    async instance( { commit, rootState }, { id, filters }) {

        commit('setLoading', true)
        let offlineInstance = null
        try {

            await idbs.getFromOfflineByPrimaryKey('evaluations',id ).then( (response) => {

                if ( response !== undefined )
                {
                    offlineInstance = response

                    commit('setInstance', response )
                }

                commit('setLoading', false)
            })

        }catch ( e ){

        }

        try {

            await axios
                .get( process.env.apiUrl +`/evaluations/${id}`, {
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                }).then(function( response ){

                    // check if this is newer than our offline-version
                    if ( offlineInstance !== null ){

                        if ( moment( response.data.data.updated_at ).isAfter( offlineInstance.updated_at ) )
                        {
                            console.log('it came here')
                            commit('setInstance', response.data.data )
                            idbs.saveToOfflineStorage('evaluations', [response.data.data])
                        }
                        
                        if (!offlineInstance.hasOwnProperty('previous_evaluation_uuid') )
                        {
                            commit('setInstance', response.data.data )
                            idbs.saveToOfflineStorage('evaluations', [response.data.data])
                        }

                    }else{
                        commit('setInstance', response.data.data )
                        idbs.saveToOfflineStorage('evaluations', [response.data.data])
                    }

                    if ( "clients" in response.data.data ){
                        commit("setClientsCollection",response.data.data.clients.data)
                    }

                    if ( "team" in response.data.data ){
                        commit("setTeamInstance",response.data.data.team.data)
                    }

                    if ( "incomplete" in response.data.data ){
                        commit("setIncompleteInstance",response.data.data.incomplete.data)
                    }

                    return response.data.data

                }).catch( function( error ){
                    return error
                })

        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    store( { commit, rootState }, data ){

        return new Promise( ( resolve, reject ) => {

            data.offline_only = true
            data.updated_at = moment().format("YYYY-MM-DD HH:mm:ss")

            try {
                commit('setBusy',true)
                axios
                    .post( process.env.apiUrl +`/evaluations`,data,{
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function( response ){

                        commit('addToCollection', response.data.data)

                        if ('location' in response.headers ) {
                            // we have a location to poll for the "building" state
                            commit('setBuildingJobLog', response.headers.location )
                            commit('setBuilding', true )
                        }

                        idbs.saveToOfflineStorage('evaluations', [response.data.data])

                        resolve( response )
                    })
                    .catch(function(error){
                        reject( error )
                    })

            }catch ( error ){
                reject( error )
            }finally {
                commit('setBusy', false)
            }
        })

    },
    clone( { commit, rootState }, data ){

        return new Promise( ( resolve, reject ) => {

            data.offline_only = true
            data.updated_at = moment().format("YYYY-MM-DD HH:mm:ss")

            try {
                commit('setBusy',true)
                axios
                    .post( process.env.apiUrl +`/evaluations/${data.uuid}/clone`,data,{
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function( response ){
                        commit('addToCollection', response.data.data)

                        if ('location' in response.headers ) {
                            // we have a location to poll for the "building" state
                            commit('setBuildingJobLog', response.headers.location )
                            commit('setBuilding', true )
                        }

                        idbs.saveToOfflineStorage('evaluations', [response.data.data])

                        resolve( response )
                    })
                    .catch(function(error){
                        reject( error )
                    })

            }catch ( error ){
                reject( error )
            }finally {
                commit('setBusy', false)
            }
        })

    },
    async update( { commit, rootState }, data ){

        commit('setBusy', true)

        data.offline_only = true
        if (data.dated_at) {
            // data.dated_at = moment(data.dated_at).format("YYYY-MM-DD HH:mm:ss");
            data.dated_at = moment(data.dated_at).format("YYYY-MM-DD");
        }
        data.updated_at = moment().format("YYYY-MM-DD HH:mm:ss")

        /* if(data.good_practice_notes)
        {
            data.good_practice_notes = data.good_practice_notes.replace('NaN', '')
        } */

        /* if(data.good_practice_notes)
        {
            data.good_practice_notes = data.good_practice_notes.replace('NaN', '')
        } */

        try {

            await idbs.getFromOfflineByPrimaryKey('evaluations', data.uuid).then( (offlineEvaluation) => {
                    const updatedOfflineEvaluation = {...offlineEvaluation, ...data}

                    idbs.saveToOfflineStorage('evaluations', [updatedOfflineEvaluation]).then((response) => {
                    // console.log("evaluation updated offline OK")
                    //     console.log( updatedOfflineEvaluation )
                    commit('updateInCollection', updatedOfflineEvaluation )
                    commit('setInstance', updatedOfflineEvaluation)
                    commit('setBusy', false)
                })
            })

        }catch( error ){

        }

        if ( data.local_only !== true )
        {

            try {

                await axios
                    .patch( process.env.apiUrl + `/evaluations/${data.uuid}`,data,{
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function( response ){
                        commit('updateInCollection', response.data.data)
                        commit('setInstance', response.data.data )

                        idbs.saveToOfflineStorage('evaluations', [response.data.data])

                        return response.data.data
                    })
                    .catch(function(error){
                        return error
                    })
            }catch ( e ){
                throw e
            }finally {
                commit('setBusy', false)
            }

        }else{
            return data
        }

    },
    async destroy( { commit, rootState }, data ){

        commit('setBusy', true)

        try {
            await idbs.deleteFromOfflineStore('evaluations', data.uuid).then((response) => {
                commit('removeFromCollection', data )
                commit('setBusy', false)
            })

        }catch( error ){

        }

        try {
            commit('setBusy', true)
            await axios
                .delete( process.env.apiUrl +`/evaluations/${data.uuid}`,{
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then(function( response ){
                    commit('removeFromCollection', response.data.data)
                    return response.data.data
                })
                .catch(function(error){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setBusy', false)
        }
    },
    pollBuildingJob({commit, rootState }, jobLog ){

        return new Promise( ( resolve, reject ) => {

            try {

                axios
                    .get(jobLog, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    }).then( function( response ){

                        if ( typeof response.data.data.is_finished !== "undefined" && response.data.data.is_finished === true )
                        {
                            commit('setBuildingJobLog', null)
                            commit('setBuilding', false )
                        }else{
                            commit('setBuilding', true )
                        }

                        resolve(response.data.data)

                    }).catch(function(error){
                        reject( error )
                    })

            }catch(e){
                console.log(e)
                reject(e)
            }
        })
    },
    updateInstanceFromOffline( { commit, rootState }, data ) {

        commit('setBusy', true)

        return new Promise( ( resolve, reject ) => {

            try {

                idbs.getFromOfflineByPrimaryKey('evaluations', data.uuid).then( (offlineEvaluation) => {

                    axios.patch( process.env.apiUrl + `/evaluations/${offlineEvaluation.uuid}`,offlineEvaluation,{
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                        .then(function( response ){

                            if ( response.data !== undefined && "data" in response.data ){
                                commit('updateInCollection', response.data.data)
                                commit('setInstance', response.data.data )

                                resolve( response.data.data )
                            }

                        })
                        .catch(function(error){
                            reject( error )
                        })

                }).finally(()=> {
                    commit('setBusy', false)
                })

            }catch( error ){
                commit('setBusy', false)
                reject( error )
            }
        })



    },
    wipeAllOfflineDatabase() {

        idbs.deleteAllFromOfflineStore("evaluations")
        idbs.deleteAllFromOfflineStore("evaluation-questionSections")
        idbs.deleteAllFromOfflineStore("evaluation-prompts")
        idbs.deleteAllFromOfflineStore("teams")
        idbs.deleteAllFromOfflineStore("actions")
        idbs.deleteAllFromOfflineStore("answers")
        idbs.deleteAllFromOfflineStore("answer-assets")
        idbs.deleteAllFromOfflineStore("contacts")
        idbs.deleteAllFromOfflineStore("sign_offs")
    },
    async collectionBySite( { commit, rootState }, filters={}) {

        commit('setLoading', true)
        commit('setCollectionMeta', null)

        let offlineCollection = null
        try {

            await idbs.getAllFromOffline('evaluations').then( (response) => {

                offlineCollection = response.filter((entry) => entry.site_uuid === filters.site)
                // console.log('evaluations site filtered - ', JSON.stringify(offlineCollection))
            })

        }catch( error )
        {

        }

        try {

            // debugger
            await axios
                .get( process.env.apiUrl + '/evaluations',{
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then( function( response ) {

                    // commit('setCollection',[] )
                    if ('meta' in response.data ){
                        commit('setCollectionMeta', response.data.meta )
                    }

                    const updatedOfflineCollection = []

                    for ( const model of response.data.data ){

                        if ( offlineCollection !== null )
                        {
                            const index = offlineCollection.findIndex((entry) => entry.uuid === model.uuid)

                            if ( index === -1 )
                            {
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }else if ( moment(offlineCollection[index].updated_at ).isBefore( model.updated_at ) )
                            {
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }else{
                                commit('addToCollection',offlineCollection[index] )
                            }

                        }else{
                            updatedOfflineCollection.push( model )
                            commit('addToCollection', model )
                        }

                    }
                    if ( updatedOfflineCollection.length > 0 ){
                        idbs.saveToOfflineStorage('evaluations', updatedOfflineCollection )
                    }
                    return response.data
                })
                .catch(function( error ){

                    if ( offlineCollection !== null )
                    {
                        commit('setCollection', offlineCollection )
                    }
                    return error
                })
        }catch ( e ){

            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    async collectionOnline( { commit, rootState }, filters={}) {

        commit('setLoading', true)
        commit('setCollectionMeta', null)

        const offlineCollection = null
        // try {

        //     await idbs.getAllFromOffline('evaluations' ).then( (response) => {

        //         offlineCollection = response
        //     })

        // }catch( error )
        // {

        // }

        try {

            await axios
                .get( process.env.apiUrl + '/evaluations',{
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then( function( response ) {
                    // commit('setCollection',[] )
                    if ('meta' in response.data ){
                        commit('setCollectionMeta', response.data.meta )
                    }

                    const updatedOfflineCollection = []

                    for ( const model of response.data.data ){

                        if ( offlineCollection !== null )
                        {
                            const index = offlineCollection.findIndex((entry) => entry.uuid === model.uuid)

                            if ( index === -1 )
                            {
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }else if ( moment(offlineCollection[index].updated_at ).isBefore( model.updated_at ) )
                            {
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }
                            else  {
                                    commit('addToCollection',offlineCollection[index] )
                                    if (!offlineCollection[index].hasOwnProperty('previous_evaluation_uuid') )
                                    {
                                        updatedOfflineCollection.push( model )
                                    }
                                    else if (!offlineCollection[index].hasOwnProperty('team_uuid') )
                                    {
                                        updatedOfflineCollection.push( model )
                                    }
                                    else if (!offlineCollection[index].hasOwnProperty('cloned_from_uuid') )
                                    {
                                        updatedOfflineCollection.push( model )
                                    }
                                    if(!offlineCollection[index].hasOwnProperty('latestReport'))
                                    {
                                        updatedOfflineCollection.push( model )    
                                    }
                                
                                    console.log('updatedOfflineCollection',JSON.stringify(updatedOfflineCollection))
                                }

                        }else{
                            updatedOfflineCollection.push( model )
                            commit('addToCollection', model )
                        }

                    }
                    if ( updatedOfflineCollection.length > 0 ){
                        idbs.saveToOfflineStorage('evaluations', updatedOfflineCollection )
                    }
                    return response.data
                })
                .catch(function( error ){

                    if ( offlineCollection !== null )
                    {
                        commit('setCollection', offlineCollection )
                    }
                    return error
                })
        }catch ( e ){

            throw e
        }finally {
            commit('setLoading', false)
        }

    },
}
