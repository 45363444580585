const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js');
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['axios'] = require('../middleware/axios.js');
middleware['axios'] = middleware['axios'].default || middleware['axios']

middleware['mobile'] = require('../middleware/mobile.js');
middleware['mobile'] = middleware['mobile'].default || middleware['mobile']

middleware['navigation'] = require('../middleware/navigation.js');
middleware['navigation'] = middleware['navigation'].default || middleware['navigation']

export default middleware
