import axios from '../../middleware/axios'

export const state = () => ({
  isUsersLoading: true,
  isUserLoading: true,
  creatingUserLoading: false,
  teamsList: [],
  user: {},
  usersList: [],
  usersListPaginationIsLoading: false,
  usersListPagination: {
    total: 0,
    count: 0,
    per_page: 0,
    current_page: 0,
    total_pages: 0,
    links: {}
  }
})

export const getters = {
  getIsUsersLoading: (state) => {
    return state.isUsersLoading
  },
  getIsUserLoading: (state) => {
    return state.isUserLoading
  },
  getCreatingUserLoading: (state) => {
    return state.creatingUserLoading
  },
  getDeleting: (state) => {
    return state.deleting
  },
  getUsersList: (state) => {
    return state.usersList
  },
  getTeamsList: (state) => {
    return state.teamsList
  },
  getUser: (state) => {
    return state.user
  },
  getUsersListPagination: (state) => {
    return state.usersListPagination
  },
  getUsersListPaginationIsLoading: (state) => {
    return state.usersListPaginationIsLoading
  }
}

export const mutations = {
  setIsUsersLoading: (state, loading) => {
    state.isUsersLoading = loading === true
  },
  setCreatingUserLoading: (state, loading) => {
    state.creatingUserLoading = loading === true
  },
  setIsUserLoading: (state, loading) => {
    state.isUserLoading = loading === true
  },
  setDeleting: (state, loading) => {
    state.deleting = loading === true
  },
  setUsersList: (state, data) => {
    state.usersList.push(data)
  },
  setTeamsList: (state, data) => {
    state.teamsList = data
  },
  setUser: (state, data) => {
    state.user = data
  },
  clearUserList: (state) => {
    state.usersList = []
  },
  clearUsersListPagination: (state, data) => {
    state.usersListPagination = {
      total: 0,
      count: 0,
      per_page: 50,
      current_page: 0,
      total_pages: 0,
      links: {}
    }
  },
  setUsersListPagination: (state, data) => {
    state.usersListPagination = data
  },
  setUsersListPaginationIsLoading: (state, loading) => {
    state.usersListPaginationIsLoading = loading === true
  }
}

export const actions = {
  async getUsersList({ commit, rootState }, data) {
    if (data === undefined || !(data.hasOwnProperty('setLoading') && data.setLoading === false)) {
      commit('setIsUsersLoading', true)
    }

    const PARAMS_CONST = {}

    if (data !== undefined) {
      if (data.searchData && data.searchData.length > 0) {
        PARAMS_CONST.member_query = data.searchData
      }

      if (data.teamData && data.teamData.length > 0) {
        PARAMS_CONST.uuid = data.teamData
      }
    }

    commit('clearUserList')

    await axios
      .get(process.env.apiUrl + '/teams?include[]=members', {
        params: PARAMS_CONST,
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setUsersList', response.data.data)
        commit('setUsersListPagination', response.data.meta.pagination)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        if (data === undefined || !(data.hasOwnProperty('setLoading') && data.setLoading === false)) {
          commit('setIsUsersLoading', false)
        }
      })
  },
  async getUser({ commit, rootState }, uuid) {
    commit('setIsUserLoading', true)

    await axios
      .get(process.env.apiUrl + '/users/' + uuid + '?include[]=team&include[]=role', {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .then((response) => {
        commit('setUser', response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        commit('setIsUserLoading', false)
      })
  },
  async createUser({ commit, rootState }, data) {
    commit('setCreatingUserLoading', true)

    const DISPATCH_DATA = {}

    if (data.form.given_name.length > 0) {
      DISPATCH_DATA.given_name = data.form.given_name
    }

    if (data.form.family_name.length > 0) {
      DISPATCH_DATA.family_name = data.form.family_name
    }

    if (data.form.email.length > 0) {
      DISPATCH_DATA.email = data.form.email
    }

    if (data.form.password !== null) {
      DISPATCH_DATA.password = data.form.password
    }

    if (data.form.roleData.length > 0) {
      DISPATCH_DATA.role = data.form.roleData
    }

    if (data.form.teamData.length > 0) {
      DISPATCH_DATA.team = data.form.teamData
    }

    await axios
      .post(process.env.apiUrl + '/users', DISPATCH_DATA, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json',
          ContentType: 'text/plain'
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        commit('setCreatingUserLoading', false)
      })
  },
  async getTeamsList({ commit, rootState }) {
    commit('setIsUserLoading', true)

    await axios
      .get(process.env.apiUrl + '/teams', {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setTeamsList', response.data.data[0])
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        commit('setIsUserLoading', false)
      })
  },
  async deleteUser({ commit, rootState }, data) {
    if (data === undefined || !(data.hasOwnProperty('setLoading') && data.setLoading === false)) {
      commit('setIsUsersLoading', true)
    }

    await axios
      .delete(process.env.apiUrl + '/users/' + data.uuid, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        commit('clearUserList')
      })
  },
  async updateUser({ commit, rootState }, data) {
    commit('setIsUserLoading', true)

    const DISPATCH_DATA = {}

    if (data.form.given_name.length > 0) {
      DISPATCH_DATA.given_name = data.form.given_name
    }

    if (data.form.family_name.length > 0) {
      DISPATCH_DATA.family_name = data.form.family_name
    }

    if (data.form.email.length > 0) {
      DISPATCH_DATA.email = data.form.email
    }

    if (data.form.password.length > 0) {
      DISPATCH_DATA.password = data.form.password
    }

    if (data.form.role.length > 0) {
      DISPATCH_DATA.role = data.form.role
    }

    if (data.form.teamData.length > 0) {
      DISPATCH_DATA.team = data.form.teamData
    }

    await axios
      .patch(process.env.apiUrl + '/users/' + data.form.uuid, DISPATCH_DATA, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .then(() => {
        this.$router.push({ path: '/admin' })
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        commit('setIsUserLoading', false)
      })
  },
  async setIsUsersLoadingAction({ commit }, data) {
    await commit('setIsUsersLoading', data)
  }
}
