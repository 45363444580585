import moment from 'moment'
import axios from '../../middleware/axios'

export const state = () => ({
  isLoading: false,
  siteMetricsList: [],
  siteMetricsListIsLoading: false,
  siteMetricCurrentCumulativeCard: {},
  // siteMetricGraphLabels: [],
  siteMetricGraphData: {
    labels: [],
    datasets: []
  },
  siteMetricGraphColours: [
    '72,177,154', // green
    '47,189,196', // blue
    '143,117,157', // purple
    '224,32,32' // red
  ],
  rubricsIsLoading: false,
  currentSite: {},
  siteMetricGraphAggregate: {}
})

export const getters = {
  getIsLoading: (state) => {
    return state.isLoading
  },
  getSiteMetricsList: (state) => {
    return state.siteMetricsList
  },
  getSiteMetricsListIsLoading: (state) => {
    return state.siteMetricsListIsLoading
  },
  getSiteMetricCurrentCumulativeCard: (state) => {
    return state.siteMetricCurrentCumulativeCard
  },
  getSiteMetricGraphLabels: (state) => {
    return state.siteMetricGraphLabels
  },
  getSiteMetricGraphData: (state) => {
    return state.siteMetricGraphData
  },
  getCurrentSite: (state) => {
    return state.currentSite
  },
  getSiteMetricGraphAggregate: (state) => {
    return state.siteMetricGraphAggregate
  }
}

export const mutations = {
  setIsLoading: (state, loading) => {
    state.isLoading = loading === true
  },
  setSiteMetricsList: (state, data) => {
    state.siteMetricsList.push(data)
  },
  clearSiteMetricsList: (state) => {
    state.siteMetricsList = []
  },
  setSiteMetricsListIsLoading: (state, loading) => {
    state.siteMetricsListIsLoading = loading === true
  },
  setSiteMetricCurrentCumulativeCard: (state, data) => {
    if (Object.keys(state.siteMetricCurrentCumulativeCard).length > 0) {
      const PROPS_CONST = Object.keys(state.siteMetricCurrentCumulativeCard)

      for (let i = 0; i < PROPS_CONST.length; i++) {
        delete state.siteMetricCurrentCumulativeCard[PROPS_CONST[i]]
      }
    }

    state.siteMetricCurrentCumulativeCard = { ...data }
  },
  setSiteMetricGraphLabels: (state, data) => {
    state.siteMetricGraphData.labels.push(data)
  },
  clearSiteMetricGraphLabels: (state) => {
    state.siteMetricGraphData.labels = []
  },
  setSiteMetricGraphDatasets: (state, data) => {
    state.siteMetricGraphData.datasets.push(data)
  },
  clearSiteMetricGraphDatasets: (state) => {
    state.siteMetricGraphData.datasets = []
  },
  setCurrentSite: (state, data) => {
    state.currentSite = JSON.parse(JSON.stringify(data))
  },
  setSiteMetricGraphAggregate: (state, data) => {
    state.siteMetricGraphAggregate = JSON.parse(JSON.stringify(data))
  },
  clearSiteMetricGraphAggregate: (state) => {
    state.siteMetricGraphAggregate = {}
  }
}

export const actions = {
  async getSiteMetricsList({ commit, state, rootState }, data) {
    commit('clearSiteMetricsList')

    if (data.type !== 'firstLoad' && data.useIsLoading === true) {
      // set loading state
      commit('setSiteMetricsListIsLoading', true)
    }

    let apiLinkValue = ''
    const PARAMS_CONST = {}

    if (data.date.end) {
      PARAMS_CONST.before = moment(data.date.end).format('YYYY-MM-DD')
    }

    if (data.date.start) {
      PARAMS_CONST.since = moment(data.date.start).format('YYYY-MM-DD')
    }

    // if (data.teamTitle.length > 0) {
    // 	PARAMS_CONST.team = data.teamTitle;
    // }

    // if (data.site.length > 0) {
    // 	PARAMS_CONST.site = data.site;
    // }

    // if (data.user.length > 0) {
    // 	PARAMS_CONST.owner = data.user;
    // }

    apiLinkValue = process.env.apiUrl + '/site-metrics/cumulative'

    await axios
      .get(apiLinkValue, {
        params: PARAMS_CONST,
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        if (response.data.data.length) {
          response.data.data.forEach((item) => {
            commit('setSiteMetricsList', item)
          })

          // set current
          if (
            Object.keys(state.siteMetricCurrentCumulativeCard).length === 0 ||
            (data.hasOwnProperty('resetCurrentMetric') && data.resetCurrentMetric === true)
          ) {
            commit('setSiteMetricCurrentCumulativeCard', response.data.data[0][0])
          }
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        if (data.type !== 'firstLoad' && data.useIsLoading === true) {
          // unset loading state
          commit('setSiteMetricsListIsLoading', false)
        }
      })
  },
  setSiteMetricCurrentCumulativeCard({ commit }, data) {
    commit('setSiteMetricCurrentCumulativeCard', data)
  },
  async setSiteMetricsListIsLoadingAction({ commit }, data) {
    await commit('setSiteMetricsListIsLoading', data)
  },
  async getSiteMetricsGraphDataAction({ commit, state, dispatch, rootState }, data) {
    if (data.useIsLoading === true) {
      // set loading state
      commit('setSiteMetricsListIsLoading', true)
    }

    const PARAMS_CONST = {}

    // BEFORE - YYYY-MM-DD
    if (data.hasOwnProperty('before')) {
      PARAMS_CONST.before = moment(data.before).format('YYYY-MM-DD')
    }

    // SINCE - YYYY-MM-DD
    if (data.hasOwnProperty('since')) {
      PARAMS_CONST.since = moment(data.since).format('YYYY-MM-DD')
    }

    // DATE-GROUP - day/month
    // if (data.hasOwnProperty('dateGroup')) {
    // 	PARAMS_CONST['date-group'] = data.dateGroup
    // }

    // TEAM - team uuid
    if (data.hasOwnProperty('team')) {
      PARAMS_CONST.team = data.team
    }

    // SITE - site uuid
    if (data.hasOwnProperty('site')) {
      PARAMS_CONST.site = data.site
    }

    // OWNER - user uuid
    if (data.hasOwnProperty('owner')) {
      PARAMS_CONST.owner = data.owner
    }

    // STATUS - draft/pending/sent
    if (data.hasOwnProperty('status')) {
      PARAMS_CONST.status = data.status
    }

    await axios
      .get(process.env.apiUrl + '/' + state.siteMetricCurrentCumulativeCard.api_link, {
        params: PARAMS_CONST,
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('clearSiteMetricGraphLabels')
        commit('clearSiteMetricGraphDatasets')

        const TOTAL_DATA_POINTS_CONST = response.data.data.length
        const TOTAL_META_LABELS_CONST = Object.keys(response.data.meta).length

        // if there is data points in the response
        if (TOTAL_DATA_POINTS_CONST > 0) {
          // push each of the item labels into the data_const.labels array above
          response.data.data.forEach((item) => {
            commit('setSiteMetricGraphLabels', item.label)
          })
        }

        // if
        // -- there is meta field in the response
        // -- the meta field has a labels array
        // -- there is data points in the response
        if (TOTAL_META_LABELS_CONST > 0 && response.data.meta.hasOwnProperty('labels') && TOTAL_DATA_POINTS_CONST > 0) {
          // temp array
          const TEMP_ARRAY_CONST = []

          // for each of the meta labels
          if (typeof response.data.meta.labels !== 'string') {
            response.data.meta.labels.forEach((META_LABEL_ITEM, META_LABEL_INDEX) => {
              // loop each data points data and push it to the datasets
              response.data.data.forEach((DATA_ITEM) => {
                // push the data item point at the number of the meta labels index
                TEMP_ARRAY_CONST.push(DATA_ITEM.data[META_LABEL_INDEX])
              })

              // push to DATA_CONST.datasets
              commit('setSiteMetricGraphDatasets', {
                label: META_LABEL_ITEM,
                data: TEMP_ARRAY_CONST,
                pointBackgroundColor: 'rgba(' + state.siteMetricGraphColours[META_LABEL_INDEX] + ', 1)',
                pointBorderColor: 'rgba(' + state.siteMetricGraphColours[META_LABEL_INDEX] + ', 1)',
                pointBorderWidth: 2,
                backgroundColor: 'rgba(' + state.siteMetricGraphColours[META_LABEL_INDEX] + ', 0.20)',
                borderColor: 'rgba(' + state.siteMetricGraphColours[META_LABEL_INDEX] + ', 1)',
                borderWidth: 1.5
              })
            })
          } else {
            // loop each data points data and push it to the datasets
            response.data.data.forEach((DATA_ITEM) => {
              // push the data item point at the number of the meta labels index
              TEMP_ARRAY_CONST.push(DATA_ITEM.data[0])
            })

            // push to DATA_CONST.datasets
            commit('setSiteMetricGraphDatasets', {
              label: response.data.meta.labels,
              data: TEMP_ARRAY_CONST,
              pointBackgroundColor: 'rgba(' + state.siteMetricGraphColours[0] + ', 1)',
              pointBorderColor: 'rgba(' + state.siteMetricGraphColours[0] + ', 1)',
              pointBorderWidth: 2,
              backgroundColor: 'rgba(' + state.siteMetricGraphColours[0] + ', 0.20)',
              borderColor: 'rgba(' + state.siteMetricGraphColours[0] + ', 1)',
              borderWidth: 1.5
            })
          }
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        if (data.useIsLoading === true) {
          // set loading state
          commit('setSiteMetricsListIsLoading', false)
        }
      })
  },
  async getSiteMetricsComparisonGraphDataAction({ commit, state, dispatch, rootState }, data) {
    if (data.useIsLoading === true) {
      // set loading state
      commit('setSiteMetricsListIsLoading', true)
    }

    const PARAMS_CONST = {}

    // BEFORE - YYYY-MM-DD
    if (data.hasOwnProperty('before')) {
      PARAMS_CONST.before = moment(data.before).format('YYYY-MM-DD')
    }

    // SINCE - YYYY-MM-DD
    if (data.hasOwnProperty('since')) {
      PARAMS_CONST.since = moment(data.since).format('YYYY-MM-DD')
    }

    if (data.hasOwnProperty('compare')) {
      PARAMS_CONST.compare = data.compare
    }

    if (data.hasOwnProperty('compare_key')) {
      PARAMS_CONST.compare_key = data.compare_key
    }

    await axios
      .get(process.env.apiUrl + '/' + state.siteMetricCurrentCumulativeCard.api_link, {
        params: PARAMS_CONST,
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('clearSiteMetricGraphLabels')
        commit('clearSiteMetricGraphDatasets')
        commit('clearSiteMetricGraphAggregate')

        const TOTAL_DATA_POINTS_CONST = response.data.data.length
        const TOTAL_META_LABELS_CONST = Object.keys(response.data.meta.labels).length

        // if there is data points in the response
        if (TOTAL_DATA_POINTS_CONST > 0) {
          // push each of the item labels into the data_const.labels array above
          response.data.data.forEach((item) => {
            commit('setSiteMetricGraphLabels', item.label)
          })
        }

        // if
        // -- there is meta field in the response
        // -- the meta field has a labels array
        // -- there is data points in the response
        if (TOTAL_META_LABELS_CONST > 0 && response.data.meta.hasOwnProperty('labels') && TOTAL_DATA_POINTS_CONST > 0) {
          // for each of the meta labels
          Object.keys(response.data.meta.labels).forEach((META_LABEL_ITEM, META_LABEL_INDEX) => {
            // temp array
            const TEMP_ARRAY_CONST = []

            // loop each data points data and push it to the datasets
            response.data.data.forEach((DATA_ITEM) => {
              // push the data item point at the number of the meta labels index
              TEMP_ARRAY_CONST.push(DATA_ITEM.data[META_LABEL_ITEM])
            })

            // push to DATA_CONST.datasets
            commit('setSiteMetricGraphDatasets', {
              label: response.data.meta.labels[META_LABEL_ITEM],
              data: TEMP_ARRAY_CONST,
              pointBackgroundColor: 'rgba(' + state.siteMetricGraphColours[META_LABEL_INDEX] + ', 1)',
              pointBorderColor: 'rgba(' + state.siteMetricGraphColours[META_LABEL_INDEX] + ', 1)',
              pointBorderWidth: 2,
              backgroundColor: 'rgba(' + state.siteMetricGraphColours[META_LABEL_INDEX] + ', 0.20)',
              borderColor: 'rgba(' + state.siteMetricGraphColours[META_LABEL_INDEX] + ', 1)',
              borderWidth: 1.5
            })
          })
        }

        if (response.data.meta.hasOwnProperty('aggregate') && Object.keys(response.data.meta.aggregate).length > 0) {
          commit('setSiteMetricGraphAggregate', response.data.meta.aggregate)
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        if (data.useIsLoading === true) {
          // set loading state
          commit('setSiteMetricsListIsLoading', false)
        }
      })
  },
  async getCurrentSiteAction({ commit, rootState }, data) {
    commit('setCurrentSite', {})

    let apiLinkValue = ''
    const PARAMS_CONST = {
      include: ['team']
    }

    apiLinkValue = process.env.apiUrl + '/sites/' + data.uuid

    await axios
      .get(apiLinkValue, {
        params: PARAMS_CONST,
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        if (Object.keys(response.data.data).length > 0) {
          commit('setCurrentSite', response.data.data)
        } else {
          commit('setCurrentSite', {})
        }
      })
      .catch((e) => {
        console.log(e)
      })
  },
  async clearSiteMetricsGraphDataAction({ commit }) {
    await commit('clearSiteMetricGraphLabels')
    await commit('clearSiteMetricGraphDatasets')
    await commit('clearSiteMetricGraphAggregate')
  }
}
