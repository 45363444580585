import axios from '../middleware/axios'

export const actions = {
  async createDistributions({ rootState }, data) {
    await axios
      .post(process.env.apiUrl + '/distributions', data, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}
