import Vue from 'vue'
import axios from '../middleware/axios'

export const state = () => ({
    loading: false,
    busy: false,
    collection: null,
    instance: null,
    collectionMeta: null
})

export const getters = {

    getCollection: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return []
        }
        return [...state.collection].sort( function(a,b) {
            if(a.title < b.title) { return -1; }
            if(a.title > b.title) { return 1; }
            return 0;
        })
    },
    getPaginationOptions: (state) => {

        if ( state.collectionMeta === null || state.collectionMeta.length === 0 ){
            return null
        }
        return ( "pagination" in state.collectionMeta ) ? state.collectionMeta.pagination : null
    },
    getInstance: (state) => {
        return state.instance
    },
    getIsLoading: (state) => {
        return state.loading
    },
    getSubMenuItems: (state) => {
        if ( state.instance === null ){
            return []
        }
        return [
            {
                href: '/sites/' + state.instance.uuid,
                linkText: 'Inspections'
            },
            {
                href: '/sites/' + state.instance.uuid + '/dashboard',
                linkText: 'Dashboard',
                class: 'remove-active'
            },
        ]
    },
}

export const mutations = {

    setLoading( state, isLoading ){
        state.loading = isLoading === true
    },
    setBusy( state, isBusy )
    {
      state.busy = isBusy === true
    },
    setCollection: ( state, data ) => {
        state.collection = data
    },

    setCollectionMeta: ( state, data ) => {
        state.collectionMeta = data
    },

    addToCollection: ( state, model ) => {
        if ( state.collection === null )
        {
            state.collection = []
        }

        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1){
            state.collection.push(model)
        }else{
            Vue.set(state.collection, index, model )
        }
    },

    setInstance: ( state, model ) => {
        return ( state.instance = model )
    },

    updateInCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }

        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            return
        }

        Vue.set(state.collection, index, model )

    },

    removeFromCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)
        if ( index === -1 ){
            return
        }
        state.collection.splice(index,1)
    }
}

export const actions = {

    clearStore( {commit} ){
        commit("setInstance", null)
        commit("setCollection", null)
        commit("setCollectionMeta", null)
    },

    // async collection( { commit, rootState }, filters={}) {

    //     commit('setLoading', true)
    //     commit('setCollectionMeta', null)

    //     try {

    //         await axios
    //             .get( process.env.apiUrl + '/sites',{
    //                 params: filters,
    //                 headers: {
    //                     Authorization: 'Bearer ' + rootState.auth.authToken,
    //                     Accept: 'application/json'
    //                 }
    //             })
    //             .then( function( response ) {
    //                 // commit('setCollection',[] )
    //                 if ('meta' in response.data ){
    //                     commit('setCollectionMeta', response.data.meta )
    //                 }
    //                 for ( const model of response.data.data ){
    //                     commit('addToCollection', model )
    //                 }

    //                 return response.data
    //             })
    //             .catch(function( error ){
    //                 return error
    //             })
    //     }catch ( e ){
    //         throw e
    //     }finally {
    //         commit('setLoading', false)
    //     }

    // },
    async collection({ commit, rootState }, filters = {}) {
        commit('setLoading', true);
        commit('setCollectionMeta', null);
      
        try {
          const response = await axios.get(process.env.apiUrl + '/sites', {
            params: filters,
            headers: {
              Authorization: 'Bearer ' + rootState.auth.authToken,
              Accept: 'application/json',
            },
          });
      
          if ('meta' in response.data) {
            commit('setCollectionMeta', response.data.meta);
          }
      
          // Clear the collection before adding new data to it.
          commit('setCollection', []);
      
          for (const model of response.data.data) {
            commit('addToCollection', model);
          }
      
          return response.data;
        } catch (error) {
          throw error;
        } finally {
          commit('setLoading', false);
        }
      },      
    async instance( { commit, rootState }, { id, filters }) {

        commit('setLoading', true)

        try {

            await axios
                .get( process.env.apiUrl +`/sites/${id}`, {
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                }).then(function( response ){

                    commit('setInstance', response.data.data )

                    return response.data.data

                }).catch( function( error ){
                    return error
                })

        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    store( { commit, rootState }, data ){

        return new Promise( ( resolve, reject ) => {

            commit('setBusy',true)

            try {

                axios
                    .post( process.env.apiUrl +`/sites`,data,{
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function( response ){
                        commit('addToCollection', response.data.data)

                        resolve( response )
                    })
                    .catch(function(error){
                        if (error.response.data !== undefined) {
                            reject(error.response.data)
                        } else {
                            reject(error)
                        }
                    })

            }catch ( error ){
                reject( error )
            }finally {
                commit('setBusy', false)
            }
        })

    },
    // update( { commit, rootState }, data ){

    //     return new Promise( ( resolve, reject ) => {
    //         commit('setBusy', true)

    //         try {

    //             axios
    //                 .patch(process.env.apiUrl + `/sites/${data.uuid}`, data, {
    //                     headers: {
    //                         Authorization: 'Bearer ' + rootState.auth.authToken,
    //                         Accept: 'application/json'
    //                     }
    //                 })
    //                 .then(function (response) {

    //                     if ( response.data !== undefined && "data" in response.data ) {
    //                         commit('updateInCollection', response.data.data)
    //                         commit('setInstance', response.data.data)
    //                     }

    //                     resolve( response )
    //                 })
    //                 .catch(function (error) {
    //                     if (error.response.data !== undefined) {
    //                         reject(error.response.data)
    //                     } else {
    //                         reject(error)
    //                     }
    //                 })
    //         } catch (e) {
    //             reject( e )
    //         } finally {
    //             commit('setBusy', false)
    //         }
    //     })

    // },
    async update({ commit, rootState }, data) {
        commit('setBusy', true);
      
        try {
          const response = await axios.patch(
            process.env.apiUrl + `/sites/${data.uuid}`,
            data,
            {
              headers: {
                Authorization: 'Bearer ' + rootState.auth.authToken,
                Accept: 'application/json',
              },
            }
          );
      
          if (response.data !== undefined && 'data' in response.data) {
            commit('updateInCollection', response.data.data);
            commit('setInstance', response.data.data);
          }
      
          return response;
        } catch (error) {
          if (error.response && error.response.data !== undefined) {
            throw error.response.data;
          } else {
            throw error;
          }
        } finally {
          commit('setBusy', false);
        }
      },
      
    async destroy( { commit, rootState }, data ){

        commit('setBusy', true)

        try {
            commit('setBusy', true)
            await axios
                .delete( process.env.apiUrl +`/sites/${data.uuid}`,{
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then(function( response ){
                    commit('removeFromCollection', response.data.data)
                    return response.data.data
                })
                .catch(function(error){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setBusy', false)
        }
    }
}
