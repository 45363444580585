import axios from '../middleware/axios'

function downloadFile(data, filename, mime, bom) {
    const blobData = (typeof bom !== 'undefined') ? [bom, data] : [data]
    const blob = new Blob(blobData, {type: mime || 'application/octet-stream'});
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, filename);
    }
    else {
        const blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename);

        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        // Fixes "webkit blob resource error 1"
        setTimeout(function() {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }, 200)
    }
}

export const state = () => ({
    loading: false,
    cumulative: null,
    cumulativeBreakdown: null,
    instance: null,
    totals: null,
    totalsMeta: null,
    dataset: null,
    datasetMeta: null,
    datasetLoading: false,
    exportDatasetLoading: false,
    categoryMetrics: null,
    categoryMetricsLoading: false,
    categoryMetricsMeta: null,
    actionsBreakdown: null
})

export const getters = {

    getCumulative: (state) => {
        if (state.cumulative === null || state.cumulative.length === 0) {
            return null
        }

        return state.cumulative
    },

    getCumulativeBreakdown: (state) => {
        return state.cumulativeBreakdown
    },

    getActionsBreakdown: (state) => {
        return state.actionsBreakdown
    },

    getCumulativePrimaryDataset: (state) => {
        if (state.cumulative === null || state.cumulative.length === 0) {
            return null
        }

        return ( state.cumulative[0] === undefined ) ? null : state.cumulative[0]
    },

    getTotals: (state) => {
        if (state.totals === null || state.totals.length === 0) {
            return null
        }

        return state.cumulative
    },
    getDataset: (state) => {
        if (state.dataset === null || state.dataset.length === 0) {
            return null
        }

        return state.dataset
    },

    getDatasetMeta: (state) => {
        if (state.datasetMeta === null || state.datasetMeta.length === 0) {
            return null
        }

        return state.datasetMeta
    },

    getInstance: (state) => {
        return state.instance
    },

    getIsLoading: (state) => {
        return state.loading
    },
    getDatasetIsLoading: (state) => {
        return state.datasetLoading
    },
    getExportDatasetIsLoading: (state) => {
        return state.exportDatasetLoading
    }
}

export const mutations = {

    setLoading( state, isLoading ){
        state.loading = isLoading === true
    },
    setDatasetLoading( state, isLoading ){
        state.datasetLoading = isLoading === true
    },
    setExportDatasetLoading( state, isLoading ){
        state.exportDatasetLoading = isLoading === true
    },
    setCumulative: ( state, data ) => {
        state.cumulative = data
    },
    setCumulativeBreakdown: ( state, data ) => {
        state.cumulativeBreakdown = data
    },
    setActionsBreakdown: ( state, data ) => {
        state.actionsBreakdown = data
    },
    setTotals: ( state, data ) => {
        state.totals = data
    },
    setTotalsMeta: ( state, data ) => {
        state.totalsMeta = data
    },
    setDataset: ( state, data ) => {
        state.dataset = data
    },
    setDatasetMeta: ( state, data ) => {
        state.datasetMeta = data
    },
    setInstanceByKey: (state, metricKey ) => {

        if ( state.cumulative !== null && state.cumulative.length > 0 )
        {
            const index = state.cumulative.findIndex( (entry) => entry.key === metricKey )

            if ( index !== -1 ){
                state.instance = state.cumulative[index]
            }
        }
    }
    //
    // addToCollection: ( state, model ) => {
    //     if ( state.collection === null )
    //     {
    //         state.collection = []
    //     }
    //     const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)
    //
    //     if ( index === -1 ){
    //         state.collection.push(model)
    //     }
    //
    // },
    //
    // upsertToCollection: (state, model ) => {
    //     if ( state.collection === null )
    //     {
    //         state.collection = []
    //     }
    //     const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)
    //
    //     if ( index === -1 ){
    //         state.collection.push(model)
    //     }else{
    //         Vue.set(state.collection, index, model )
    //     }
    // },
    //
    // setInstance: ( state, model ) => {
    //     return ( state.instance = model )
    // },
    // clearInstance: ( state ) => {
    //     state.instance = null
    // },
    // updateInCollection: ( state, model ) =>
    // {
    //     if ( ! state.collection || state.collection.length < 1 )
    //     {
    //         return
    //     }
    //
    //     const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)
    //
    //     if ( index === -1 ){
    //         return
    //     }
    //
    //     Vue.set(state.collection, index, model )
    //
    //     // also update any filtered collection
    //
    //     if ( state.filteredCollection && state.filteredCollection.length > 0 )
    //     {
    //         const index = state.filteredCollection.findIndex((entry) => entry.uuid === model.uuid)
    //         if ( index !== -1 ){
    //             Vue.set(state.filteredCollection, index, model)
    //         }
    //     }
    // },
    //
    // removeFromCollection: ( state, model ) =>
    // {
    //     if ( ! state.collection || state.collection.length < 1 )
    //     {
    //         return
    //     }
    //     const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)
    //     if ( index === -1 ){
    //         return
    //     }
    //     state.collection.splice(index,1)
    // }
}

export const actions = {

    clearStore( {commit} ){
        commit("setCumulative", null)
        commit("setTotals", null)
        commit("setTotalsMeta", null)
        commit('setDataset', null);
        commit('setDatasetMeta', null);
    },

    async totals( { commit, rootState }, filters={}) {

        commit('setLoading', true)
        commit('setTotalsMeta', null)

        try {

            await axios
                .get( process.env.apiUrl + '/site-metrics/totals',{
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then( function( response ) {
                    commit('setTotals',[] )
                    if ('meta' in response.data ){
                        commit('setTotalsMeta', response.data.meta )
                    }
                    commit('setTotals',response.data.data )

                    return response.data
                })
                .catch(function( error ){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    async cumulative( { commit, rootState }, { filters }) {

        commit('setLoading', true)

        // console.log("filters set to cumulative")
        // console.log( filters )

        try {

            await axios
                .get( process.env.apiUrl +`/site-metrics/cumulative`, {
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                }).then(function( response ){
                    commit('setCumulative', response.data.data )

                    return response.data.data

                }).catch( function( error ){
                    return error
                })

        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },


    async cumulativeOverview( { commit, rootState }, { filters }) {

        commit('setLoading', true)

        // console.log("filters set to cumulative")
        // console.log( filters )

        try {

            await axios
                .get( process.env.apiUrl +`/site-metrics/overview`, {
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                }).then(function( response ){
                    commit('setCumulative', response.data.data )

                    return response.data.data

                }).catch( function( error ){
                    return error
                })

        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },

    async cumulativeBreakdown( { commit, rootState }, { filters }) {

        commit('setLoading', true)

        // console.log("filters set to cumulative")
        // console.log( filters )

        try {

            await axios
                .get( process.env.apiUrl +`/site-metrics/cumulative-trends/breakdown`, {
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                }).then(function( response ){
                    commit('setCumulativeBreakdown', response.data )
                    return response.data

                }).catch( function( error ){
                    return error
                })

        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },

    async actionsBreakdown( { commit, rootState }, { filters }) {

        commit('setLoading', true)

        // console.log("filters set to cumulative")
        // console.log( filters )

        try {

            await axios
                .get( process.env.apiUrl +`/site-metrics/action-totals/breakdown`, {
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                }).then(function( response ){
                    commit('setActionsBreakdown', response.data.data )
                    return response.data

                }).catch( function( error ){
                    return error
                })

        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },

    async metricDataset( { commit, rootState }, { metricType, filters }) {

        commit('setDatasetLoading', true)

        try {

            await axios
                .get( process.env.apiUrl +`/`+ metricType.api_link, {
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                }).then(function( response ){
                    commit('setDataset', response.data.data )
                    
                    if ('meta' in response.data ){
                        commit('setDatasetMeta', response.data.meta )
                    }

                    return response.data.data

                }).catch( function( error ){
                    return error
                })

        }catch ( e ){
            throw e
        }finally {
            commit('setDatasetLoading', false)
        }

    },

    async exportMetricDataset( { commit, rootState }, { metricType, filters }) {

        commit('setExportDatasetLoading', true)

        try {

            const endpoint = new URL(process.env.apiUrl +`/`+ metricType.api_link);
            endpoint.pathname = endpoint.pathname + '/export'

            await axios
                .get( endpoint.toString(), {
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                }).then(function( response ){

                    downloadFile(response.data, 'export.csv', response.headers['content-type']);

                }).catch( function( error ){
                    return error
                })

        }catch ( e ){
            throw e
        }finally {
            commit('setExportDatasetLoading', false)
        }

    }

}
