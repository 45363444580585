export const state = () => ({
  mainNavList: [
    {
      id: 0,
      title: 'Clients',
      iconName: 'Clients',
      colour: 'normal',
      href: '/clients',
      normalLink: false,
      hidden: false
    },
    {
      id: 1,
      title: 'Inspections',
      iconName: 'HeaderChecklist',
      colour: 'normal',
      href: '/inspections',
      normalLink: false,
      hidden: false
    },
    {
      id: 9,
      title: 'Assessments',
      iconName: 'HeaderChecklist',
      colour: 'normal',
      href: '/assessments',
      normalLink: false,
      hidden: false
    },
    {
      id: 2,
      title: 'Actions',
      iconName: 'Actions',
      colour: 'normal',
      href: '/actions',
      normalLink: false,
      hidden: true,
      class: 'c-icon-nav__link--action-icon'
      // count: 0
    },
    {
      id: 3,
      title: 'Sites',
      iconName: 'Sites',
      colour: 'normal',
      href: '/sites',
      normalLink: false,
      hidden: true
    },
    {
      id: 4,
      title: 'Dashboard',
      iconName: 'Dashboard',
      colour: 'normal',
      href: '/dashboard',
      normalLink: false,
      hidden: false
    },
    {
      id: 5,
      title: 'Admin',
      iconName: 'Admin',
      colour: 'normal',
      href: '/admin',
      normalLink: false,
      hidden: false
    },
    {
      id: 11,
      title: 'Dashboard',
      iconName: 'Dashboard',
      colour: 'normal',
      href: '/organisation-dashboard',
      normalLink: false,
      hidden: false
    },
    {
      id: 10,
      title: 'Admin',
      iconName: 'Admin',
      colour: 'normal',
      href: '/admin/me',
      normalLink: false,
      hidden: true
    }
  ],
  mainNavActiveId: 0
})

export const getters = {
  getMainNavList: (state) => {
    return state.mainNavList
  },
  getMainNavActiveId: (state) => {
    return state.mainNavActiveId
  }
}

export const mutations = {
  setMainNavActiveId: (state, data) => {
    state.mainNavActiveId = data
  },
  setHiddenStateOfLink: (state, data) => {
    if (state.mainNavList === null) {
      state.mainNavList = []
    }
    const index = state.mainNavList.findIndex((entry) => entry.id === data.index)
    if (index === -1) {
      return
    }

    state.mainNavList[index].hidden = data.state
  },
  setActionsCount: (state, data) => {
    state.mainNavList[2].count = data
  }
}

export const actions = {
  changeMainNavActiveId({ commit }, data) {
    if (typeof data === 'number' && data >= 0) {
      commit('setMainNavActiveId', data)
    }
  },
  rebuildNav({ commit, dispatch, rootState }, data) {
    let visibleMenuItems = []
    if (data === 'Actions') {
      visibleMenuItems = [2, 3, 11, 10]
    } else {
      visibleMenuItems = [0, 1, 9, 4, 5]
    }

    for (let i = 0; i <= 11; i++) {
      if (visibleMenuItems.includes(i)) {
        commit('setHiddenStateOfLink', {
          index: i,
          state: false
        })
      } else {
        commit('setHiddenStateOfLink', {
          index: i,
          state: true
        })
      }
    }
  }
}
