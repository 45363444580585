import moment from 'moment'
import axios from '../../middleware/axios'

export const state = () => ({
  isLoading: false,
  categoryMetricsList: [],
  siteList: [],
  userList: [],
  categoryMetricsRubricList: [],
  categoryMetricsListIsLoading: false,
  rubricsIsLoading: false
})

export const getters = {
  getIsLoading: (state) => {
    return state.isLoading
  },
  getSiteList: (state) => {
    return state.siteList
  },
  getUserList: (state) => {
    return state.userList
  },
  getCategoryMetricsList: (state) => {
    return state.categoryMetricsList
  },
  getCategoryMetricsRubricList: (state) => {
    return state.categoryMetricsRubricList
  },
  getCategoryMetricsListIsLoading: (state) => {
    return state.categoryMetricsListIsLoading
  },
  getRubricsIsLoading: (state) => {
    return state.rubricsIsLoading
  }
}

export const mutations = {
  setIsLoading: (state, loading) => {
    state.isLoading = loading === true
  },
  setCategoryMetricsList: (state, data) => {
    state.categoryMetricsList.push(data)
  },
  setSiteList: (state, data) => {
    state.siteList.push(data)
  },
  setUserList: (state, data) => {
    state.userList.push(data)
  },
  setCategoryMetricsRubricList: (state, data) => {
    state.categoryMetricsRubricList.push(data)
  },
  clearCategoryMetricsList: (state) => {
    state.categoryMetricsList = []
  },
  clearCategoryMetricsRubricsList: (state) => {
    state.categoryMetricsRubricList = []
  },
  clearSiteList: (state) => {
    state.siteList = []
  },
  clearUserList: (state) => {
    state.userList = []
  },
  setCategoryMetricsListIsLoading: (state, loading) => {
    state.categoryMetricsListIsLoading = loading === true
  },
  setRubricsIsLoading: (state, loading) => {
    state.rubricsIsLoading = loading === true
  }
}

export const actions = {
  async getSiteList({ commit, rootState }, data) {
    if (data.hasOwnProperty('setLoad')) {
      if (data.setLoad === true) {
        commit('setIsLoading', true, {
          root: true
        })
      }
    }

    if (data.type === 'firstLoad') {
      commit('clearSiteList')
      // commit('setCategoryMetricsListIsLoading', true);
    }

    let apiLinkValue = ''
    const PARAMS_CONST = {}

    if (data.hasOwnProperty('teamUuid')) {
      PARAMS_CONST.team = data.teamUuid
    }

    apiLinkValue = process.env.apiUrl + '/sites'

    await axios
      .get(apiLinkValue, {
        params: PARAMS_CONST,
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        if (response.data.data.length) {
          response.data.data.forEach((item) => {
            commit('setSiteList', item)
          })
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // unset loading state
        // commit('setCategoryMetricsListIsLoading', false);
        if (data.hasOwnProperty('unsetLoad')) {
          if (data.unsetLoad === true) {
            commit('setIsLoading', false, {
              root: true
            })
          }
        }
      })
  },
  async clearSiteList({ commit }) {
    await commit('clearSiteList')
  },
  async getUserList({ commit, rootState }, data) {
    if (data.hasOwnProperty('setLoad')) {
      if (data.setLoad === true) {
        commit('setIsLoading', true, {
          root: true
        })
      }
    }

    if (data.type === 'firstLoad') {
      commit('clearUserList')
      // commit('setCategoryMetricsListIsLoading', true);
    }

    let apiLinkValue = ''
    const PARAMS_CONST = {}

    if (data.hasOwnProperty('teamUuid')) {
      PARAMS_CONST.team = data.teamUuid
    }

    apiLinkValue = process.env.apiUrl + '/users'

    await axios
      .get(apiLinkValue, {
        params: PARAMS_CONST,
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        if (response.data.data.length) {
          response.data.data.forEach((item) => {
            commit('setUserList', item)
          })
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // unset loading state
        // commit('setCategoryMetricsListIsLoading', false);
        if (data.hasOwnProperty('unsetLoad')) {
          if (data.unsetLoad === true) {
            commit('setIsLoading', false, {
              root: true
            })
          }
        }
      })
  },
  async clearUserList({ commit }) {
    await commit('clearUserList')
  },
  async getCategoryMetricsList({ commit, rootState }, data) {
    commit('clearCategoryMetricsList')
    commit('clearCategoryMetricsRubricsList')

    // set loading state
    commit('setCategoryMetricsListIsLoading', true)

    let apiLinkValue = ''
    const PARAMS_CONST = {}

    PARAMS_CONST.before = moment(data.before).format('YYYY-MM-DD')
    PARAMS_CONST.since = moment(data.since).format('YYYY-MM-DD')

    if (data.hasOwnProperty('client')) {
      PARAMS_CONST.team = data.client
    }

    if (data.hasOwnProperty('site')) {
      PARAMS_CONST.site = data.site
    }

    if (data.hasOwnProperty('user')) {
      PARAMS_CONST.owner = data.user
    }

    if (data.hasOwnProperty('status')) {
      PARAMS_CONST.status = data.status
    }

    apiLinkValue = process.env.apiUrl + '/category-metrics'

    await axios
      .get(apiLinkValue, {
        params: PARAMS_CONST,
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        if (response.data.data.length) {
          response.data.data.forEach((item) => {
            commit('setCategoryMetricsList', item)
          })
        }

        if (response.data.meta.rubric.length) {
          commit('setRubricsIsLoading', true)
          response.data.meta.rubric.forEach((item) => {
            commit('setCategoryMetricsRubricList', item)
          })
          commit('setRubricsIsLoading', false)
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // unset loading state
        commit('setCategoryMetricsListIsLoading', false)
      })
  },
  async createCategoryMetric({ commit, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    // set login error message null
    commit('setCategoryMetricCreationErrors', null)

    // put all form data into a FormData object
    const DISPTACH_DATA = new FormData()

    if (data.categoryMetric.length > 0) {
      DISPTACH_DATA.append('categoryMetric', data.categoryMetric)
    }

    DISPTACH_DATA.append('date_point', moment(data.date_point).format('YYYY-MM-DD'))

    if (data.favourite.length > 0) {
      DISPTACH_DATA.append('favourite', data.favourite)
    }

    if (data.team.length > 0) {
      DISPTACH_DATA.append('team', data.team)
    }

    await axios
      .post(process.env.apiUrl + '/categoryMetrics', DISPTACH_DATA, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .then((response) => {
        this.$router.push({ path: '/categoryMetrics/edit/' + response.data.data.uuid })
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // unset loading state
        commit('setIsLoading', false)
      })
  },
  async editCategoryMetric({ commit, state, dispatch, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    const DISPATCH_DATA = {}

    if (data.form.categoryMetric.length > 0) {
      DISPATCH_DATA.categoryMetric = data.form.categoryMetric
    }

    if (data.form.date_point.length > 0) {
      DISPATCH_DATA.date_point = moment(data.form.date_point).format('YYYY-MM-DD')
    }

    if (data.form.favourite.length > 0) {
      DISPATCH_DATA.favourite = data.form.favourite
    }

    if (data.form.teamData.length > 0) {
      DISPATCH_DATA.team = data.form.teamData
    }

    await axios
      .patch(process.env.apiUrl + '/categoryMetrics/' + data.uuid, DISPATCH_DATA, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          'Cache-Control': 'no-cache'
        }
      })
      .then(() => {})
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // set loading state
        commit('setIsLoading', false)
      })
  },
  async getCurrentCategoryMetric({ commit, state, rootState }, data) {
    if (state.isLoading === false) {
      // set loading state
      commit('setIsLoading', true)
    }

    await axios
      .get(process.env.apiUrl + '/categoryMetrics/' + data, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setCategoryMetricCurrent', response.data.data)
      })
      .catch((e) => {
        console.log(e)

        if (e.response.status === 404) {
          this.$router.push({ path: '/categoryMetrics' })
        }
      })
      .finally(() => {
        // set loading state
        commit('setIsLoading', false)
      })
  },
  async updateCurrentCategoryMetric({ commit, state, rootState }, data) {
    if (state.isLoading === false) {
      // set loading state
      commit('setIsLoading', true)
    }
    await axios
      .patch(process.env.apiUrl + '/categoryMetrics/' + data.uuid, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setCategoryMetricCurrent', response.data.data)
      })
      .catch((e) => {
        console.log(e)

        if (e.response.status === 404) {
          this.$router.push({ path: '/categoryMetrics' })
        }
      })
      .finally(() => {
        // set loading state
        commit('setIsLoading', false)
      })
  },
  async setCategoryMetricsListIsLoadingAction({ commit }, data) {
    await commit('setCategoryMetricsListIsLoading', data)
  }
}
