import axios from '../middleware/axios'

export const state = () => ({
    loading: false,
    collection: null,
    instance: null,
    collectionMeta: null,
})

export const getters = {

    getCollection: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }
        return state.collection
    },
    getInstance: (state) => {
        return state.instance
    },
    getIsLoading: (state) => {
        return state.loading
    }
}

export const mutations = {

    setLoading( state, isLoading ){
        state.loading = isLoading === true
    },

    setCollection: ( state, data ) => {
        state.collection = data
    },

    setCollectionMeta: ( state, data ) => {
        state.collectionMeta = data
    },

    addToCollection: ( state, model ) => {
        if ( state.collection === null )
        {
            state.collection = []
        }
        state.collection.push(model)
    },

    setInstance: ( state, model ) => {
        return ( state.instance = model )
    },

    updateInCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }

        const index = state.collection.findIndex((entry) => entry.id === model.id)

        if ( index === -1 ){
            return
        }

        state.collection[index] = model

    },

    removeFromCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }
        const index = state.collection.findIndex((entry) => entry.id === model.id)
        if ( index === -1 ){
            return
        }
        state.collection.splice(index,1)
    }
}

export const actions = {
    clearStore( {commit} ){
        commit("setInstance", null)
        commit("setCollection", null)
        commit("setCollectionMeta", null)
    },
    collection( { commit, rootState }, filters={}) {

        commit('setLoading', true)

        return new Promise( ( resolve, reject ) => {
            commit('setCollectionMeta', null)
            axios
                .get( process.env.apiUrl + '/evaluation-presets',{
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then( function( response ) {
                    commit('setCollection',[] )
                    if ('meta' in response.data ){
                        commit('setCollectionMeta', response.data.meta )
                    }
                    for ( const model of response.data.data ){
                        commit('addToCollection', model )
                    }
                    commit('setLoading', false)
                    resolve( response )
                })
                .catch(function( error ){
                    commit('setLoading', false)
                    reject( error )
                })
        })
    },
    setInstanceFromCollectionByUuid( { commit, state },{uuid} ){

        if ( state.collection && state.collection.length > 0 )
        {
            const index = state.collection.findIndex((entry) => entry.uuid === uuid )

            if ( index !== -1 ){
                commit("setInstance", state.collection[index] )
            }
        }

    },
    instance( { commit, rootState }, { id, filters }) {
        return new Promise( (resolve, reject ) => {

            commit('setLoading', true)

            axios
                .get( process.env.apiUrl +`/evaluation-presets/${id}`, {
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                }).then(function( response ){
                commit('setInstance', response.data.data )
                commit('setLoading', false)
                resolve( response )
            }).catch( function( error ){
                commit('setLoading', false)
                reject( error )
            })
        })
    },
    store( { commit, rootState }, data ){
        return new Promise((resolve, reject ) => {
            axios
                .post( process.env.apiUrl +`/evaluation-presets`,data,{
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then(function( response ){
                    commit('addToCollection', response.data.data)
                    resolve( response )
                })
                .catch(function(error){
                    reject( error )
                })
        })
    },
    update( { commit, rootState }, data ){
        return new Promise((resolve, reject ) => {
            axios
                .patch( process.env.apiUrl + `/evaluation-presets/${data.uuid}`,data,{
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then(function( response ){
                    commit('updateInCollection', response.data.data)
                    resolve( response )
                })
                .catch(function(error){
                    reject( error )
                })
        })
    },
    destroy( { commit, rootState }, data ){
        return new Promise((resolve, reject ) => {
            axios
                .delete( process.env.apiUrl +`/evaluation-presets/${data.uuid}`,{
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then(function( response ){
                    commit('removeFromCollection', response.data.data)
                    resolve( response )
                })
                .catch(function(error){
                    reject( error )
                })
        })
    }
}
