import Vue from 'vue'
import axios from '../middleware/axios'
import idbs from "../api/offline/indexedDbService"
import uuidService from "../api/offline/uuidService";

export const state = () => ({
    loading: false,
    busy: false,
    collection: null,
    instance: null,
    collectionMeta: null
})

export const getters = {

    getCollection: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return []
        }
        return state.collection
    },
    getFirstItemInCollection: (state) => {

        if (state.collection === null || state.collection.length === 0) {
            return  null
        }
        return state.collection[0]
    },
    getIsLoading: (state) => {
        return state.loading
    }
}

export const mutations = {

    setLoading( state, isLoading ){
        state.loading = isLoading === true
    },
    setBusy( state, isBusy )
    {
      state.busy = isBusy === true
    },
    setCollection: ( state, data ) => {
        state.collection = data
    },

    setCollectionMeta: ( state, data ) => {
        state.collectionMeta = data
    },

    addToCollection: ( state, model ) => {
        if ( state.collection === null )
        {
            state.collection = []
        }

        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            state.collection.push(model)
        }else{
            Vue.set(state.collection, index, model )
        }
    },

    setInstance: ( state, model ) => {
        return ( state.instance = model )
    },

    updateInCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }

        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            return
        }
        Vue.set(state.collection, index, model )

    },

    removeFromCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)
        if ( index === -1 ){
            return
        }
        state.collection.splice(index,1)
    }
}

export const actions = {

    clearStore( {commit} ){
        commit("setInstance", null)
        commit("setCollection", null)
        commit("setCollectionMeta", null)
    },

    async collection( { commit, rootState }, filters={}) {

        commit('setLoading', true)
        commit('setCollectionMeta', null)

        try {

            await idbs.getAllFromOffline('sign_offs','evaluation_uuid', filters.evaluation ).then( (response) => {

                commit('setCollection', response )

                commit('setLoading', false)
            })

        }catch( error )
        {

        }

        try {

            await axios
                .get( process.env.apiUrl + '/sign-offs',{
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then( function( response ) {
                    // commit('setCollection',[] )
                    if ('meta' in response.data ){
                        commit('setCollectionMeta', response.data.meta )
                    }
                    for ( const model of response.data.data ){
                        commit('addToCollection', model )
                    }

                    idbs.saveToOfflineStorage('sign_offs', response.data.data)

                    return response.data
                })
                
                .catch(function( error ){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    async instance( { commit, rootState }, { id, filters }) {

        commit('setLoading', true)

        try {

            await axios
                .get( process.env.apiUrl +`/sign-offs/${id}`, {
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                }).then(function( response ){

                    commit('setInstance', response.data.data )

                    idbs.saveToOfflineStorage('sign_offs', [response.data.data])
                    return response.data.data

                }).catch( function( error ){
                    return error
                })

        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    store( { commit, rootState }, data ){

        return new Promise( ( resolve, reject ) => {

            commit('setBusy',true)

            try {

                axios
                    .post( process.env.apiUrl +`/sign-offs`,data,{
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function( response ){
                        commit('addToCollection', response.data.data)

                        idbs.saveToOfflineStorage('sign_offs', [response.data.data])

                        resolve( response )
                    })
                    .catch(function(error){
                        reject( error )
                    })

            }catch ( error ){
                reject( error )
            }finally {
                commit('setBusy', false)
            }
        })

    },
    async update( { commit, rootState }, data ){

        commit('setBusy', true)

        try {

            await axios
                .patch( process.env.apiUrl + `/sign-offs/${data.uuid}`,data,{
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then(function( response ){
                    commit('updateInCollection', response.data.data)
                    commit('setInstance', response.data.data )

                    idbs.saveToOfflineStorage('sign_offs', [response.data.data])

                    return response.data.data
                })
                .catch(function(error){
                   return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setBusy', false)
        }
    },
    async upsert( { commit, rootState }, { data, options } ){

        commit('setBusy', true)

        try {

            if ( !( "uuid" in data) ) {
                data.uuid = uuidService.generate()
            }
            
            if ( "evaluation" in data ) {
                data.evaluation_uuid = data.evaluation
            }

            if ( "cross_signature_asset" in data ){
                data.cross_signature_asset_base64 = data.cross_signature_asset
            }

            if ( "signature_asset" in data ){
                data.signature_asset_base64 = data.signature_asset
            }

            data.offline_only = true

            await idbs.saveToOfflineStorage('sign_offs', [data])

            commit('addToCollection', data )
            commit('setInstance', data )

        }catch( e ) {
            console.log(e)
        }

        if ( options.localOnly === undefined || options.localOnly !== true ){
            try {

                await axios
                    .put( process.env.apiUrl + `/sign-offs`,data,{
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function( response ){
                        commit('addToCollection', response.data.data)
                        commit('setInstance', response.data.data )

                        idbs.saveToOfflineStorage('sign_offs', [response.data.data])

                        return response.data.data
                    })
                    .catch(function(error){
                        return error
                    })
            }catch ( e ){
                throw e
            }finally {
                commit('setBusy', false)
            }

        }else{
            return data
        }

    },
    async destroy( { commit, rootState }, data ){

        commit('setBusy', true)

        try {
            commit('setBusy', true)
            await axios
                .delete( process.env.apiUrl +`/sign-offs/${data.uuid}`,{
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then(function( response ){
                    commit('removeFromCollection', response.data.data)
                    return response.data.data
                })
                .catch(function(error){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setBusy', false)
        }
    },
    updateInstanceFromOffline( { commit, rootState }, data ) {

        commit('setBusy', true)

        return new Promise( ( resolve, reject ) => {

            try {

                idbs.getAllFromOffline('sign_offs', "evaluation_uuid", data.evaluation).then( ( response ) => {

                    const offlineOnlyCollection = [...response.filter(model => (model.offline_only === true))]

                    if ( offlineOnlyCollection.length > 0 ){

                        const offlineSignOff = offlineOnlyCollection.shift()

                        if ( offlineSignOff.cross_signature_asset !== undefined && offlineSignOff.cross_signature_asset.length > 0 ){

                            axios.put( process.env.apiUrl + `/sign-offs`,offlineSignOff,{
                                headers: {
                                    Authorization: 'Bearer ' + rootState.auth.authToken,
                                    Accept: 'application/json'
                                }
                            })
                                .then(function( response ){

                                    if ( response.data !== undefined && "data" in response.data ){
                                        commit('updateInCollection', response.data.data)
                                        commit('setInstance', response.data.data )

                                        resolve( response.data.data )
                                    }

                                })
                                .catch(function(error){
                                    reject( error )
                                })

                        }else{
                            resolve({ message: "No valid assets to sync"})
                        }

                    }else{
                        resolve({ message: "No offline only models to sync"})
                    }

                }).finally(()=> {
                    commit('setBusy', false)
                })

            }catch( error ){
                commit('setBusy', false)
                reject( error )
            }
        })



    },
}
