import Vue from 'vue'
import * as moment from 'moment'
import axios from '../middleware/axios'
import idbs from "../api/offline/indexedDbService";

import uuidService from "../api/offline/uuidService";

export const state = () => ({
    loading: false,
    busy: false,
    collection: null,
    instance: null,
    collectionMeta: null,
})

export const getters = {

    getCollection: (state) => {

        if (state.collection === null || state.collection.length === 0) {
            return []
        }

       return [...state.collection].filter( contact => ( contact.trashed !== true ) ).sort( function(a,b) {
            if(a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
            if(a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
            return 0;
        })

    },
    getIsLoading: (state) => {
        return state.loading
    },

}

export const mutations = {

    setLoading( state, isLoading ){
        state.loading = isLoading === true
    },
    setBusy( state, isBusy )
    {
        state.busy = isBusy === true
    },
    setCollection: ( state, data ) => {
        state.collection = data
    },

    setCollectionMeta: ( state, data ) => {
        state.collectionMeta = data
    },

    addToCollection: ( state, model ) => {
        if ( state.collection === null )
        {
            state.collection = []
        }

        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            state.collection.push(model)
        }else{
            Vue.set(state.collection, index, model )
        }

    },

    setInstance: ( state, model ) => {
        return ( state.instance = model )
    },

    upsertInCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }

        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            state.collection.push(model)
        }else{
            // state.collection[index] = model
            Vue.set(state.collection, index, model )
        }

    },

    updateInCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }

        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            return
        }

        Vue.set(state.collection, index, model )

    },

    removeFromCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)
        if ( index === -1 ){
            return
        }
        state.collection.splice(index,1)
    }
}

export const actions = {

    clearStore( {commit} ){
        commit("setInstance", null)
        commit("setCollection", null)
        commit("setCollectionMeta", null)
    },

    async collection( { commit, rootState }, filters={}) {

        commit('setLoading', true)
        commit('setCollectionMeta', null)

        let offlineCollection = null

        try {

            await idbs.getAllFromOffline('contacts','model_uuid', filters.evaluation ).then( (response) => {

                commit('setCollection', response )

                offlineCollection = response

                commit('setLoading', false)
            })

        }catch( error )
        {

        }

        try {

            await axios
                .get( process.env.apiUrl + '/contacts',{
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then( function( response ) {

                    const updatedOfflineCollection = []

                    if ('meta' in response.data ){
                        commit('setCollectionMeta', response.data.meta )
                    }

                    for ( const model of response.data.data ){

                        if ( offlineCollection !== null )
                        {
                            const index = offlineCollection.findIndex((entry) => entry.uuid === model.uuid)

                            if ( index === -1 )
                            {
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }else if ( moment(offlineCollection[index].updated_at ).isBefore( model.updated_at ) )
                            {
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }else{
                                commit('addToCollection',offlineCollection[index] )
                            }

                        }else{
                            commit('addToCollection', model )
                            updatedOfflineCollection.push( model )
                        }

                    }

                    if ( updatedOfflineCollection.length > 0 ){
                        idbs.saveToOfflineStorage('contacts', updatedOfflineCollection )
                    }

                    return response.data
                })
                .catch(function( error ){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    async instance( { commit, rootState }, { id, filters }) {

        commit('setLoading', true)

        try {
            await idbs.getFromOfflineByPrimaryKey('contacts', id).then( (response) => {
                commit('setInstance', response )
                commit('setLoading', false)
            })
        }catch( error )
        {

        }

        try {

            await axios
                .get( process.env.apiUrl +`/contacts/${id}`, {
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                }).then(function( response ){
                    commit('setInstance', response.data.data )
                    idbs.saveToOfflineStorage('contacts', [response.data.data])
                    return response.data.data

                }).catch( function( error ){
                    return error
                })

        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    async store( { commit, rootState }, data ){

        commit('setBusy', true)

        try {

            data.offline_only = true
            data.uuid = uuidService.generate()

            if ( "evaluation" in data ){
                data.model_uuid = data.evaluation
            }

            await idbs.saveToOfflineStorage('contacts', [data]).then((response) => {
                commit('addToCollection', data )
                commit('setLoading', false)

                // console.log("saved offline OK")
                // console.log( response )
            })

        }catch( error ){
            console.log("offline save error")
            console.log( error )
        }

        if ( data.local_only !== true){
            try {

                await axios
                    .post( process.env.apiUrl +`/contacts`,data,{
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function( response ){
                        commit('addToCollection', response.data.data)
                        idbs.saveToOfflineStorage('contacts', [response.data.data])
                        return response.data.data
                    })
                    .catch(function(error){
                        return error
                    })

            }catch ( e ){
                throw e
            }finally {
                commit('setBusy', false)
            }
        }else{
            return data
        }

    },
    async update( { commit, rootState }, data ){

        commit('setBusy', true)

        data.offline_only = true

        try {
            await idbs.saveToOfflineStorage('contacts', data).then((response) => {
                commit('updateInCollection', response )
                commit('setBusy', false)
            })

        }catch( error ){

        }

        try {

            await axios
                .patch( process.env.apiUrl + `/contacts/${data.uuid}`,data,{
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then(function( response ){
                    commit('updateInCollection', response.data.data)
                    idbs.saveToOfflineStorage('contacts', [response.data.data])
                    return response.data.data
                })
                .catch(function(error){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setBusy', false)
        }
    },
    updateCollectionFromOffline( { commit, rootState }, data ) {

        commit('setBusy', true)

        return new Promise( ( resolve, reject ) => {

            try {

                idbs.getAllFromOffline('contacts','model_uuid', data.evaluation ).then( (response) => {

                    const offlineOnlyCollection = [...response.filter(model => (model.offline_only === true))]

                    if ( offlineOnlyCollection.length > 0 ){

                        axios.put( process.env.apiUrl + `/contacts/batch-upsert`,{ contacts: offlineOnlyCollection },{
                            headers: {
                                Authorization: 'Bearer ' + rootState.auth.authToken,
                                Accept: 'application/json'
                            }
                        }).then(function( response ){

                                resolve( response.data )
                            })
                            .catch(function(error){
                                reject( error )
                            })

                    }else{
                        resolve({ message: "No offline only models to sync"})
                    }

                }).finally(()=> {
                    commit('setBusy', false)
                })

            }catch( error ){
                commit('setBusy', false)
                reject( error )
            }
        })

    },
    async destroy( { commit, rootState }, data ){

        commit('setBusy', true)

        if ( data.force ){

            try {
                idbs.deleteFromOfflineStore('contacts', data.uuid).then((response) => {

                    commit('removeFromCollection', data )
                    commit('setBusy', false)

                }).catch( (error) => {
                    console.log("internal offline removal error " + error )
                })

            }catch( error ){
                console.log("offline removal error " + error )
            }

        }else{

            data.trashed = true
            data.updated_at = moment().format("YYYY-MM-DD HH:mm:ss")
            data.offline_only = true

            idbs.saveToOfflineStorage('contacts', [data]).then((response) => {
                commit('removeFromCollection', data )
                commit('setBusy', false)
            })

        }

        if ( data.local_only !== true ){
            try {

                await axios
                    .delete( process.env.apiUrl +`/contacts/${data.uuid}`,{
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function( response ){
                        commit('removeFromCollection', response.data.data)
                        idbs.deleteFromOfflineStore("contacts", data.uuid )
                        return response.data.data
                    })
                    .catch(function(error){
                        return error
                    })
            }catch ( e ){
                throw e
            }finally {
                commit('setBusy', false)
            }

        }else{
            return data
        }

    }
}
