export const state = () => ({
  isLoading: false
})

export const getters = {
  getIsLoading: (state) => {
    return state.isLoading
  }
}

export const mutations = {
  setIsLoading: (state, loading) => {
    state.isLoading = loading === true
  }
}
