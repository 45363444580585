import * as axios from 'axios'
// import store from '~/store/auth';

// Add a request interceptor
/*
axios.interceptors.request.use(
  function(config) {
    console.log('request', config)

    // Do something before request is sent
    return config
  },
  function(error) {
    console.log('request', error)

    // Do something with request error
    return error
  }
)
*/
// Add a response interceptor
axios.interceptors.response.use(
  function(response) {
    // console.log('response', response)

    // Do something with response data
    return response
  },
  function(error) {

      if ( error.response !== undefined && "status" in error.response ){

          switch (error.response.status) {
              case 401:
                  // console.log("401 unauthenticated recv'd")
                  // store.dispatch("setAsUnauthenticated")
                  break
              case 422:
                  // console.log("422 validation error detected")
                  break;
          }
      }

    // Do something with response error
    throw error
  }
)


export default axios
