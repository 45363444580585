import jwt from 'jwt-simple'
import idbs from "../api/offline/indexedDbService"
import axios from '../middleware/axios'

const cookieKey = 'havio_' + process.env.NODE_ENV + '_user'
const jwtSecret = Buffer.from('fe1a1915a379f3be5394b64d14794932', 'hex')

export const state = () => ({
  loading: false,
  authenticated: false,
  authToken: '',
  userData: {},
  userInvite: null,
  avatar: null,
  loginError: null,
  authRoles: [],
  rolePermissions: null,
})

export const getters = {
  getAuthenticated: (state) => {
    return state.authenticated
  },
    getIsLoading: (state) => {
        return state.loading
    },
  getAuthToken: (state) => {
    return state.authToken
  },
    getUserInvite: (state) => {
      return state.userInvite
    },
  getUserData(state) {
    return state.userData
  },
  getLoginError(state) {
    return state.loginError
  },
  getUserUuid(state) {
    return state.userData.uuid
  },
  getUserName(state) {
    return state.userData.given_name
  },
  getFullName(state) {
    return state.userData.name
  },
  getUserImage(state) {
    if ( state.avatar === null || state.avatar === undefined )
    {
      return 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp'
    }

    if ( state.avatar.base_64 && state.avatar.base_64.length > 0 ){
      return state.avatar.base_64
    }else if ( state.avatar.public_url && state.avatar.public_url.length > 0)
    {
      return state.avatar.public_url
    }

    return 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=identicon'
  },
  getAuthRoles(state) {
    return state.authRoles
  },
    getRolePermissions( state ){

      if ( state.rolePermissions === null ){
          return []
      }

      return state.rolePermissions.reduce(function(collection,model){
          collection.push(model.title)
          return collection
      },[])
    },
    getUserDataInitiatorUserId(state) {
      return state.userData.initiatorUserId
    },
}

export const mutations = {

    setLoading( state, isLoading ){
        state.loading = isLoading === true
    },
    setAuthenticated: (state, data) => {
    // console.log("set authenticated")
    state.authenticated = data
  },
  setAuthToken: (state, data) => {
    // console.log("set authToken: " + data )
    state.authToken = data
  },
  setUserData(state, data) {
    state.userData = data
  },
  setAvatar( state, data ) {
    state.avatar = data
  },
  setLoginError(state, data) {
    state.loginError = data
  },
  setAuthRoles(state, data) {
    state.authRoles = [...data]
  },
    setUserInviteInstance: ( state, model ) => {
        return ( state.userInvite = model )
    },
    setRolePermissions( state, data ){
      state.rolePermissions = data
    },
    setUserDataInitiatorUserId(state, data) {
      state.userData.initiatorUserId = data
    }
}

export const actions = {

  loadTokenFromLocal({ commit, dispatch, state }, cookieKey ) {

    const encodedUser = this.$cookies.get( cookieKey )

      if (encodedUser) {

          const jwtDecode = jwt.decode(encodedUser, jwtSecret)
          const decodedUser = JSON.parse(jwtDecode)

          const now = new Date()
          const refreshDate = new Date(decodedUser.login_expires_at)

          if (
              refreshDate >= now &&
                decodedUser.token.length > 0
          ) {

              // set authenticated
              commit('setAuthToken', decodedUser.token)
              // commit('setAuthToken', '1234') // for 401 redirect
              commit('setAuthenticated', true)
              commit('setUserData', decodedUser)

              if ("avatar" in decodedUser ){
                  commit("setAvatar", decodedUser.avatar.data )
              }

              // if ( "rolePermissions" in decodedUser ){
              //     commit("setRolePermissions", decodedUser.rolePermissions.data )
              // }

              dispatch('navigation/rebuildNav', decodedUser.navLabel, { root: true })

              // if is index
              // redirect to designated page
              // console.log("current route name:... " + this.$router.currentRoute.name )

              if (this.$router.currentRoute.name === 'index') {
                  // this.$router.push({ path: '/inspections' })
                  // check user redirect link
                  const REDIRECT_LINK_CONST = this.$router.resolve({
                      path: state.userData.after_login_redirect
                  })

                  if (
                      REDIRECT_LINK_CONST !== undefined &&
                      Object.keys(REDIRECT_LINK_CONST).length > 0 &&
                      REDIRECT_LINK_CONST.hasOwnProperty('href') &&
                      REDIRECT_LINK_CONST.href !== undefined
                  ) {
                      // console.log("redirect to here:..." +  REDIRECT_LINK_CONST.href )
                      // redirect to the link if its valid
                      this.$router.push({ path: REDIRECT_LINK_CONST.href })
                  } else {
                      // else redirect to inspections
                      this.$router.push({ path: '/inspections' })
                  }
              }
          } else {

              // need to unset everything
              // delete cookie backup
              this.$cookies.remove(cookieKey)
              idbs.deleteFromOfflineStore("secrets", cookieKey )

              // set loading state
              commit('setIsLoading', true, {
                  root: true
              })

              // if is not index
              // redirect to home/login if not home/login
              if (this.$router.currentRoute.name !== 'index') {
                  this.$router.push({ path: '/' })
              }

              // unset loading state
              commit('setIsLoading', false, {
                  root: true
              })
          }
      }


  },
  refreshUserDataFromOffline({commit, state}){

      if ( ! process.server ){

          try {

              idbs.getFromOfflineByPrimaryKey("secrets", cookieKey ).then( (response) => {

                  if ( response !== undefined )
                  {
                      if ("avatar" in response ){
                          commit("setAvatar", response.avatar.data)
                      }

                      if ("rolePermissions" in response ){
                          commit("setRolePermissions", response.rolePermissions.data)
                      }

                      if ("initiatorUserId" in response ){
                        commit("setUserDataInitiatorUserId", response.initiatorUserId)
                      }
                  }

              })

          }catch( error ){
              console.log("getting userData from offline error " + error )
          }
      }
  },
  saveTokenLocally({ state }) {

      const userData = {
          uuid: state.userData.uuid,
          given_name : state.userData.given_name,
          name: state.userData.name,
          login_expires_at: state.userData.login_expires_at,
          token: state.authToken,
          after_login_redirect: state.userData.after_login_redirect
      }

      if ( state.userData.mainNavItems.data[0].label ){
          userData.navLabel = state.userData.mainNavItems.data[0].label
      }else{
          userData.navLabel = null
      }

    // parse json
    const jsonEncoded = JSON.stringify( userData )

    // encode with jwt
    const jwtEncoded = jwt.encode(jsonEncoded, jwtSecret)

    // push to cookie
      this.$cookies.set(cookieKey, jwtEncoded, {
        path: "/",
        maxAge: 86400,
        secure: false,
        overwrite: true
      })

    idbs.saveToOfflineStorage("secrets",[{...{key: cookieKey}, ...state.userData}])
  },
  login({ commit, dispatch }, credentials) {
    // set loading state
    // commit('setIsLoading', true, {
    //   root: true
    // })

    // set login error message null
    // commit('setLoginError', null)

    return new Promise( ( resolve, reject ) => {
      axios
          .post(process.env.apiUrl + '/login', credentials)
          .then((response) => {
            // success login
            if (
                response.data.data.hasOwnProperty('mainNavItems') &&
                response.data.data.mainNavItems.hasOwnProperty('data') &&
                response.data.data.mainNavItems.data.length > 0 &&
                response.data.data.mainNavItems.data[0].hasOwnProperty('label') &&
                response.data.data.mainNavItems.data[0].label.length > 0 &&
                (response.data.data.hasOwnProperty('accessTokens') &&
                    response.data.data.accessTokens.hasOwnProperty('data') &&
                    response.data.data.accessTokens.data.length > 0 &&
                    response.data.data.accessTokens.data[0].hasOwnProperty('token') &&
                    response.data.data.accessTokens.data[0].token.length > 0)
            ) {
              commit('setAuthToken', response.data.data.accessTokens.data[0].token)
              commit('setAuthenticated', true)
              commit('setUserData', response.data.data)

              if ("avatar" in response.data.data ){
                // console.log("avatar in server response")
                commit("setAvatar", response.data.data.avatar.data )
              }

            if ( "rolePermissions" in response.data.data ){
                commit("setRolePermissions", response.data.data.rolePermissions.data )
            }

              dispatch('saveTokenLocally')
              dispatch('navigation/rebuildNav', response.data.data.mainNavItems.data[0].label, { root: true })
            } else {
              dispatch('logout')
            }

            resolve( response )
          })
          .catch((error) => {
            // console.log(error)
            // commit('setLoginError', error.response.data.errors)

            if ( error.response.data !== undefined ){
              reject( error.response.data )
            }else{
              reject( error )
            }

          })
          .finally(() => {
            // unset loading state
            // commit('setIsLoading', false, {
            //   root: true
            // })
          })

    })

  },
  loginAs({ commit, dispatch, rootState }, id) {
    // set loading state
    // commit('setIsLoading', true, {
    //   root: true
    // })

    // set login error message null
    // commit('setLoginError', null)

    return new Promise( ( resolve, reject ) => {
      axios
          .post(process.env.apiUrl + '/login-as/' + id, null, {
              headers: {
                  Authorization: 'Bearer ' + rootState.auth.authToken
              }
          })
          .then((response) => {
            // success login
            if (
                response.data.data.hasOwnProperty('mainNavItems') &&
                response.data.data.mainNavItems.hasOwnProperty('data') &&
                response.data.data.mainNavItems.data.length > 0 &&
                response.data.data.mainNavItems.data[0].hasOwnProperty('label') &&
                response.data.data.mainNavItems.data[0].label.length > 0 &&
                (response.data.data.hasOwnProperty('accessTokens') &&
                    response.data.data.accessTokens.hasOwnProperty('data') &&
                    response.data.data.accessTokens.data.length > 0 &&
                    response.data.data.accessTokens.data[0].hasOwnProperty('token') &&
                    response.data.data.accessTokens.data[0].token.length > 0)
            ) {
              commit('setAuthToken', response.data.data.accessTokens.data[0].token)
              commit('setAuthenticated', true)
              commit('setUserData', response.data.data)

              if ("avatar" in response.data.data ){
                // console.log("avatar in server response")
                commit("setAvatar", response.data.data.avatar.data )
              }

            if ( "rolePermissions" in response.data.data ){
                commit("setRolePermissions", response.data.data.rolePermissions.data )
            }

              dispatch('saveTokenLocally')
              dispatch('navigation/rebuildNav', response.data.data.mainNavItems.data[0].label, { root: true })
            } else {
              dispatch('logout')
            }

            resolve( response )
          })
          .catch((error) => {
            // console.log(error)
            // commit('setLoginError', error.response.data.errors)

            if ( error.response.data !== undefined ){
              reject( error.response.data )
            }else{
              reject( error )
            }

          })
          .finally(() => {
            // unset loading state
            // commit('setIsLoading', false, {
            //   root: true
            // })
          })

    })

  },
  forgotPassword({ commit, dispatch }, credentials){

    return new Promise( ( resolve, reject ) => {
      axios
          .post(process.env.apiUrl + '/forgot-password', credentials)
          .then((response) => {
            resolve( response )
          })
          .catch((error) => {

            if ( error.response.data !== undefined ){
              reject( error.response.data )
            }else{
              reject( error )
            }

          })

    })
  },
  resetPassword({ commit, dispatch }, data){

    return new Promise( ( resolve, reject ) => {
      axios
          .post(process.env.apiUrl + '/password-reset', data)
          .then((response) => {
            resolve( response )
          })
          .catch((error) => {

            if ( error.response.data !== undefined ){
              reject( error.response.data )
            }else{
              reject( error )
            }

          })

    })
  },
    resetPasswordFromInvite({ commit, dispatch }, data){

        return new Promise( ( resolve, reject ) => {
            axios
                .post(process.env.apiUrl + '/password-reset-from-invite', data)
                .then((response) => {
                    resolve( response )
                })
                .catch((error) => {

                    if ( error.response.data !== undefined ){
                        reject( error.response.data )
                    }else{
                        reject( error )
                    }

                })

        })
    },
    userInviteInstance({ commit, dispatch }, { id, filters }){

        return new Promise( ( resolve, reject ) => {
            commit('setLoading', true)
            axios
                .get(process.env.apiUrl + `/user-invites/${id}`, {
                    params: filters,
                    headers: {
                        Accept: 'application/json'
                    }
                })
                .then((response) => {
                    commit('setUserInviteInstance', response.data.data )
                    resolve( response.data.data )
                })
                .catch((error) => {

                    if ( error.response.data !== undefined ){
                        reject( error.response.data )
                    }else{
                        reject( error )
                    }

                }).finally(()=>{
                commit('setLoading', false )
            })

        })
    },
    upsertUserInvite({ commit, rootState }, data){

        return new Promise( ( resolve, reject ) => {
            axios
                .put( process.env.apiUrl + '/user-invites', data,{
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then((response) => {
                    resolve( response )
                })
                .catch((error) => {

                    if ( error.response.data !== undefined ){
                        reject( error.response.data )
                    }else{
                        reject( error )
                    }

                })

        })
    },
  async logout({ commit, rootState }) {
    // set loading state
    commit('setIsLoading', true, {
      root: true
    })

    // remove cookies
    this.$cookies.remove(cookieKey)
    idbs.deleteFromOfflineStore("secrets", cookieKey )

    await axios
      .post(process.env.apiUrl + '/logout', null, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then(() => {
        // success logout
        commit('setAuthToken', '')
        commit('setAuthenticated', false)
        commit('setUserData', {})
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        this.$router.push({ path: '/' })

        setTimeout(() => {
          // unset loading state
          commit('setIsLoading', false, {
            root: true
          })
        }, 500)
      })
  },
  setAsUnauthenticated({ commit }){
    // console.log("set as authenticatd dispatchd")
    commit('setAuthToken', '')
    commit('setAuthenticated', false)
    commit('setUserData', {})
    commit('setAvatar', null)
  },
  async getAuthRoles({ commit, rootState }) {
    await axios
      .get(process.env.apiUrl + '/auth-roles', {
        params: {
          pageSize: 500
        },
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setAuthRoles', response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  },
  loginAsNew({ commit, dispatch, rootState }, data) {
    // set loading state
    // commit('setIsLoading', true, {
    //   root: true
    // })

    // set login error message null
    // commit('setLoginError', null)

    return new Promise( ( resolve, reject ) => {
      axios
          .post(process.env.apiUrl + '/login-as/' + data.uuid, null, {
              headers: {
                  Authorization: 'Bearer ' + rootState.auth.authToken
              }
          })
          .then((response) => {
            // success login
            if (
                response.data.data.hasOwnProperty('mainNavItems') &&
                response.data.data.mainNavItems.hasOwnProperty('data') &&
                response.data.data.mainNavItems.data.length > 0 &&
                response.data.data.mainNavItems.data[0].hasOwnProperty('label') &&
                response.data.data.mainNavItems.data[0].label.length > 0 &&
                (response.data.data.hasOwnProperty('accessTokens') &&
                    response.data.data.accessTokens.hasOwnProperty('data') &&
                    response.data.data.accessTokens.data.length > 0 &&
                    response.data.data.accessTokens.data[0].hasOwnProperty('token') &&
                    response.data.data.accessTokens.data[0].token.length > 0)
            ) {
              commit('setAuthToken', response.data.data.accessTokens.data[0].token)
              commit('setAuthenticated', true)
              commit('setUserData', response.data.data)
              commit('setUserDataInitiatorUserId', data.initiatorUserId)
              

              if ("avatar" in response.data.data ){
                // console.log("avatar in server response")
                commit("setAvatar", response.data.data.avatar.data )
              }

            if ( "rolePermissions" in response.data.data ){
                commit("setRolePermissions", response.data.data.rolePermissions.data )
            }

              dispatch('saveTokenLocally')
              dispatch('navigation/rebuildNav', response.data.data.mainNavItems.data[0].label, { root: true })
            } else {
              dispatch('logout')
            }

            resolve( response )
          })
          .catch((error) => {
            // console.log(error)
            // commit('setLoginError', error.response.data.errors)

            if ( error.response.data !== undefined ){
              reject( error.response.data )
            }else{
              reject( error )
            }

          })
          .finally(() => {
            // unset loading state
            // commit('setIsLoading', false, {
            //   root: true
            // })
          })

    })

  },
}
