import Vue from 'vue'
import * as moment from 'moment'
import axios from '../middleware/axios'

import idbs from "../api/offline/indexedDbService";

import uuidService from "../api/offline/uuidService";


export const state = () => ({
    loading: false,
    busy: false,
    collection: null,
    filteredCollection: null,
    instance: null,
    collectionMeta: null,
    isBeingCloned: false
})

export const getters = {

    getCollection: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }

        //  console.log("answers/getCollection - state.collection", JSON.stringify(state.collection))

        return [...state.collection].filter( model => ( model.trashed !== true ) )
    },
    pluckCollection: ( state ) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }
        return [...state.collection].reduce(function(collection,model){
            if ( model.trashed !== true ){
                collection[model.uuid] = model
            }
            return collection
        },{})
    },
    getTotalIncomplete: ( state ) => {
        if (state.collection === null || state.collection.length === 0) {
            return 0
        }
        return [...state.collection].filter( model => (model.is_incomplete === true && model.trashed !== true )).length // && model.evaluation_prompt_rating === model.rating
    },
    getCollectionWhereNegative: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return []
        }
        return [...state.collection].filter( model => ( ["non-compliant","no","negative"].includes( model.rating ) && model.is_escalated === false && model.trashed !== true )) // && model.evaluation_prompt_rating === model.rating
    },
    getCollectionWherePositive: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return []
        }
        return [...state.collection].filter( model => ( ["compliant","yes","positive"].includes( model.rating ) && model.trashed !== true )) // && model.evaluation_prompt_rating === model.rating
    },

    getCollectionWhereEscalated: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return []
        }
        return [...state.collection].filter( model => ( ["non-compliant","no","negative"].includes( model.rating ) && model.is_escalated === true && model.trashed !== true)) // && model.evaluation_prompt_rating === model.rating

    },
    getCollectionWhereNegativeForEvaluation: (state) => (evaluation) => {
        if (state.collection === null || state.collection.length === 0) {
            return []
        }
        return [...state.collection].filter( model => ( ["non-compliant","no","negative"].includes( model.rating ) && model.is_escalated === false && model.trashed !== true  && model.evaluation_uuid === evaluation)) // && model.evaluation_prompt_rating === model.rating
    },
    getCollectionWherePositiveForEvaluation: (state) =>  (evaluation) => {
        if (state.collection === null || state.collection.length === 0) {
            return []
        }
        return [...state.collection].filter( model => ( ["compliant","yes","positive"].includes( model.rating ) && model.trashed !== true  && model.evaluation_uuid === evaluation)) // && model.evaluation_prompt_rating === model.rating
    },

    getCollectionWhereEscalatedForEvaluation: (state) => (evaluation) => {
        if (state.collection === null || state.collection.length === 0) {
            return []
        }
        return [...state.collection].filter( model => ( ["non-compliant","no","negative"].includes( model.rating ) && model.is_escalated === true && model.trashed !== true && model.evaluation_uuid === evaluation)) // && model.evaluation_prompt_rating === model.rating

    },
    
}

export const mutations = {

    setLoading( state, isLoading ){
        state.loading = isLoading === true
    },
    setBusy( state, isBusy )
    {
        state.busy = isBusy === true
    },
    setCollection: ( state, data ) => {
        state.collection = data
    },

    setCollectionMeta: ( state, data ) => {
        state.collectionMeta = data
    },
    setFilteredCollection: ( state, data ) => {
        state.filteredCollection = data
    },
    addToCollection: ( state, model ) => {

        if ( state.collection === null )
        {
            state.collection = []
        }

        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){

            state.collection.push(model)
        }else{

            Vue.set(state.collection, index, model )
        }

    },

    setInstance: ( state, model ) => {
        return ( state.instance = model )
    },
    clearInstance: ( state ) => {
        state.instance = null
    },
    setCollectionFilteredByPromptUuid: ( state, { evaluationPromptUuid, rating }) => {
        state.filteredCollection = []

        if (state.collection === null || state.collection.length === 0) {
            return
        }

        for ( let i =0; i < state.collection.length; i++ ) {

            if ( state.collection[i].evaluation_prompt_uuid === evaluationPromptUuid && state.collection[i].rating === rating && state.collection[i].trashed !== true)
            {
                state.filteredCollection.push( state.collection[i] )
            }
        }

    },
    reconcileModelAndLocalData: ( state, { model, localData }) => {

        if ( localData !== undefined )
        {
            if ( localData.observation ) {
                model.description = localData.observation
            }

            if ( localData.justification ) {
                model.justification = localData.justification
            }

            if ( localData.grade ) {
                model.score = localData.grade
                model.is_escalated = ( localData.grade > 5 )
            }else{
                model.is_escalated = false
            }

            if ( localData.relatingToCurrentValue ) {
                model.contact_uuid = localData.relatingToCurrentValue
            }
        }

        state.instance = model
    },
    updateInCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }

        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            return
        }

        Vue.set(state.collection, index, model )

        // also update any filtered collection

        if ( state.filteredCollection && state.filteredCollection.length > 0 )
        {
            const index = state.filteredCollection.findIndex((entry) => entry.uuid === model.uuid)
            
            if ( index !== -1 ){

                Vue.set(state.filteredCollection, index, model )
                
            }
        }
    },
    updateCollectionPromptRating( state, { evaluationPromptUuid, evaluationPromptRating }){
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }
        for ( let index = 0; index < state.collection.length; index ++ ) {

            if ( state.collection[index].evaluation_prompt_uuid === evaluationPromptUuid ){

                Vue.set( state.collection[index],'evaluation_prompt_rating', evaluationPromptRating )
            }
        }

    },
    removeFromCollection: ( state, model ) =>
    {

        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)
        if ( index === -1 ){
            return
        }

        state.collection.splice(index,1)

        if ( state.filteredCollection && state.filteredCollection.length > 0 )
        {
            const index = state.filteredCollection.findIndex((entry) => entry.uuid === model.uuid)
            if ( index !== -1 ){
                state.filteredCollection.splice(index,1)
            }
        }
    },
    setCollectionFilteredByPromptUuidWithoutRating: ( state, { evaluationPromptUuid }) => {
        state.filteredCollection = []

        if (state.collection === null || state.collection.length === 0) {
            return
        }

        for ( let i =0; i < state.collection.length; i++ ) {

            if ( state.collection[i].evaluation_prompt_uuid === evaluationPromptUuid && state.collection[i].trashed !== true)
            {
                state.filteredCollection.push( state.collection[i] )
            }
        }


    },
    trashCollectionByCategoryUuid: ( state, {categoryUuid, trashedState }) => {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }
        for ( let i = 0; i < state.collection.length; i++ )
        {
            if ( state.collection[i].evaluation_category_uuid === categoryUuid ) {
                state.collection[i].trashed = trashedState
            }
        }
    },
}

export const actions = {

    clearStore( {commit} ){
        commit("setInstance", null)
        commit("setCollection", null)
        commit("setFilteredCollection", null)
        commit("setCollectionMeta", null)
    },

    setInstanceAndCollectionState({commit, state }, { model, localData} ){

        commit("reconcileModelAndLocalData", { model, localData })

        commit("addToCollection", state.instance )
        idbs.saveToOfflineStorage('answers', [state.instance] )

    },
    setInstanceFromCollectionByUuid( { commit, state },{uuid} ){

        if ( state.collection && state.collection.length > 0 )
        {
            const index = state.collection.findIndex((entry) => entry.uuid === uuid )

            if ( index !== -1 ){
                commit("setInstance", state.collection[index] )
            }
        }

    },
    clearInstance( { commit } ){
        commit("clearInstance")
    },
    clearFilteredCollection( {commit } ){
        commit("setFilteredCollection", null)
    },
    filterCollectionByEvaluationPrompt( {commit}, {evaluationPromptUuid, rating } )
    {

        if ( evaluationPromptUuid!== undefined && rating !== undefined ){

            commit("setCollectionFilteredByPromptUuid", {evaluationPromptUuid, rating })
        }

    },
    updatePromptRatingLocally({ commit}, { evaluationPromptUuid, evaluationPromptRating } ){

        commit("updateCollectionPromptRating", { evaluationPromptUuid, evaluationPromptRating })

    },
    async collection( { commit, rootState }, filters={}) {

        commit('setLoading', true)
        commit('setCollectionMeta', null)

        let offlineCollection = null

        try {

            await idbs.getAllFromOffline('answers','evaluation_uuid', filters.evaluation ).then( (response) => {

                offlineCollection = response

                commit('setCollection', response )
                commit('setLoading', false)
            })

        }catch( error )
        {

        }
        try {

            await axios
                .get( process.env.apiUrl + '/answers',{
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then( function( response ) {

                    const updatedOfflineCollection = []

                    if ('meta' in response.data ){
                        commit('setCollectionMeta', response.data.meta )
                    }

                    for ( const model of response.data.data ){

                        if ( offlineCollection !== null )
                        {
                            const index = offlineCollection.findIndex((entry) => entry.uuid === model.uuid)

                            if ( index === -1 )
                            {
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }                            
                            else if ( moment(offlineCollection[index].updated_at ).isBefore( model.updated_at ) )
                            {
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }else{

                                commit('addToCollection',offlineCollection[index] )

                                if (offlineCollection[index].offline_only === false && !offlineCollection[index].hasOwnProperty('copied_answer_id') )
                                {
                                    updatedOfflineCollection.push( model )
                                }

                                if ( offlineCollection[index].description !== model.description)
                                {
                                    updatedOfflineCollection.push( offlineCollection[index] )
                                }

                                if ( offlineCollection[index].trashed !== model.trashed)
                                {
                                    updatedOfflineCollection.push( offlineCollection[index] )
                                }

                                if (offlineCollection[index].offline_only === false && !offlineCollection[index].hasOwnProperty('evaluation_category_uuid') )
                                {
                                    updatedOfflineCollection.push( model )
                                }                                
                            }

                        }else{
                            commit('addToCollection', model )
                            updatedOfflineCollection.push( model )
                        }

                        if ( updatedOfflineCollection.length > 0 ){
                            idbs.saveToOfflineStorage('answers', updatedOfflineCollection )
                        }
                    }

                    return response.data
                })
                .catch(function( error ){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    async instance( { commit, rootState }, { id, filters }) {

        try {

            commit('setLoading', true)

            await axios
                .get( process.env.apiUrl +`/answers/${id}`, {
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                }).then(function( response ){
                    commit('setInstance', response.data.data )

                    return response.data.data

                }).catch( function( error ){
                    return error
                })

        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    generateUuid(){
        return uuidService.generate()
    },
    store( { commit, rootState, dispatch }, data ){

        // console.log("store new answer submitted")

        commit('setBusy', true)

        return new Promise( (resolve, reject ) => {

            try {

                if ( data.uuid === undefined ){
                    data.uuid = uuidService.generate()
                }

                data.offline_only = true

                if ( "evaluation" in data ){
                    data.evaluation_uuid = data.evaluation
                }

                if ( "prompt" in data ) {
                    data.evaluation_prompt_uuid = data.prompt
                }

                if ( "grade" in data ){
                    data.score = data.grade
                    if ( data.grade > 5 ) {
                        data.is_escalated = true
                    }else{
                        data.is_escalated = false
                    }
                }else{
                    data.is_escalated = false
                }

                // need to manually set the rating too

                if ( "contractor" in data && data.contractor.length > 0 )
                {
                    data.contact_uuid = data.contractor
                }

                idbs.saveToOfflineStorage('answers', [data]).then((response) => {

                    commit('addToCollection', data )
                    commit('setBusy', false)

                })

            }catch( error ){

                reject( error )
            }

            if ( data.local_only !== true ) {

                try {

                    axios
                        .post(process.env.apiUrl + `/answers`, data, {
                            headers: {
                                Authorization: 'Bearer ' + rootState.auth.authToken,
                                Accept: 'application/json'
                            }
                        })
                        .then(function (response) {

                            if (response.data !== undefined) {
                                commit('addToCollection', response.data.data)

                                idbs.saveToOfflineStorage('answers', [response.data.data])

                                resolve(response.data.data)
                            } else {

                                resolve(data)
                            }

                        })
                        .catch(function (error) {

                            console.log(error)

                            resolve(data) // return back our source data in the event of network issue
                            // reject( error )
                        })

                } catch (error) {
                    // console.log("reject here from answer/store")
                    reject(error)
                } finally {
                    commit('setBusy', false)
                }

            }else{
                commit('addToCollection', data )
                resolve(data)
            }
        })


    },
    updateInState( { commit}, { model, data }) {

        const mergedData = {...model, ...data}

        if ( "evaluation" in mergedData ){
            mergedData.evaluation_uuid = mergedData.evaluation
        }

        if ( "prompt" in mergedData ) {
            mergedData.evaluation_prompt_uuid = mergedData.prompt
        }

        if ( "grade" in mergedData ){
            mergedData.score = mergedData.grade
            if ( data.grade > 5 ) {
                mergedData.is_escalated = true
            }else{
                mergedData.is_escalated = false
            }
        }else{
            mergedData.is_escalated = false
        }

        // need to manually set the rating too

        if ( "contractor" in mergedData && mergedData.contractor.length > 0 )
        {
            mergedData.contact_uuid = mergedData.contractor
        }

        mergedData.offline_only = true

        try {

            idbs.saveToOfflineStorage('answers', [mergedData]).then((response) => {
                console.log("updateInState - response", JSON.stringify(mergedData))
                commit('updateInCollection', mergedData )
            });

        }catch( error ){
            console.log( error )
        }

    },
    update( { commit, rootState, dispatch }, data ){

        
        commit('setBusy', true)

        return new Promise( (resolve, reject ) => {
            try {

                data.offline_only = true
                data.updated_at = moment().format("YYYY-MM-DD HH:mm:ss")

                if ( !( "evaluation_uuid" in data) ){
                    data.evaluation_uuid = data.evaluation
                }

                if ( "grade" in data ){

                    data.score = data.grade

                    if ( data.grade > 5 ) {
                        data.is_escalated = true
                    }else{
                        data.is_escalated = false
                    }
                }else{
                    data.is_escalated = false
                }

                if ( "prompt" in data ) {
                    data.evaluation_prompt_uuid = data.prompt
                }

                if ( "contractor" in data && data.contractor.length > 0 )
                {
                    data.contact_uuid = data.contractor
                }

                idbs.getFromOfflineByPrimaryKey("answers", data.uuid ).then( function( offlineAnswer )
                {
                    idbs.updateOfflineStorage('answers', offlineAnswer, data).then((response) => {

                        commit('updateInCollection', data )
                        commit('setBusy', false)
                    })
                })

            }catch( error ){
                console.error("issue saving answer offline " + error )
            }

            if ( data.local_only !== true ) {

                try {
                    axios
                        .patch(process.env.apiUrl + `/answers/${data.uuid}`, data, {
                            headers: {
                                Authorization: 'Bearer ' + rootState.auth.authToken,
                                Accept: 'application/json'
                            }
                        })
                        .then(function (response) {
                            commit('updateInCollection', response.data.data)

                            idbs.saveToOfflineStorage('answers', [response.data.data])
                            // if ( 'actions' in response.data.data )
                            // {
                            //
                            //     dispatch('answerActions/updateCollectionState', response.data.data.actions.data , {root: true })
                            //     idbs.saveToOfflineStorage('actions',response.data.data.actions.data )
                            // }

                            resolve(response.data.data)
                        })
                        .catch(function (error) {
                            console.log(error)
                            resolve(data)
                        })

                } catch (e) {
                    reject(e)
                } finally {
                    commit('setBusy', false)
                }

            }else{
                resolve(data)
            }
        })

    },
    updateCollectionFromOffline( { commit, rootState }, data ) {

        commit('setBusy', true)

        return new Promise( ( resolve, reject ) => {

            try {

                idbs.getAllFromOffline('answers','evaluation_uuid', data.evaluation ).then( (response) => {

                    const offlineOnlyCollection = [...response.filter(model => (model.offline_only === true))]

                    if ( offlineOnlyCollection.length > 0 ){
                        axios.put( process.env.apiUrl + `/answers/batch-upsert`,{ evaluation: data.evaluation, answers: offlineOnlyCollection },{
                            headers: {
                                Authorization: 'Bearer ' + rootState.auth.authToken,
                                Accept: 'application/json'
                            }
                        })
                            .then(function( response ){

                                resolve( response.data )
                            })
                            .catch(function(error){
                                reject( error )
                            })
                    }else{
                        resolve({ message: "No offline only models to sync"})
                    }

                }).finally(()=> {
                    commit('setBusy', false)
                })

            }catch( error ){
                commit('setBusy', false)
                reject( error )
            }
        })

    },

    updateCollectionFromAnyOffline( { commit, rootState }, data ) {

        commit('setBusy', true)

        return new Promise( ( resolve, reject ) => {

            try {

                idbs.getAllFromOffline('answers').then( (response) => {

                    const offlineOnlyCollection = [...response.filter(model => (model.offline_only === true))]

                    if ( offlineOnlyCollection.length > 0 ){
                        axios.put( process.env.apiUrl + `/answers/batch-upsert`,{ answers: offlineOnlyCollection },{
                            headers: {
                                Authorization: 'Bearer ' + rootState.auth.authToken,
                                Accept: 'application/json'
                            }
                        })
                            .then(function( response ){

                                resolve( response.data )
                            })
                            .catch(function(error){
                                reject( error )
                            })
                    }else{
                        resolve({ message: "No offline only models to sync"})
                    }

                }).finally(()=> {
                    commit('setBusy', false)
                })

            }catch( error ){
                commit('setBusy', false)
                reject( error )
            }
        })

    },
    async destroy( { commit, rootState }, data ){
        commit('setBusy', true)

        if ( data.force ){

            try {
                
                idbs.deleteFromOfflineStore('answers', data.uuid).then((response) => {

                    commit('removeFromCollection', data )
                    commit('setBusy', false)

                }).catch( (error) => {
                    console.log("internal offline removal error " + error )
                })

            }catch( error ){
                console.log("offline removal error " + error )
            }

        }else{

            idbs.getFromOfflineByPrimaryKey('answers', data.uuid).then((response) => {

                data.trashed = true
                data.offline_only = true
                data.updated_at = moment().format("YYYY-MM-DD HH:mm:ss")

                data = {...response, ...data }

                idbs.saveToOfflineStorage('answers', [data]).then((response) => {
                    commit('removeFromCollection', data )
                    commit('setBusy', false)
                })
            })

        }

        if ( data.local_only !== true ) {
            try {

                await axios
                    .delete(process.env.apiUrl + `/answers/${data.uuid}`, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function (response) {

                        commit('removeFromCollection', response.data.data)
                        idbs.deleteFromOfflineStore("answers", data.uuid)
                        return response.data.data
                    })
                    .catch(function (error) {
                        return error
                    })
            } catch (e) {
                throw e
            } finally {
                commit('setBusy', false)
            }

        }else{
            return data
        }
    },
    batchDestroy( { commit}, data ){
        commit('setBusy', true)

        if ( data.evaluation_prompt_uuid )
        {
            // get all answers by prompt_uuid
            idbs.getAllFromOffline('answers','evaluation_prompt_uuid', data.evaluation_prompt_uuid).then((response) => {
                const offlineOnlyCollection = [...response.filter(model => (model.rating !== data.rating && (model.trashed === undefined || !model.trashed)))]

                if ( offlineOnlyCollection.length > 0 )
                {

                    offlineOnlyCollection.forEach( ( answerToDestroy ) => {

                        answerToDestroy.trashed = true
                        answerToDestroy.offline_only = true
                        answerToDestroy.updated_at = moment().format("YYYY-MM-DD HH:mm:ss")
                        answerToDestroy.archived_at = moment().format("YYYY-MM-DD HH:mm:ss")
                        commit('removeFromCollection', answerToDestroy )
                    })

                    idbs.saveToOfflineStorage('answers',offlineOnlyCollection )

                }

            })
        }
    },

    restoreArchived( { commit}, data ){
        commit('setBusy', true)

        if ( data.evaluation_prompt_uuid )
        {
            // get all answers by prompt_uuid
            idbs.getAllFromOffline('answers','evaluation_prompt_uuid', data.evaluation_prompt_uuid).then((response) => {
                const archivedOnlyCollection = [...response.filter(model => (model.rating === data.rating && (model.archived_at === undefined || model.archived_at !== null) ))]

                if ( archivedOnlyCollection.length > 0 )
                {

                    archivedOnlyCollection.forEach( ( answerToRestore ) => {

                        answerToRestore.trashed = false
                        answerToRestore.offline_only = true
                        answerToRestore.updated_at = moment().format("YYYY-MM-DD HH:mm:ss")
                        answerToRestore.archived_at = null
                        commit('addToCollection', answerToRestore )
                    })

                    idbs.saveToOfflineStorage('answers',archivedOnlyCollection )

                }

            })
        }
    },
    async collectionByEvaluationPrompt( { commit, rootState }, filters={}) {

        commit('setLoading', true)
        commit('setCollectionMeta', null)
          
        filters.offline_only = true
        // const offlineCollection = null
         let offlineCollection = []

        try {

             await idbs.getAllFromOffline('answers','evaluation_prompt_uuid', filters.prompt ).then( (response) => {

                offlineCollection = response

               // console.log("collectionByEvaluationPrompt - idbs.getAllFromOffline - response", JSON.stringify(response))
                commit('setCollection', response )
                commit('setLoading', false)
            }) 

        }catch( error )
        {

        }
        if ( filters.local_only !== true ){

        try {

            await axios
                .get( process.env.apiUrl + '/answers',{
                    params: {
                        prompt : filters.prompt,
                        include: ['actions']
                    },
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then( function( response ) {

                    //  console.log("collectionByEvaluationPrompt - online - response", JSON.stringify(response))

                    const updatedOfflineCollection = []

                    if ('meta' in response.data ){
                        commit('setCollectionMeta', response.data.meta )
                    }
                    for ( const model of response.data.data ){
                        
                        if ( offlineCollection !== null )
                        {
                            const index = offlineCollection.findIndex((entry) => entry.uuid === model.uuid)

                            if ( index === -1 )
                            {
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }else if ( moment(offlineCollection[index].updated_at ).isBefore( model.updated_at ) )
                            {
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }else{

                                commit('addToCollection',offlineCollection[index] )
                            }

                        }else{
                            commit('addToCollection', model )
                            updatedOfflineCollection.push( model )
                        }

                        if ( updatedOfflineCollection.length > 0 ){
                            idbs.saveToOfflineStorage('answers', updatedOfflineCollection )
                        }
                    }

                    return response.data
                })
                .catch(function( error ){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }
    }
    else{
        return filters
    }
    },
    filterCollectionByEvaluationPromptWithoutRating( {commit}, {evaluationPromptUuid } )
    {

        if ( evaluationPromptUuid!== undefined ){

            commit("setCollectionFilteredByPromptUuidWithoutRating", {evaluationPromptUuid })
        }

    },
    trashCollectionByCategoryUuid( {commit}, {categoryUuid, evaluationUuid, trashedState } )
    {
        commit("trashCollectionByCategoryUuid", { categoryUuid, trashedState } )
        // we also need to handle storing these changes offline
        try {
            idbs.getAllFromOffline('answers','evaluation_uuid', evaluationUuid ).then( (response) => {
                if ( response !== undefined ) {
                    const trashedOffline = []
                    for ( let i=0; i < response.length; i++ )
                    {
                        if ( response[i].evaluation_category_uuid === categoryUuid ){
                            trashedOffline.push({ ...response[i], ...{ "trashed": trashedState, "offline_only": true }})
                        }
                    }
                    if ( trashedOffline.length > 0 ){
                        idbs.saveToOfflineStorage('answers', trashedOffline )
                    }
                }
            })
        }catch( error )
        {
        }
    }
}
