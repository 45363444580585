import Vue from 'vue'
import * as moment from 'moment'

Vue.mixin({
  data() {
    return {
      currentPageTitle: this.$options.currentPageTitle ? this.$options.currentPageTitle : null
    }
  },
  computed: {
    currentSeoContent() {
      // base modalTitle from options data supplied in page
      const SEO_TITLE_STRING = this.$options.titleData ? this.$options.titleData : ''
      // base body class from options data supplied in page
      let bodyClassString = this.$options.bodyClassData ? this.$options.bodyClassData : ''
      // scroll plugin bollean from options data supplied in page
      // adds extra scroll plugin to page for scrolling icon nav
      const SCROLL_TO_PLUGIN_BOOLEAN = this.$options.scrollToPluginData ? this.$options.scrollToPluginData : false
      // blank let
      let seoDataLet

      // make main area bg white on certain pages
      if (
        this.$nuxt.$route.name === 'inspections' ||
        this.$nuxt.$route.name === 'new-inspection-id' ||
        this.$nuxt.$route.name === 'new-inspection-id-action-plan' ||
        this.$nuxt.$route.name === 'clients' ||
        this.$nuxt.$route.name === 'clients-id' ||
        this.$nuxt.$route.name === 'clients-id-sites' ||
        this.$nuxt.$route.name === 'clients-id-sites-siteId' ||
        this.$nuxt.$route.name === 'clients-id-sites-siteId-actions' ||
        this.$nuxt.$route.name === 'clients-id-sites-siteId-inspections' ||
        this.$nuxt.$route.name === 'clients-id-sites-siteId-notes' ||
        this.$nuxt.$route.name === 'actions' ||
        this.$nuxt.$route.name === 'sites' ||
        this.$nuxt.$route.name === 'sites-siteId' ||
        this.$nuxt.$route.name === 'sites-siteId-inspections' ||
        this.$nuxt.$route.name === 'sites-siteId-inspections-reportId' ||
        this.$nuxt.$route.name === 'sites-siteId-actions'
      ) {
        bodyClassString += ' p-body-white-bg'
      }

      // if modalTitle
      // add modalTitle to outputted object
      if (SEO_TITLE_STRING.length > 0) {
        seoDataLet = {
          title: SEO_TITLE_STRING,
          bodyAttrs: {
            class: bodyClassString
          }
        }
      } else {
        // else do not
        seoDataLet = {
          bodyAttrs: {
            class: bodyClassString
          }
        }
      }

      // add script scroll to plugin to center carousel control nav icons
      if (SCROLL_TO_PLUGIN_BOOLEAN && process.env.NODE_ENV === 'production') {
        seoDataLet.script = [
          {
            type: 'text/javascript',
            src: process.env.baseUrl + '/assets/js/gsap/ScrollToPlugin.min.js'
          }
        ]
      }

      return seoDataLet
    }
  },
  methods: {
    returnCurrentYear() {
      return moment().year()
    },
    returnFormattedDate(dateVal) {
      return moment(dateVal).format('DD/MM/YYYY')
    },
    returnShortFormFormattedDate(dateVal) {
      return moment(dateVal).format('DD/MM')
    },
    returnFormattedDateTime(dateVal) {
      return moment(dateVal).format('DD/MM HH:mm')
    },
    returnFormattedDateNew(dateVal) {
      return moment(dateVal).format('DD/MM/YY')
    },
    returnFormattedTimeNew(dateVal) {
      return moment(dateVal).format('HH:mm')
    }
  }
})
