import vue from 'vue'
import axios from '../../middleware/axios'
import idbs from "../../api/offline/indexedDbService";
import uuidService from "../../api/offline/uuidService";


export const state = () => ({
    isLoading: true,
    currentAnswers: [],
    rating: null,
    currentAnswer: {}
})

export const getters = {
    getIsLoading: (state) => {
        return state.isLoading
    },

    getCurrentAnswer: (state ) => {
        return state.currentAnswer
    },
    getCurrentAnswerAssets: ( state ) => {
      return ( typeof state.currentAnswer.assets !== "undefined" ) ? state.currentAnswer.assets.data : []
    },
    getAnswersByPromptUuidAndRating: state => ({uuid, rating}) => {

        // console.log('getAnswersByPromptUuidAndRating data: ' + uuid + ' ' + rating )

        console.log("current Answers type:")
        console.log( state.currentAnswers )

        return state.currentAnswers.filter( ( answer ) => {
            return ( answer.evaluation_prompt_uuid === uuid && answer.rating === rating )
        })

        // @todo add in a sort here to consistently sort
    }

}

export const mutations = {

    setIsLoading: (state, loading) => {
        state.isLoading = loading === true
    },
    setCurrentAnswers: (state, answers) => {
        state.currentAnswers = answers
    },
    setCurrentAnswerByUuid: ( state, answerUuid ) => {

        const answerIndex = state.currentAnswers.findIndex((i) => i.uuid === answerUuid )

        if ( answerIndex > -1 )
        {
            state.currentAnswer = state.currentAnswers[ answerIndex ]
        }
    },
    mergeUuidToCurrentAnswer: ( state, answerUuid ) => {

        state.currentAnswer.uuid = answerUuid

    },
    setAnswerInCurrentAnswers: ( state, newAnswer ) => {

        // console.log('setAnswerInCurrentAnswers: ' )
        // console.log( newAnswer )

        const existingAnswerIndex = state.currentAnswers.findIndex((i) => i.uuid === newAnswer.uuid )

        // console.log('index ' + existingAnswerIndex )

        if ( existingAnswerIndex > -1 )
        {
             vue.set( state.currentAnswers, existingAnswerIndex, newAnswer )
            // state.currentAnswers[existingAnswerIndex] = newAnswer
        }else{
            state.currentAnswers.push( newAnswer )
        }

    },
    removeAnswerFromCurrentAnswers: ( state, answerUuid ) => {

        const existingAnswerIndex = state.currentAnswers.findIndex((i) => i.uuid === answerUuid )

        // console.log("removeAnswer :" + answerUuid + " index: " + existingAnswerIndex )

        if ( existingAnswerIndex > -1 )
        {
            state.currentAnswers.splice( existingAnswerIndex, 1 )
        }
    },
    clearCurrentAnswer: (state ) => {

        state.currentAnswer = {}

    },
    addAssetToCurrentAnswer: ( state, asset ) => {

        if ( typeof state.currentAnswer.assets === 'undefined' )
        {
            // console.log("adding assets because missing....")

            state.currentAnswer.assets = {
                data : []
            }
        }

        const ASSET_INDEX = state.currentAnswer.assets.data.findIndex((i) => i.id === asset.id )

        if ( ASSET_INDEX > -1 )
        {
            // console.log("updating existing asset within the index")

            vue.set( state.currentAnswers.data, ASSET_INDEX, asset )
        }else{

            // console.log("adding a new asset into this stack")

            state.currentAnswer.total_assets += 1
            state.currentAnswer.assets.data.push( asset )
        }

    },
    removeAssetFromCurrentAnswer: ( state, asset ) => {

        const ASSET_INDEX = state.currentAnswer.assets.data.findIndex((i) => i.id === asset.id )

        if ( ASSET_INDEX > -1 )
        {
            state.currentAnswer.total_assets -= 1
            state.currentAnswer.assets.data.splice(ASSET_INDEX, 1)
        }

    },
    removeCurrentAnswersForEvaluation: ( state, evaluationUuid ) => {

        state.currentAnswers.forEach( (answer, index ) => {
            if ( answer.evaluation_uuid === evaluationUuid )
            {
                state.currentAnswers.splice( index, 1 )
            }
        })
    }
}

export const actions = {

    clearCurrentAnswer({commit} )
    {
        // console.log("clearCurrentAnswer triggered")

        commit("clearCurrentAnswer")
    },

    getAnswerByUuid({state,dispatch,commit}, answerUuid )
    {
        commit("setCurrentAnswerByUuid", answerUuid )
    },

    async getAnswersByEvaluation( { state, dispatch, commit, rootState }, evaluationUuid )
    {
        // console.log( "currentAnswers length: " + state.currentAnswers.length )

        if ( state.currentAnswers.length > 0 )
        {
            return true
        }

        // console.log("calling getAnswersByEvaluation: " + evaluationUuid )

        // dispatch('newInspection/evaluationPrompt/setLoadingState', true )
        commit('setIsLoading', true)

        try {
            const offlineAnswers = await idbs.getAllFromOffline("answers","evaluation_uuid", evaluationUuid )

            commit( 'setCurrentAnswers',offlineAnswers )
            commit('setIsLoading', false)

            // dispatch('newInspection/evaluationPrompt/setLoadingState', false )

        }catch( e )
        {
            console.log("error getting answers from offline storage")
        }

            try {
                const answersResponse = await axios
                    .get( process.env.apiUrl + '/answers', {
                        params: {
                            evaluation: evaluationUuid,
                            include: ['contractor', 'assets', 'actions']
                        },
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })

                const answers = await answersResponse.data.data

                // console.log('answers await thing')
                // console.log( answers )

                // update our offline store with the results
                idbs.saveToOfflineStorage('answers',  answers )

                commit( 'setCurrentAnswers',answers )

            }catch( e ){
                console.log(e)
            }

        commit('setIsLoading', false)
        // dispatch('newInspection/evaluationPrompt/setLoadingState', false )

    },
    async createAnswer({ commit, dispatch, state, rootState }, answer )
    {
        commit('setIsLoading', true)

        commit("clearCurrentAnswer")

        // put data in "FormData" for axios transit
        const answerData = new FormData()
        const offlineAnswerData = {
            uuid : uuidService.generate(),
            contractor: null
        }

        answerData.append('prompt', answer.evaluationPrompt.uuid )
        offlineAnswerData.evaluation_prompt_uuid = answer.evaluationPrompt.uuid

        answerData.append('uuid', offlineAnswerData.uuid )

        if ( answer.data.hasOwnProperty('criticalScore') ) {
            answerData.append('grade', answer.data.criticalScore)
            offlineAnswerData.is_escalated = (answer.data.criticalScore > 5 ) ? 1 : 0
            offlineAnswerData.score = answer.data.criticalScore
            offlineAnswerData.is_scored = 1
        }else{
            offlineAnswerData.is_scored = 0
            offlineAnswerData.score = 0;
            offlineAnswerData.is_escalated = false
        }

        if ( answer.data.hasOwnProperty('justification')) {
            answerData.append('justification', answer.data.justification)
            offlineAnswerData.justification = answer.data.justification
        }else{
            offlineAnswerData.justification = ""
        }

        if (answer.data.hasOwnProperty('description')) {
            answerData.append('description', answer.data.description)
            offlineAnswerData.description = answer.data.description
        }else{
            offlineAnswerData.description = ""
        }

        if ( answer.data.hasOwnProperty('contractor') ) {

            answerData.append('contractor', answer.data.contractor)

            // console.log('contractors prop')
            // console.log( answer.contractors )

            if ( answer.contractors.data.length > 0 )
            {
                const contractorIndex = answer.contractors.data.findIndex((i) => i.uuid === answer.data.contractor )

                if ( contractorIndex > -1 )
                {
                    // console.log('contractor found')
                    // console.log(answer.contractors.data[contractorIndex])
                    offlineAnswerData.contractor = {
                        data : answer.contractors.data[contractorIndex]
                    }
                }
            }
        }

        // loop all actions and put data in "FormData" for axios transit
        if ( answer.data.hasOwnProperty('action') ) {

            offlineAnswerData.actions = {
                data: []
            }

            answer.data.action.forEach((action) => {
                answerData.append('actions[]', action.data)
                offlineAnswerData.actions.data.push({ action : action.data })
            })
        } else {
            answerData.append('actions[]', '')
        }

        if (answer.data.hasOwnProperty('actionedNow')) {
            if (answer.data.actionedNow === true || answer.data.actionedNow === 1)
            {
                answerData.append('is_closed', 1)
                offlineAnswerData.is_closed = 1;
                offlineAnswerData.is_closed_during_inspection = 1;
            } else {
                answerData.append('is_closed', 0)
                offlineAnswerData.is_closed = 0;
                offlineAnswerData.is_closed_during_inspection = 0;
            }
        } else {
            answerData.append('is_closed', 0)
            offlineAnswerData.is_closed = 0;
            offlineAnswerData.is_closed_during_inspection = 0;
        }

        answerData.rating = answer.rating
        offlineAnswerData.rating = answer.rating

        const savedOfflineAt = new Date
        savedOfflineAt.toISOString()

        try {

            await idbs.saveToOfflineStorage("answers",[offlineAnswerData] )

            console.log('offline data set: ')

            console.log( offlineAnswerData )

            commit("setAnswerInCurrentAnswers", offlineAnswerData )

            commit('setIsLoading', false)

        }catch( e )
        {
            console.log("save offline error")
            console.log( e )
        }

        try
        {
            const createAnswerResponse = await axios
                .post( process.env.apiUrl + '/answers', answerData,
                    {
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        'Cache-Control': 'no-cache',
                        Accept: 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })

            const answer = await createAnswerResponse.data.data

            console.log("answer returned via the server: ")
            console.log( answer )

            await idbs.saveToOfflineStorage('answers', [ answer ] )

            commit( "setAnswerInCurrentAnswers",answer )

        }catch( e )
        {
            console.log("Create answer via API error:")
            console.log(e)
        }

        commit('setIsLoading', false )

    },
    async updateAnswer({ commit, dispatch, state, rootState }, answer )
    {
        commit('setIsLoading', true)
        commit("clearCurrentAnswer")

        const answerData = {
            grade: answer.data.criticalScore,
            description: answer.data.description,
            actions: []
        }

        if ( answer.data.hasOwnProperty('justification') )
        {
            answerData.justification = answer.data.justification
        }

        const offlineData = answerData

        if ( answer.data.hasOwnProperty('action') )
        {
            if ( answer.data.action.length > 0 ) {
                answer.data.action.forEach((item) => {
                    answerData.actions.push(item.data)
                })
            } else {
                answerData.actions = []
            }
        } else {
            answerData.actions = []
        }

        if ( answer.data.hasOwnProperty('relatingToCurrentValue') )
        {
            answerData.contractor = answer.data.relatingToCurrentValue

            if ( answer.contractors.data.length > 0 )
            {
                const contractorIndex = answer.contractors.data.findIndex((i) => i.uuid === answer.data.relatingToCurrentValue )

                if ( contractorIndex > -1 )
                {
                    offlineData.contractor = {
                        data : answer.contractors.data[contractorIndex]
                    }
                }else{
                    offlineData.contractor = null
                }
            }

        }else{
            offlineData.contractor = null
        }

        if ( answer.data.hasOwnProperty('actionedNow') )
        {
            answerData.is_closed = answer.data.actionedNow
            answerData.is_closed_during_inspection = answer.data.actionedNow
        } else {
            answerData.is_closed = 0
            answerData.is_closed_during_inspection = 0
        }

        offlineData.is_closed = answerData.is_closed
        offlineData.score = answer.data.criticalScore
        offlineData.is_escalated = ( answer.data.criticalScore > 5 ) ? 1 : 0
        offlineData.is_closed_during_inspection = answerData.is_closed_during_inspection

        try {
            const offlineAnswer = await idbs.getFromOfflineByPrimaryKey("answers", answer.data.uuid )

            const updatedOfflineAnswer = Object.assign({}, offlineAnswer, offlineData )

            await idbs.saveToOfflineStorage("answers", [ updatedOfflineAnswer ] )

            commit( "setAnswerInCurrentAnswers", updatedOfflineAnswer )
            commit('setIsLoading', false)

        }catch( e )
        {
            console.log('PATCH answer offline error')
            console.log( e )
        }

        try {
            const updatedAnswerResponse = await axios
                .patch(process.env.apiUrl + '/answers/' + answer.data.uuid, answerData, {
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        'Cache-Control': 'no-cache'
                    }
                })

            const updatedAnswer = await updatedAnswerResponse.data.data

            commit( "setAnswerInCurrentAnswers",updatedAnswer )

            await idbs.saveToOfflineStorage("answers", [updatedAnswer] )

        }catch( e )
        {
            console.log("PATCH answer error")
            console.log(e)
        }

        commit('setIsLoading', false)
    },
    async deleteAnswer({ commit, dispatch, state, rootState }, answerUuid )
    {
        commit('setIsLoading', true)

        commit('removeAnswerFromCurrentAnswers', answerUuid )

        // remove from our offline store too
        try {
            await idbs.deleteFromOfflineStore("answers", answerUuid )
        }catch( e )
        {
            console.log( e )
        }

        commit('setIsLoading', false)

        // remove via the API
        try {
            const deletedAnswer = await axios.delete( process.env.apiUrl + '/answers/' + answerUuid, {
                headers: {
                    Authorization: 'Bearer ' + rootState.auth.authToken,
                    'Cache-Control': 'no-cache',
                    Accept: 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })

            // make sure this is definitely removed from state
            commit('removeAnswerFromCurrentAnswers', deletedAnswer.data.uuid )

            commit('setIsLoading', false )

        }catch( e )
        {
            console.log( e )
        }

    },
    async addAssetToAnswer({ commit, dispatch, state, rootState }, data )
    {
        console.log("addAssetToAnswer triggered")

        // set loading state
        commit('setIsLoading', true)

        // put data in "FormData" for axios transit
        const FORM_DATA_CONST = new FormData()

        const offlineAsset = {}

        if (data.hasOwnProperty('answer')) {
            FORM_DATA_CONST.append('answer', data.answer)
        }

        if (data.hasOwnProperty('prompt')) {
            FORM_DATA_CONST.append('prompt', data.prompt)
            offlineAsset.prompt = data.prompt
        }

        if (data.hasOwnProperty('image')) {
            FORM_DATA_CONST.append('image', data.image)
            offlineAsset.image = data.image
        }

        if (data.hasOwnProperty('filename')) {
            FORM_DATA_CONST.append('filename', data.filename)
            offlineAsset.filename = data.filename
        }

        const uuid = uuidService.generate()

        FORM_DATA_CONST.append('uuid', uuid )
        offlineAsset.uuid = uuid

        // set this offline ??
        // @todo - store this offline before we hit the network

        if ( data.type === 'provisional')
        {
            const offlineAnswer = {}
            // @todo we need to setup a fake answer uuid to attach this all to
            offlineAnswer.uuid = uuidService.generate()

            offlineAnswer.assets = {
                data: [ offlineAsset ]
            }

            FORM_DATA_CONST.append('answer_offline_uuid', offlineAnswer.uuid )

            await idbs.saveToOfflineStorage("answers", [ offlineAnswer ] )

            commit( "setAnswerInCurrentAnswers", offlineAnswer )

        }else if ( data.hasOwnProperty('answer') )
        {

                const offlineAnswer = await idbs.getFromOfflineByPrimaryKey("answers", data.answer )

                offlineAnswer.assets.data.push( offlineAsset );


        }

        try {
            const response = await axios
                .post(process.env.apiUrl + '/answers/assets', FORM_DATA_CONST,
                    {
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })

            if ( data.type === 'provisional')
            {
                // save response data to currentEpObservation state data

                // 1. get the whole response data, remove the answer key/value
                // this is to obtain just the info about the asset
                const IMAGE_RESPONSE_DATA_OBJECT = Object.assign({}, response.data.data)
                delete IMAGE_RESPONSE_DATA_OBJECT.answer

                // 2. get the answer data from the response,
                // this is most of the data object for the current observation
                // - create assets with empty object
                // - in assets create data that is empty array
                // - push the image const above into assets.data
                const OBS_RESPONSE_DATA_OBJECT = Object.assign({}, response.data.data.answer.data)
                OBS_RESPONSE_DATA_OBJECT.assets = {}
                OBS_RESPONSE_DATA_OBJECT.assets.data = []
                OBS_RESPONSE_DATA_OBJECT.assets.data.push(IMAGE_RESPONSE_DATA_OBJECT)

                commit('setAnswerInCurrentAnswers', OBS_RESPONSE_DATA_OBJECT)
                commit('setCurrentAnswerByUuid', OBS_RESPONSE_DATA_OBJECT.uuid)

                // this version does not work at all
                // commit('mergeUuidToCurrentAnswer', OBS_RESPONSE_DATA_OBJECT.uuid)
                //
                // commit('addAssetToCurrentAnswer', IMAGE_RESPONSE_DATA_OBJECT )

            } else {
                // this prompt already exists, simply push new image to asset list in currentEpObservation
                const answerAsset = await response.data.data

                // @todo store this offline - somehow

                await idbs.saveToOfflineStorage("answers", [ answerAsset.answer ] )

                delete answerAsset.answer


                commit('addAssetToCurrentAnswer', answerAsset)

            }

        }catch( e )
        {
            console.log("POST answer/asset api error")
            console.log( e )

        }

        commit('setIsLoading', false)
    }

}