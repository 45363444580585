import Vue from 'vue'
import axios from '../middleware/axios'

import idbs from "../api/offline/indexedDbService";
import uuidService from "../api/offline/uuidService";

export const state = () => ({
    loading: false,
    busy: false,
    collection: null,
    filteredCollection: null,
    instance: null,
    collectionMeta: null,
    isBeingCloned: false
})

export const getters = {

    getCollection: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }
        return state.collection
    }
}

export const mutations = {

    setLoading( state, isLoading ){
        state.loading = isLoading === true
    },
    setBusy( state, isBusy )
    {
        state.busy = isBusy === true
    },
    setCollection: ( state, data ) => {
        state.collection = data
    },

    setCollectionMeta: ( state, data ) => {
        state.collectionMeta = data
    },

    setFilteredCollection: ( state, data ) => {
        state.filteredCollection = data
    },

    addToCollection: ( state, model ) => {
        if ( state.collection === null )
        {
            state.collection = []
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            state.collection.push(model)
        }else{
            Vue.set(state.collection, index, model )
        }

    },

    upsertToCollection: (state, model ) => {
        if ( state.collection === null )
        {
            state.collection = []
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            state.collection.push(model)
        }else{
            Vue.set(state.collection, index, model )
        }
    },

    setInstance: ( state, model ) => {
        return ( state.instance = model )
    },
    clearInstance: ( state ) => {
        state.instance = null
    },
    setCollectionFilteredByPromptUuid: ( state, evaluationPromptUuid) => {
        state.filteredCollection = []

        if (state.collection === null || state.collection.length === 0) {
            return
        }

        for ( const model of state.collection ){
            if ( model.evaluation_prompt_uuid === evaluationPromptUuid )
            {
                state.filteredCollection.push( model )
            }
        }
    },
    updateInCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }

        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            return
        }

        // console.log("updating answer collection index for: " + index )
        Vue.set(state.collection, index, model )

        // also update any filtered collection

        if ( state.filteredCollection && state.filteredCollection.length > 0 )
        {
            const index = state.filteredCollection.findIndex((entry) => entry.uuid === model.uuid)
            if ( index !== -1 ){
                Vue.set(state.filteredCollection, index, model )
            }
        }
    },

    removeFromCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)
        if ( index === -1 ){
            return
        }
        state.collection.splice(index,1)
    }
}

export const actions = {

    clearStore( {commit} ){
        commit("setInstance", null)
        commit("setCollection", null)
        commit("setFilteredCollection", null)
        commit("setCollectionMeta", null)
    },

    setInstanceFromCollectionByUuid( { commit, state },{uuid} ){

        if ( state.collection && state.collection.length > 0 )
        {
            const index = state.collection.findIndex((entry) => entry.uuid === uuid )

            if ( index !== -1 ){
                commit("setInstance", state.collection[index] )
            }
        }

    },
    updateCollectionState({commit}, models )
    {
        for ( const model of models ){
            commit('upsertToCollection', model )
        }
    },
    clearInstance( { commit } ){
        commit("clearInstance")
    },
    filterCollectionByEvaluationPrompt( {commit}, {evaluationPromptUuid} )
    {
      commit("setCollectionFilteredByPromptUuid", evaluationPromptUuid)
    },
    async collection( { commit, rootState }, filters={}) {

        commit('setLoading', true)
        commit('setCollectionMeta', null)

        let index = '';
        let query = '';

        if (filters.evaluation) {
            index = 'evaluation_uuid';
            query = filters.evaluation;
        }

        try {

            await idbs.getAllFromOffline('answer-assets', index, query).then( (response) => {

                commit('setCollection', response )
                commit('setLoading', false)
            })

        }catch( error )
        {

        }

        try {

            await axios
                .get( process.env.apiUrl + '/answers/assets',{
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then( function( response ) {
                    // commit('setCollection',[] )
                    if ('meta' in response.data ){
                        commit('setCollectionMeta', response.data.meta )
                    }
                    for ( const model of response.data.data ){
                        commit('addToCollection', model )
                    }
                    idbs.saveToOfflineStorage('answer-assets', response.data.data)
                    return response.data
                })
                .catch(function( error ){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    async instance( { commit, rootState }, { id, filters }) {

        try {

            commit('setLoading', true)

            await axios
                .get( process.env.apiUrl +`/answers/assets/${id}`, {
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                }).then(function( response ){
                    commit('setInstance', response.data.data )
                    idbs.saveToOfflineStorage('answer-assets', [response.data.data])
                    return response.data.data

                }).catch( function( error ){
                    return error
                })

        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    store( { commit, rootState, dispatch }, data ){

        return new Promise( ( resolve, reject ) => {

            commit('setBusy', true)

            data.offline_only = true

            try {

                data.uuid = uuidService.generate()

                if ( "answer" in data ){
                    data.model_uuid = data.answer
                }

                idbs.saveToOfflineStorage('answer-assets', [data]).then((response) => {

                    commit('addToCollection', data )

                    commit('setBusy', false)

                }).catch((error) => {
                    console.log( error )
                })

            }catch( error ){
                console.log("offline save error")
                console.log( error )
            }

            if ( data.local_only !== true ){

                try {

                    axios
                        .post(process.env.apiUrl + `/answers/assets`, data, {
                            headers: {
                                Authorization: 'Bearer ' + rootState.auth.authToken,
                                Accept: 'application/json'
                            }
                        })
                        .then(function (response) {
                            commit('addToCollection', response.data.data)
                            idbs.saveToOfflineStorage('answer-assets', [response.data.data])
                            resolve( response )
                        })
                        .catch(function (e) {
                            // reject(e)
                            resolve( data )
                        })

                } catch (e) {
                    reject(e)
                } finally {
                    commit('setBusy', false)
                }

            }else{
                resolve( data )
            }

        })
    },
    async update( { commit, rootState }, data ){

        commit('setBusy', true)

        data.offline_only = true

        try {
            await idbs.saveToOfflineStorage('answer-assets', [data]).then((response) => {
                commit('updateInCollection', data )
                commit('setBusy', false)
            })

        }catch( error ){

        }

        if ( data.local_only !== true ) {
            try {

                await axios
                    .patch(process.env.apiUrl + `/answers/assets/${data.uuid}`, data, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function (response) {
                        commit('updateInCollection', response.data.data)
                        idbs.saveToOfflineStorage('answer-assets', [response.data.data])
                        return response.data.data
                    })
                    .catch(function (error) {
                        return error
                    })
            } catch (e) {
                throw e
            } finally {
                commit('setBusy', false)
            }
        }else{
            return data
        }
    },
    updateCollectionFromOffline( { commit, rootState }, data ) {

        commit('setBusy', true)

        return new Promise( ( resolve, reject ) => {

            try {

                idbs.getAllFromOffline('answer-assets','evaluation_uuid', data.evaluation ).then( (response) => {

                    const offlineOnlyCollection = [...response.filter(model => (model.offline_only === true))]

                    if ( offlineOnlyCollection.length > 0 ) {

                        if ( offlineOnlyCollection.length > 2 ){

                            let endPoint = 0
                            const collectedResponse = []
                            const collectedErrors = []

                            for( let startPoint=0; startPoint < offlineOnlyCollection.length; startPoint += 2) {
                                endPoint += 2
                                const slice = { ...offlineOnlyCollection.slice( startPoint, endPoint ) }

                                axios.put(process.env.apiUrl + `/answers/assets/batch-upsert`, { evaluation: data.evaluation, assets: slice }, {
                                    headers: {
                                        Authorization: 'Bearer ' + rootState.auth.authToken,
                                        Accept: 'application/json'
                                    }
                                }).then( function(response ) {
                                    collectedResponse.push( response.data )
                                }).catch(function (error) {
                                    collectedErrors.push( error )

                                })
                            }

                            if ( collectedErrors.length > 0 ){
                                reject( collectedErrors )
                            }else{
                                resolve( collectedResponse )
                            }

                        }else{

                            axios.put(process.env.apiUrl + `/answers/assets/batch-upsert`, { evaluation: data.evaluation, assets: offlineOnlyCollection }, {
                                headers: {
                                    Authorization: 'Bearer ' + rootState.auth.authToken,
                                    Accept: 'application/json'
                                }
                            })
                                .then(function (response) {

                                    resolve(response.data)
                                })
                                .catch(function (error) {
                                    reject(error)
                                })
                        }

                    }else{
                        resolve({ message: "No offline only models to sync"})
                    }

                }).finally(()=> {
                    commit('setBusy', false)
                })

            }catch( error ){
                commit('setBusy', false)
                reject( error )
            }
        })

    },
    async destroy( { commit, rootState }, data ){

        commit('setBusy', true)

        try {
            await idbs.deleteFromOfflineStore('answer-assets', data.uuid).then((response) => {
                commit('removeFromCollection', data)
                commit('setBusy', false)
            })

        }catch( error ){

        }

        if ( data.local_only !== true ) {
            try {

                await axios
                    .delete(process.env.apiUrl + `/answers/assets/${data.uuid}`, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function (response) {
                        commit('removeFromCollection', response.data.data)
                        idbs.deleteFromOfflineStore('answer-assets', data.uuid)
                        return response.data.data
                    })
                    .catch(function (error) {
                        return error
                    })
            } catch (e) {
                throw e
            } finally {
                commit('setBusy', false)
            }
        }else{
            return data
        }
    }
}
