import Vue from 'vue'
import axios from '../middleware/axios'

export const state = () => ({
    loading: false,
    busy: false,
    building: false,
    buildingJobLog: null,
    collection: null,
    instance: null,
    collectionMeta: null
})

export const getters = {

    getCollection: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }
        return [...state.collection].sort( function(a,b) {
            if(a.updated_at < b.updated_at) { return 1; }
            if(a.updated_at > b.updated_at) { return -1; }
            return 0;
        })
    },
    getPaginationOptions: (state) => {

        if ( state.collectionMeta === null || state.collectionMeta.length === 0 ){
            return null
        }
        return ( "pagination" in state.collectionMeta ) ? state.collectionMeta.pagination : null
    },
    getInstance: (state) => {
        return state.instance
    },
    getIsLoading: (state) => {
        return state.loading
    },
    getIsBuilding: (state) => {
        return state.building
    },
    getBuildingJobLog: (state) => {
        return state.buildingJobLog
    },
}

export const mutations = {

    setLoading( state, isLoading ){
        state.loading = isLoading === true
    },
    setBusy( state, isBusy )
    {
        state.busy = isBusy === true
    },
    setBuilding( state, isBuilding )
    {
        state.building = isBuilding === true
    },
    setBuildingJobLog( state, jobLog )
    {
        state.buildingJobLog = jobLog
    },
    setCollection: ( state, data ) => {
        state.collection = data
    },

    setCollectionMeta: ( state, data ) => {
        state.collectionMeta = data
    },

    setFilteredCollection: ( state, data ) => {
        state.filteredCollection = data
    },

    addToCollection: ( state, model ) => {
        if ( state.collection === null )
        {
            state.collection = []
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            state.collection.push(model)
        }

    },

    upsertToCollection: (state, model ) => {
        if ( state.collection === null )
        {
            state.collection = []
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            state.collection.push(model)
        }else{
            Vue.set(state.collection, index, model )
        }
    },

    setInstance: ( state, model ) => {
        return ( state.instance = model )
    },
    clearInstance: ( state ) => {
        state.instance = null
    },
    updateInCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }

        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            return
        }

        Vue.set(state.collection, index, model )

    },

    removeFromCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)
        if ( index === -1 ){
            return
        }
        state.collection.splice(index,1)
    }
}

export const actions = {

    clearStore( {commit} ){
        commit("setInstance", null)
        commit("setCollection", null)
        commit("setCollectionMeta", null)
        commit("setBuilding", false)
        commit("setBuildingJobLog", null)
    },

    async collection( { commit, rootState }, filters={}) {

        commit('setLoading', true)
        commit('setCollectionMeta', null)

        try {

            await axios
                .get( process.env.apiUrl + '/master-reports',{
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then( function( response ) {
                    // commit('setCollection',[] )
                    if ('meta' in response.data ){
                        commit('setCollectionMeta', response.data.meta )
                    }
                    for ( const model of response.data.data ){
                        commit('addToCollection', model )
                    }
                    return response.data
                })
                .catch(function( error ){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    instance( { commit, rootState }, { id, filters }) {

        commit('setLoading', true)

        return new Promise( ( resolve, reject ) => {

        try {

            axios
                .get( process.env.apiUrl +`/master-reports/${id}`, {
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                }).then(function( response ){

                    commit('setInstance', response.data.data )

                    resolve( response.data.data )

                }).catch( function( error ){
                    return error
                }).finally(() => {
                commit('setLoading', false)
            })

        }catch ( e ){
            throw e
        }

        })

    },
    setInstanceFromCollectionByUuid( { commit, state },{uuid} ){

        if ( state.collection && state.collection.length > 0 )
        {
            const index = state.collection.findIndex((entry) => entry.uuid === uuid )

            if ( index !== -1 ){
                commit("setInstance", state.collection[index] )
            }
        }

    },
    store( { commit, rootState }, data ){


        return new Promise( ( resolve, reject ) => {
            commit('setBusy', true)

            try {

                axios
                    .post(process.env.apiUrl + `/master-reports`, data, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function (response) {
                        commit('addToCollection', response.data.data)


                        if ('location' in response.headers ) {
                            // we have a location to poll for the "building" state
                            commit('setBuildingJobLog', response.headers.location )
                            commit('setBuilding', true )
                        }
                        resolve( response.data.data )
                    })
                    .catch(function (error) {
                        if ( error.response.data !== undefined ){
                            reject( error.response.data )
                        }else{
                            reject( error )
                        }
                    })

            } catch (e) {
                throw e
            } finally {
                commit('setBusy', false)
            }
        })
    },

    generate( { commit, rootState }, data ){

        return new Promise( ( resolve, reject ) => {

            commit('setBusy', true)

            try {

                axios
                    .post(process.env.apiUrl + `/master-reports/generate`, data, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function (response) {
                        commit('addToCollection', response.data.data)
                        commit('setInstance', response.data.data)

                        if ('location' in response.headers ) {
                            // we have a location to poll for the "building" state
                            commit('setBuildingJobLog', response.headers.location )
                            commit('setBuilding', true )
                        }

                        resolve( response.data.data )
                    })
                    .catch(function (error) {
                        if ( error.response.data !== undefined ){
                            reject( error.response.data )
                        }else{
                            reject( error )
                        }
                    })

            } catch (e) {
                throw e
            } finally {
                commit('setBusy', false)
            }
        })
    },

    update( { commit, rootState }, data ){

        return new Promise( ( resolve, reject ) => {
            commit('setBusy', true)

            try {

                axios
                    .patch(process.env.apiUrl + `/master-reports/${data.uuid}`, data, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function (response) {

                        commit('updateInCollection', response.data.data)

                        resolve( response.data.data )

                    })
                    .catch(function (error) {
                        if ( error.response.data !== undefined ){
                            reject( error.response.data )
                        }else{
                            reject( error )
                        }
                    })
            } catch (e) {
               reject( e )
            } finally {
                commit('setBusy', false)
            }
        })
    },
    async destroy( { commit, rootState }, data ){

        commit('setBusy', true)

        try {

            await axios
                .delete( process.env.apiUrl +`/master-reports/${data.uuid}`,{
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then(function( response ){
                    commit('removeFromCollection', response.data.data)

                    return response.data.data
                })
                .catch(function(error){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setBusy', false)
        }
    },
    pollBuildingJob({commit, rootState }, jobLog ){

        return new Promise( ( resolve, reject ) => {

            try {

                axios
                    .get(jobLog, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    }).then( function( response ){

                    if ( typeof response.data.data.is_finished !== "undefined" && response.data.data.is_finished === true )
                    {
                        commit('setBuildingJobLog', null)
                        commit('setBuilding', false )
                    }else{
                        commit('setBuilding', true )
                    }

                    resolve(response.data.data)

                }).catch(function(error){
                    reject( error )
                })

            }catch(e){
                console.log(e)
                reject(e)
            }
        })
    },
}
