import axios from '../../middleware/axios'
import idbs from "../../api/offline/indexedDbService";

export const state = () => ({
  isLoading: true,
  epsList: [],
  epsFilteredList: [],
  epsAlphabetTotals: {
    '#': 0,
    a: 0,
    b: 0,
    c: 0,
    d: 0,
    e: 0,
    f: 0,
    g: 0,
    h: 0,
    i: 0,
    j: 0,
    k: 0,
    l: 0,
    m: 0,
    n: 0,
    o: 0,
    p: 0,
    q: 0,
    r: 0,
    s: 0,
    t: 0,
    u: 0,
    v: 0,
    w: 0,
    x: 0,
    y: 0,
    z: 0
  },
  epsTotals: {}
})

export const getters = {
  getIsLoading: (state) => {
    return state.isLoading
  },
  getEpsList: (state) => {
    return state.epsList
  },
  getEpsFilteredList: (state) => {
    return state.epsFilteredList
  },
  getEpsAlphabetTotals: (state) => {
    return state.epsAlphabetTotals
  },
  getEpsTotals: (state) => {
    return state.epsTotals
  }
}

export const mutations = {
  setIsLoading: (state, loading) => {
    state.isLoading = loading === true
  },
  setEpsListingData: (state, data) => {
    // empty list
    state.epsList = []

    // refill with new data
    data.forEach((item) => {
      state.epsList.push(item)
    })
  },
  updateSinglePromptInEpsList: (state, newPrompt ) => {
    console.log("push this newPrompt:")
    console.log(newPrompt)
    state.epsList.push( newPrompt )
  },
  updatePromptAnswerTotals: ( (state, data) => {

    const ItemIndex = state.epsList.findIndex((item) => item.uuid === data.data.uuid)

    if ( ItemIndex > -1 )
    {
      state.epsList[ItemIndex].total_answers += data.data.total_answers
      state.epsList[ItemIndex].has_answers = data.data.has_answers
      state.epsList[ItemIndex].total_assets += data.data.total_assets
      state.epsList[ItemIndex].total_escalated_actions += data.data.total_answers
    }
  }),
  clearEpsListingData: (state) => {
    state.epsList = []
  },
  setSingleEpRating: (state, data) => {
    // find index of the current item in list
    const ItemIndex = state.epsList.findIndex((item) => item.uuid === data.data.uuid)

    if ( ItemIndex > -1 )
    {
      // update rated time
      state.epsList[ItemIndex].rated_at = data.replacementData.rated_at

      // update rating modalTitle
      state.epsList[ItemIndex].rating = data.replacementData.rating

      // update updated time
      state.epsList[ItemIndex].updated_at = data.replacementData.updated_at

      // update all rating buttons
      // find ratings array in current item
      const RatingOptions = state.epsList[ItemIndex].ratingOptions.data

      // find index of the handle of the current handle
      const RatingIndex = RatingOptions.findIndex((item) => item.handle === data.data.handle)

      // make all other ratings false
      RatingOptions.forEach((item, index) => {
        if (index !== RatingIndex) {
          item.active = false
        }
      })

      // make specific ratings true
      RatingOptions[RatingIndex].active = true
    }else{
      // console.log("No index found for this data payload:")
      // console.log( data )
    }
  },
  setEpsFilteredListingData: (state, { items, rating }) => {
    // empty list
    state.epsFilteredList = []

    // console.log( items )

    /*
    state.epsFilteredList = state.epsFilteredList.filter( ( item ) => {
      return item.rating === rating
    }).concat( items )

     */
    // console.log( state.epsFilteredList )

    // add with new data
    items.forEach((item) => {
      state.epsFilteredList.push(item)
    })

  },
  clearEpsFilteredListingData: (state) => {
    state.epsFilteredList = []
  },
  addToEpsFilteredListingData: (state, data) => {
    // add with new data
    data.forEach((item) => {
      state.epsFilteredList.push(item)
    })
  },
  setEpsAlphabetTotals: (state, data) => {
    state.epsAlphabetTotals = data
  },
  setEpsTotals: (state, data) => {
    state.epsTotals = data
  },
  increaseEvaluationPromptTotal: ( state, { key, amount }) => {

      if ( typeof state.epsTotals[key] !== 'undefined' )
      {
          state.epsTotals[key] += amount
      }
  },
    decreaseEvaluationPromptTotal: ( state, { key, amount }) => {

        if ( typeof state.epsTotals[key] !== 'undefined' )
        {
            state.epsTotals[key] -= amount
        }
    },
}

export const actions = {
  async getEpsList({ commit, rootState }, data) {
    // set loading state
    commit('setIsLoading', true)

    let promptsFound = false

    // console.log('fetching offlineVersion first by: evaluation_category_uuid: ' + data )

    // try loading from offline first, then update once the network catches up
    const offlineEvaluationPrompts = idbs.getAllFromOffline('evaluation-prompts',
        'evaluation_category_uuid',
        data )

        await offlineEvaluationPrompts.then(function( offlineEvaluationPrompts)
        {

          if ( offlineEvaluationPrompts.length > 0 )
          {

            promptsFound = true

            // console.log("Trying to sort the prompts by running_order")

            // we again need to resort these
            offlineEvaluationPrompts.sort( function(a,b)
            {

              const order = 1

              return a.id > b.id ? -order : a.id < b.id ? order : 0;

            })

            commit('setEpsListingData', offlineEvaluationPrompts )
            commit('setIsLoading', false )

          }


        }, function( e )
        {
          console.log( 'offline data error' )
        }).finally( async() => {

          if ( promptsFound === false )
          {
            await axios
                .get(process.env.apiUrl + '/evaluation-prompts', {
                  params: {
                    category: data
                  },
                  headers: {
                    Authorization: 'Bearer ' + rootState.auth.authToken
                  }
                })
                .then((response) => {
                  idbs.saveToOfflineStorage('evaluation-prompts', response.data.data )
                  commit('setEpsListingData', response.data.data)
                })
                .catch((e) => {
                  console.log(e)
                })
                .finally(() => {
                  // unset loading
                  commit('setIsLoading', false)
                })
          }
        })

  },
  async updateSingleEpRating({ commit, rootState }, data) {
    // set loading state
    if (
      (data.emphasis !== 'negative' && data.emphasis !== 'positive') ||
      (data.clickType === 'single' && data.emphasis === 'positive')
    ) {
      commit('setIsLoading', true, {
        root: true
      })
    } else {
      commit('setIsLoading')
    }

    // we are going to store offline first then await the network!
    const newRating = data.handle
    const isRated = true
    const ratedAt = new Date
    ratedAt.toISOString()

    // work out the active buttons thing locally (urgggh)
    const ratingOptions = {
      data: []
    }

    if ( data.prompt.ratingOptions.data.length > 0 )
    {
      data.prompt.ratingOptions.data.forEach( function( ratingOption, index)
      {
        ratingOptions.data.push({
          handle : ratingOption.handle,
          label  : ratingOption.label,
          active : ( ratingOption.handle === newRating ),
          control_size : ratingOption.control_size,
          control_emphasis : ratingOption.control_emphasis
        })

      })

    }

    await idbs.updateOfflineStorage('evaluation-prompts', data.prompt, { rating: newRating, is_rated: isRated, rated_at: ratedAt, ratingOptions })
        .then( ( response ) => {
            console.log('response updatePrompt data back from offline: ' + data.uuid + ' rating: ' + newRating )
            // console.log( response )

          // update vuex records
         const offlineUpdate = Object.assign( {}, data.prompt,{ rating: newRating, is_rated: isRated, ratingOptions } )
          // data.prompt.rating = "foo"
          // console.log( offlineUpdate )

          commit('newInspection/evaluationPrompt/setCurrentEp', offlineUpdate, {
            root: true
          })

          commit('setSingleEpRating', { data, replacementData : {
              rated_at : ratedAt,
              rating : newRating,
              updated_at : ratedAt
            } } )

    }).catch( (e) => {
          console.log("update offline error")
          console.log( e )
    })

    // unset loading
    if (
        (data.emphasis !== 'negative' && data.emphasis !== 'positive') ||
        (data.clickType === 'single' && data.emphasis === 'positive')
    ) {
      commit('setIsLoading', false, {
        root: true
      })
    } else {
      commit('setIsLoading')
    }

    await axios
      .patch(
        process.env.apiUrl + '/evaluation-prompts/' + data.uuid,
        {
          rating: newRating
        },
        {
          headers: {
            Authorization: 'Bearer ' + rootState.auth.authToken,
            'Cache-Control': 'no-cache'
          }
        }
      )
      .then((response) => {

        idbs.updateOfflineStorage('evaluation-prompts', data.uuid, response.data.data)
        // update vuex records
        commit('newInspection/evaluationPrompt/setCurrentEp', response.data.data, {
          root: true
        })

        commit('setSingleEpRating', {
          data,
          replacementData: response.data.data
        })

      })
      .catch((e) => {
        console.log(e)

      })
      .finally(() => {
        // unset loading
        if (
          (data.emphasis !== 'negative' && data.emphasis !== 'positive') ||
          (data.clickType === 'single' && data.emphasis === 'positive')
        ) {
          commit('setIsLoading', false, {
            root: true
          })
        } else {
          commit('setIsLoading')
        }
      })

  },
  async prefetchPromptsToOffline({ commit, rootState }, { evaluationUuid })
  {
    // console.log('prefetch prompts called OK')
    // console.log( evaluationUuid )

    await axios
        .get(process.env.apiUrl + '/evaluation-prompts', {
          params: {
            evaluation: evaluationUuid,
            include: 'category',
            pageSize: 150
          },
          headers: {
            Authorization: 'Bearer ' + rootState.auth.authToken
          }
        })
        .then((response) => {
          // console.log("prefetch prompts result:")
          // console.log( response.data.data )
          // store these offline
          idbs.saveToOfflineStorage('evaluation-prompts', response.data.data )
        })

  },
  async getEpsFilteredList({ commit, rootState }, data) {
    // if the data its not a string or is not valid
    if (
      typeof data !== 'string' ||
      (data !== 'pending' &&
        data !== 'compliant' &&
        data !== 'non-compliant' &&
        data !== 'not-applicable' &&
        data !== 'not-inspected')
    ) {
      // escape loading
      return false
    }

    // set loading state
    commit('setIsLoading', true)

    const evaluationUuid = rootState.newInspection.evaluation.currentEvaluation
    // attempt to get from the local store first :(
    const offlineFilteredPrompts = idbs.getAllFromOffline('evaluation-prompts','evaluation_uuid', evaluationUuid )

    await offlineFilteredPrompts.then( function( offlineFilteredPrompts )
    {
      const ratingToCheck = ( data === "pending") ? null : data

      // we need to remove all where the rating does not match
      offlineFilteredPrompts.forEach( function( prompt, index ){

        if ( prompt.rating !== ratingToCheck )
          {
            console.log( prompt.uuid + ' does not match ' )
            delete offlineFilteredPrompts[index]
          }

      })

      if ( offlineFilteredPrompts.length > 0 )
      {

        offlineFilteredPrompts.sort(function(a,b)
        {
          const order = 1
          return a.alphabet_key < b.alphabet_key ? -order : a.alphabet_key > b.alphabet_key ? order : 0;
        })

      }

      commit('setEpsFilteredListingData', { items: offlineFilteredPrompts, rating: ratingToCheck } )

      commit('setIsLoading', false)

    },function( e ){
      console.log('error retrieving filteredPrompts from IDBS')
    })

    await axios
      .get(process.env.apiUrl + '/evaluation-prompts', {
        params: {
          evaluation: evaluationUuid,
          rating: data, // Valid values: pending,compliant,non-compliant,not-applicable
          include: 'category',
          meta: 'alphabet',
          pageSize: 150
        },
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setEpsFilteredListingData', { items: response.data.data, rating: data })
        commit('setEpsAlphabetTotals', response.data.meta.alphabet_totals)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // unset loading state
        commit('setIsLoading', false)
      })
  },
  async batchUpdateEpsRatings({ commit, dispatch, rootState }, data) {
    // if the data its not a string or is not valid
    if (
      typeof data.rating !== 'string' ||
      (data.rating !== 'pending' &&
        data.rating !== 'compliant' &&
        data.rating !== 'non-compliant' &&
        data.rating !== 'not-applicable')
    ) {
      // escape loading
      return false
    }

    // set loading state
    commit('setIsLoading', true)

    const PromptsData = {
      rating: data.rating,
      prompts: []
    }

    data.prompts.forEach((item) => {
      PromptsData.prompts.push(item)
    })

    await axios
      .patch(process.env.apiUrl + '/evaluation-prompts/batch', PromptsData, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          'Cache-Control': 'no-cache'
        }
      })
      .then((response) => {
        // dispatch to get updated totals
        dispatch('getEpsTotals')

        // rerun dispatch of getEpsFilteredList,
        // to reobtain the list without the changed elements
        dispatch('getEpsFilteredList', data.parentList)
      })
      .catch((e) => {
        console.log(e)
      })
    // highlightng out because interfers with dispatch('getEpsFilteredList')
    // .finally(function () {
    // unset loading
    //   commit('setIsLoading', false)
    // })
  },
  async batchUpdateEpsNotInspected({ commit, dispatch, rootState }, data) {
    // if the data its not a string or is not valid
    if (typeof data.rating !== 'string' || data.rating !== 'not-inspected') {
      // escape loading
      return false
    }

    // set loading state
    commit('setIsLoading', true)

    const PromptsData = {
      rating: data.rating,
      prompts: [],
      rating_reason: data.reason
    }

    data.prompts.forEach((item) => {

      PromptsData.prompts.push(item)

      idbs.getFromOfflineByPrimaryKey("evaluation-prompts", item ).then( function(offlinePrompt)
      {
        idbs.updateOfflineStorage("evaluation-prompts", offlinePrompt, {
          "rating" : PromptsData.rating,
          "rating_reason" : PromptsData.rating_reason
        })

      })

    })

    // Update the offline store first

    await axios
      .patch(process.env.apiUrl + '/evaluation-prompts/batch', PromptsData, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          'Cache-Control': 'no-cache'
        }
      })
      .then((response) => {
        // rerun dispatch of getEpsFilteredList,
        // to reobtain the list without the changed elements
        dispatch('getEpsFilteredList', data.parentList)
      })
      .catch((e) => {
        console.log(e)
      })
    // highlightng out because interfers with dispatch('getEpsFilteredList')
    // .finally(function () {
    // unset loading
    //   commit('setIsLoading', false)
    // })
  },
  clearStoreData({ commit }) {
    commit('setIsLoading', true)
    commit('clearEpsListingData')
    commit('clearEpsFilteredListingData')
    commit('setEpsAlphabetTotals', {
      '#': 0,
      a: 0,
      b: 0,
      c: 0,
      d: 0,
      e: 0,
      f: 0,
      g: 0,
      h: 0,
      i: 0,
      j: 0,
      k: 0,
      l: 0,
      m: 0,
      n: 0,
      o: 0,
      p: 0,
      q: 0,
      r: 0,
      s: 0,
      t: 0,
      u: 0,
      v: 0,
      w: 0,
      x: 0,
      y: 0,
      z: 0
    })
    commit('setEpsTotals', {})
  },
  async getEpsTotals({ commit, rootState }) {
    // set loading state
    commit('setIsLoading', true)

    await axios
      .get(process.env.apiUrl + '/evaluation-prompt-totals', {
        params: {
          evaluation: rootState.newInspection.evaluation.currentEvaluation
        },
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setEpsTotals', response.data)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // unset loading
        commit('setIsLoading', false)
      })
  }
}
