import * as moment from 'moment'
import axios from '../../middleware/axios'

export const state = () => ({
  isLoading: false,
  masterReportSectionList: [],
  currentSection: {},
  masterReportSectionListIsLoading: false,
  masterReportSectionIsLoading: false
})

export const getters = {
  getIsLoading: (state) => {
    return state.isLoading
  },
  getMasterReportSectionList: (state) => {
    return state.masterReportSectionList
  },
  getMasterReportSectionListIsLoading: (state) => {
    return state.masterReportSectionListIsLoading
  },
  getMasterReportSectionIsLoading: (state) => {
    return state.masterReportSectionIsLoading
  },
  getCurrentSection: (state) => {
    return state.currentSection
  }
}

export const mutations = {
  setIsLoading: (state, loading) => {
    state.isLoading = loading === true
  },
  setMasterReportSectionList: (state, data) => {
    state.masterReportSectionList.push(data)
  },
  setCurrentSection: (state, data) => {
    state.currentSection = data
  },
  clearMasterReportSectionList: (state) => {
    state.masterReportSectionList = []
  },
  setMasterReportSectionListIsLoading: (state, loading) => {
    state.masterReportSectionListIsLoading = loading === true
  },
  setMasterReportSectionIsLoading: (state, loading) => {
    state.masterReportSectionIsLoading = loading === true
  }
}

export const actions = {
  async getMasterReportSectionList({ commit, state, rootState }, data) {
    if (data.type === 'firstLoad') {
      commit('clearMasterReportSectionList')
      commit('clearMasterReportSectionRubricsList')
      commit('clearMasterReportSectionListPagination')
    }

    // set loading state
    if (data.type === 'firstLoad') {
      commit('setMasterReportSectionListIsLoading', true)
    } else {
      commit('setMasterReportSectionListPaginationIsLoading', true)
    }

    let apiLinkValue = ''
    const PARAMS_CONST = {}

    if (data.uuid.length > 0) {
      PARAMS_CONST.master_report_section_uuid = data.uuid
    }

    apiLinkValue = process.env.apiUrl + '/master-report-sections'

    await axios
      .get(apiLinkValue, {
        params: PARAMS_CONST,
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        if (response.data.data.length) {
          response.data.data.forEach((item) => {
            commit('setMasterReportSectionList', item)
          })
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // unset loading state
        commit('setMasterReportSectionListIsLoading', false)
      })
  },
  async createCategorySection({ commit, rootState }, data) {
    // set loading state
    commit('setMasterReportSectionIsLoading', true)

    // put all form data into a FormData object
    const DISPATCH_DATA = {
      filters: []
    }

    if (data.hasOwnProperty('master_report') && data.master_report.length > 0) {
      DISPATCH_DATA.master_report = data.master_report
    }

    if (data.hasOwnProperty('section_type') && data.section_type.length > 0) {
      DISPATCH_DATA.section_type = data.section_type
    }

    if (data.form.hasOwnProperty('title') && data.form.title.length > 0) {
      DISPATCH_DATA.title = data.form.title
    }

    if (data.form.hasOwnProperty('sliderCard') && data.form.sliderCard.key.length > 0) {
      DISPATCH_DATA.metric_type = data.form.sliderCard.key
    }

    if (
      data.form.date.start !== null ||
      data.form.date.end !== null ||
      (data.form.hasOwnProperty('clientData') && data.form.clientData.length > 0) ||
      (data.form.hasOwnProperty('siteData') && data.form.siteData.length > 0) ||
      (data.form.hasOwnProperty('userData') && data.form.userData.length > 0) ||
      (data.form.hasOwnProperty('statusData') && data.form.statusData.length > 0)
    ) {
      DISPATCH_DATA.filters = {}
    }

    if (data.form.date.start !== null) {
      DISPATCH_DATA.filters.since = moment(data.form.date.start).format('YYYY-MM-DD')
    }

    if (data.form.date.end !== null) {
      DISPATCH_DATA.filters.before = moment(data.form.date.end).format('YYYY-MM-DD')
    }

    if (data.form.hasOwnProperty('clientData') && data.form.clientData.length > 0) {
      DISPATCH_DATA.filters.team = data.form.clientData
    }

    if (data.form.hasOwnProperty('siteData') && data.form.siteData.length > 0) {
      DISPATCH_DATA.filters.site = data.form.siteData
    }

    if (data.form.hasOwnProperty('userData') && data.form.userData.length > 0) {
      DISPATCH_DATA.filters.owner = data.form.userData
    }

    if (data.form.hasOwnProperty('statusData') && data.form.statusData.length > 0) {
      DISPATCH_DATA.filters.status = data.form.statusData
    }

    if (data.form.hasOwnProperty('compare_key') && data.form.compare_key.length > 0) {
      DISPATCH_DATA.compare_key = data.form.compare_key
    }

    if (data.form.hasOwnProperty('compare') && data.form.compare.length > 0) {
      DISPATCH_DATA.compare = data.form.compare
    }

    await axios
      .post(process.env.apiUrl + '/master-report-sections', DISPATCH_DATA, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          Accept: 'application/json'
        }
      })
      .then(() => {
        this.$router.push({ path: '/dashboard/report-builder/' + data.master_report + '/edit/' })
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // unset loading state
        commit('setMasterReportSectionIsLoading', false)
      })
  },
  async editCurrentSection({ commit, rootState }, data) {
    // set loading state
    commit('setMasterReportSectionIsLoading', true)

    const DISPATCH_DATA = {}

    if (data.form.hasOwnProperty('title') && data.form.title.length > 0) {
      DISPATCH_DATA.title = data.form.title
    }

    if (data.form.hasOwnProperty('sliderCard') && data.form.sliderCard.key.length > 0) {
      DISPATCH_DATA.metric_type = data.form.sliderCard.key
    }

    if (
      data.form.date.start !== null ||
      data.form.date.end !== null ||
      (data.form.hasOwnProperty('clientData') && data.form.clientData.length > 0) ||
      (data.form.hasOwnProperty('siteData') && data.form.siteData.length > 0) ||
      (data.form.hasOwnProperty('userData') && data.form.userData.length > 0) ||
      (data.form.hasOwnProperty('statusData') && data.form.statusData.length > 0)
    ) {
      DISPATCH_DATA.filters = {}
    }

    if (data.form.date.start !== null) {
      DISPATCH_DATA.filters.since = moment(data.form.date.start).format('YYYY-MM-DD')
    }

    if (data.form.date.end !== null) {
      DISPATCH_DATA.filters.before = moment(data.form.date.end).format('YYYY-MM-DD')
    }

    if (data.form.hasOwnProperty('clientData') && data.form.clientData.length > 0) {
      DISPATCH_DATA.filters.team = data.form.clientData
    }

    if (data.form.hasOwnProperty('siteData') && data.form.siteData.length > 0) {
      DISPATCH_DATA.filters.site = data.form.siteData
    }

    if (data.form.hasOwnProperty('userData') && data.form.userData.length > 0) {
      DISPATCH_DATA.filters.owner = data.form.userData
    }

    if (data.form.hasOwnProperty('statusData') && data.form.statusData.length > 0) {
      DISPATCH_DATA.filters.status = data.form.statusData
    }

    if (data.form.hasOwnProperty('compare_key') && data.form.compare_key.length > 0) {
      DISPATCH_DATA.compare_key = data.form.compare_key
    }

    if (data.form.hasOwnProperty('compare') && data.form.compare.length > 0) {
      DISPATCH_DATA.compare = data.form.compare
    }

    await axios
      .patch(process.env.apiUrl + '/master-report-sections/' + data.uuid, DISPATCH_DATA, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          'Cache-Control': 'no-cache'
        }
      })
      .then(() => {
        this.$router.push({ path: '/dashboard/report-builder/' + data.master_report + '/edit' })
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // set loading state
        commit('setMasterReportSectionIsLoading', false)
      })
  },
  async getCurrentSection({ commit, state, rootState }, data) {
    if (data.hasOwnProperty('setLoad')) {
      if (data.setLoad === true) {
        commit('setMasterReportSectionIsLoading', true)
      }
    } else if (state.isLoading === false) {
      // set loading state
      commit('setIsLoading', true)
    }

    await axios
      .get(process.env.apiUrl + '/master-report-sections/' + data.uuid, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setCurrentSection', response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // set loading state
        if (data.hasOwnProperty('unsetLoad')) {
          if (data.unsetLoad === true) {
            commit('setMasterReportSectionIsLoading', false)
          }
        } else {
          commit('setIsLoading', false)
        }
      })
  },
  async updateCurrentCategoryMetric({ commit, state, rootState }, data) {
    if (state.isLoading === false) {
      // set loading state
      commit('setIsLoading', true)
    }
    await axios
      .patch(process.env.apiUrl + '/masterReportSection/' + data.uuid, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken
        }
      })
      .then((response) => {
        commit('setCategoryMetricCurrent', response.data.data)
      })
      .catch((e) => {
        console.log(e)

        if (e.response.status === 404) {
          this.$router.push({ path: '/masterReportSection' })
        }
      })
      .finally(() => {
        // set loading state
        commit('setIsLoading', false)
      })
  },
  async deleteSection({ commit, state, dispatch, rootState }, data) {
    // set loading state
    commit('setMasterReportSectionIsLoading', true)

    await axios
      .delete(process.env.apiUrl + '/master-report-sections/' + data.uuid, {
        headers: {
          Authorization: 'Bearer ' + rootState.auth.authToken,
          'Cache-Control': 'no-cache'
        }
      })
      // .then(() => {
      //   this.$router.push({ path: '/dashboard/report-builder/' + data.master_report + '/edit', force: true })
      // })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        // set loading state
        commit('setMasterReportSectionIsLoading', false)
      })
  },
  async setMasterReportSectionIsLoadingAction({ commit }, data) {
    await commit('setMasterReportSectionIsLoading', data)
  }
}
